import { types } from "../types/types";
const intitalState = {
    clientForgetPasswordProfile: {},
};

const clientForgetPasswordReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CLIENT_FORGET_PASSWORD:
            return {
                ...state,
                clientForgetPasswordProfile: action.payload,
            };
        default:
            return { ...state };
    }
};

export default clientForgetPasswordReducer;