import React from "react";
import { Carousel } from "primereact/carousel";
import { Button } from "primereact/button";
import HomeContent from "./HomeContent";

export default function Recommend() {
  const { RecommendContent } = HomeContent();
  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "991px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const ServiceTemplate = (RecommendContent) => {
    return (
      <div className="m-2 p-2">
        <div className="mb-4 recommend-img curve overflow-hidden">
          <img
            src={RecommendContent.img}
            alt=""
            className="w-100 h-100 cover-img shadow-2"
          />
        </div>
        <div>
          <p className="mt-0 mb-3 text-sm">{RecommendContent.detail}</p>
        </div>
      </div>
    );
  };
  return (
    <div className="container position-relative py-5">
        <h2 className="text-center fw-bold">Recommended for you</h2>
      <Carousel
        value={RecommendContent}
        showNavigators="false"
        numScroll={1}
        numVisible={3}
        className="inspiredservice-carousel recommendedservice px-5 overflow-hidden"
        responsiveOptions={responsiveOptions}
        itemTemplate={ServiceTemplate}
      />
    </div>
  );
}
