import React from "react";
import CameraIcon from "../../assets/icons/camera.png";
import VerifySuccessful from "../../assets/vector/verfiysuccessful.png";
import MainButton from "../../components/buttons/MainButton";
import LicenceImg from "../../assets/images/licence.png";
import { Link } from "react-router-dom";
import Layout from "../../layout";

export default function VerificationSuccessfull() {
  return (
    <div>
      <Layout>
        <div className="p-5">
          <div className="d-flex mb-3">
            <h4 className="text-main fw-bold mb-0 ms-3">Verification</h4>
          </div>
          <div className="row text-center align-items-end mb-3">
            <div className="col-md-8">
              <div className="bg-white rounded p-5 text-center">
                <img src={VerifySuccessful} width={211} className="mb-3" />
                <p className="text-lg fw-semibold">
                  You are Successfully Verified!!
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
