import React, { useState } from "react";
import InputTextComponent from "../../../components/input/InputTextComponent";
import PasswordComponent from "../../../components/input/PasswordComponent";
import { Checkbox } from "primereact/checkbox";
import MainButton from "../../../components/buttons/MainButton";
import CheckboxComponent from "../../../components/input/CheckboxComponent";
import { PhoneInput } from "react-contact-number-input";
import { useSelector,useDispatch } from "react-redux";
import OtpVerify from "./OtpVerify";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../redux/action/toastAction";
import { requestotpAction,clientSignupAction } from "../../../redux/clientAction/clientSignupAction";
import CustomModal from "../../../components/Modal/CustomModal";
import Logo from "../../../assets/icons/Logo.png";
import Login from "../Login/Login";
export default function CreateAccount() {
  const dispatch = useDispatch();
  const navigate =  useNavigate();
  const[selectedStep,setSelectedStep]  = useState("1")
  const[loginVisible ,setLoginVisible] = useState(false)
  const [checked,setChecked] =  useState(false)
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobile: "",
    countryCode: "",
    otp: ""
  })
  const [errors, setErrors] = useState({});
  let handleOnChange = value => {
    setErrors({
      ...errors,
      mobile: null,
    });
    setForm({
      ...form, mobile: value.phoneNumber,
      countryCode:value.countryCode
    });
  };
  const emailRegex =
    new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
  const isValidEmail = emailRegex.test(form.email);
  const handleRequestOtp = (e) => {
    if (isValidEmail) {
      dispatch(requestotpAction(form, () => setSelectedStep("2")))
    }
    else {
      dispatch(showToast({ severity: "error", summary: "Please Enter Valid Email Address", content: "Please Enter Valid Email Address", }));
    }
  }
  const handleSubmitOtp = () => {
    if (form.firstName === "" || form.lastName === "" || form.email === "" || form.password === "" || form.confirmPassword === "" || form.mobile === null) {
      setErrors({
        ...errors,
        firstName:form.firstName === ""?"Please Enter First Name":errors.firstName,
        lastName:form.lastName === "" ?"Please Enter Last Name":errors.lastName,
        email:form.email === ""?"Please Enter Email":errors.email,
        password:form.password === ""?"Please Enter Password":errors.password,
        confirmPassword:form.confirmPassword === ""?"Please Enter Confirm Password":errors.confirmPassword,
        mobile:form.mobile === null ?"Please Enter Mobile":errors.mobile
      })
      return false;
    }
    else if (form.password !==form.confirmPassword){
      dispatch(showToast({ severity: "error", summary: "Password Can't match", content: "Password Can't match" }));
    }
    else if(checked === false){
      dispatch(showToast({ severity: "error", summary: "Please Accept Terms & Conditions", content: "Please Accept Terms & Conditions"}));
    }
    else {
      handleRequestOtp()
    }
  }
  const handleSubmit = async()=>{
    try{
      if(form.email === "" || form.firstName===""||form.lastName==="" || form.password === "" || form.confirmPassword ===""|| form.otp==="" || form.mobile ===null ){
        setErrors({
          ...errors,
          email:form.email ===""?"Please Enter email": errors.email,
          firstName:form.firstName ===""?"Please Enter First Name":errors.firstName,
          lastName:form.lastName ===""?"Please Enter Last Name":errors.lastName,
          mobile :form.mobile ===null?"Please Enter Mobile":errors.mobile,
          password:form.password ===""?"Please Enter Password":errors.password,
          confirmPassword:form.confirmPassword ===""?"Please Enter confirm Password":errors.confirmPassword,
          otp:form.otp === ""?"Please Enter OTP":errors.otp
        })
        return false;
      }
      dispatch(clientSignupAction(form, () => navigate("/")))
     
    }catch(err){
      dispatch(showToast({ severity: "error" ,content: "Something went wrong" }));
    }
   
  }
  return (
    <div>
      {selectedStep === "1" && 
      <div className="d-block login  mt-2 ">
      <h4 className="text-center mb-3 fw-bold"> Create Your Account</h4>
      <div className="mt-2">
        <span className="p-input-icon-left w-100">
          <i className="pi pi-user" />
          <InputTextComponent placeholder="First name" name="firstname" value={form?.firstName}
            onChange={(e) => {
              setErrors({
                ...errors,
                firstName: null,
              });
              setForm({
                ...form,
                firstName: e.target.value
              })
            }} />
            {errors.firstName && <span className="error">{errors.firstName}</span>}
        </span>
        <span className="p-input-icon-left w-100">
          <i className="pi pi-user" />
          <InputTextComponent placeholder="Last name" name="lastname" value={form?.lastName}
            onChange={(e) => {
              setErrors({
                ...errors,
                lastName: null,
              });
              setForm({
                ...form,
                lastName: e.target.value
              })
            }} />
          {errors.lastName && <span className="error">{errors.lastName}</span>}
        </span>
        <span className="p-input-icon-left w-100 mb-2">
          <i className="pi pi-envelope" />
          <InputTextComponent placeholder="Email" name="email" value = {form.email}
          onChange = {(e)=>{
            setErrors({
              ...errors,
            email: null,
            });
            setForm({
              ...form,
              email:e.target.value
            })
          }} />
          {errors.email && <span className="error">{errors.email}</span>}
        </span>
        <span className="p-input-icon-left w-100">
          <PhoneInput className="w-100" value={form.mobile} onChange={handleOnChange}  />
          
        </span>
        {errors.mobile && <span className="error">{errors.mobile}</span> }
        <span className="p-input-icon-left w-100">
          <i className="pi pi-lock" style={{ zIndex: "1" }} />
          <PasswordComponent placeholder="Password" name="password" value = {form.password}
          onChange = {(e)=>{
            setErrors({
              ...errors,
            password: null,
            });
            setForm({
              ...form,
              password:e.target.value
            })
          }} />
          {errors.password && <span className="error">{errors.password}</span>}
        </span>
        <span className="p-input-icon-left w-100">
          <i className="pi pi-lock" style={{ zIndex: "1" }} />
          <PasswordComponent
            placeholder="Confirm Password"
            name="confirmPassword"
            form = {form.confirmPassword}
            onChange = {(e)=>{
              setErrors({
                ...errors,
              confirmPassword: null,
              });
              setForm({
                ...form,
                confirmPassword:e.target.value
              })
            }}
          />
          {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}
        </span>
      </div>
      <div className="d-flex align-items-baseline mb-2">
        <div className="mt-3 m-2">
          <CheckboxComponent
            label="I accept Terms & conditions"
            labelClassName="text-black"
            checked={checked}
            setChecked={setChecked}
          />
        </div>
      </div>
      <MainButton label="Sign up" onClick={handleSubmitOtp} />
      <div className="d-flex justify-content-center my-3">
        <p className="sub-heading-2">Already have an account?</p>&nbsp;
        <span className="sign-text h6 link text-main text-decoration-none cursor-pointer" onClick={()=>setLoginVisible(true)}>
          Sign in
        </span>
      </div>
      <CustomModal header={<img src={Logo} alt="logo" className="mx-auto d-block" />} visible={loginVisible} onHide={() => setLoginVisible(false)}>
        <Login />
        </CustomModal>
    </div>
      }
      
      {selectedStep === "2" && <OtpVerify form ={form} setForm = {setForm} handleSubmit = {handleSubmit} setSelectedStep= {setSelectedStep} setErrors ={setErrors} errors = {errors} handleSubmitOtp = {handleSubmitOtp}/> }
    </div>
  );
}
