import React from "react";
import { InputText } from "primereact/inputtext";

export default function InputTextComponent({
  label,
  name,
  errorMessage,
  extraClassName,
  inputClass,
  labelClassName,
  required,
  placeholder,
  ...props
}) {
  return (
    <div className={extraClassName}>
      {label ? (
        <label
          htmlFor={name}
          className={`text-sm text-gray form-label ${labelClassName}`}
        >
          {label} {required && <span className="p-error">*</span>}
        </label>
      ) : null}
      <InputText
        id={name}
        name={name}
        placeholder={placeholder}
        className={`w-100 rounded border-none ${inputClass} `}
        {...props}
        errorMessage = {errorMessage}
      />
    </div>
  );
}
