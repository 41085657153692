import React, { useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import { Container } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Artist from "./Artist";
import Layout from "../../Layout"
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { allCategoriesAction } from "../../../../redux/clientAction/allCategoriesAction";
import { searchBusinessAction } from "../../../../redux/clientAction/allCategoriesAction";
export default function SinglePage() {
  const categories = useSelector((state) => state.allCatergory.categoryProfile)
  const dispatch = useDispatch()
  const navigate =  useNavigate()
  useEffect(() => {
    dispatch(allCategoriesAction())
  }, [])
  const handleCateorySubmit = (name)=>{
    dispatch(searchBusinessAction({search:name},navigate(`/service/${name}`)))
  }
  return (
    <div>
      <Layout>
        <Tab.Container id="left-tabs-example" defaultActiveKey="artist">
          <div className="shadow-sm">
            <Container className="">
              <Nav
                variant="pills"
                className="flex-row justify-content-between service-tabs "
              >
                {categories?.length > 0 && categories?.map((item, index) => {
                  return (
                    <Nav.Item>
                      <Nav.Link eventKey={item?.categoryName} className="p-3" onClick={()=>handleCateorySubmit(item.categoryName)}>
                        {item?.categoryName}
                      </Nav.Link>
                    </Nav.Item>
                  )
                })}
                {/* <Nav.Item>
                  <Nav.Link eventKey="beauty" className="p-3">
                    Beauty
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="carpenter" className="p-3">
                    Carpenter
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="painter" className="p-3">
                    Painter
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="cleaning" className="p-3">
                    Cleaning
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="logistic" className="p-3">
                    Logistic
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="electrician" className="p-3">
                    Electrician
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="carwash" className="p-3">
                    Car Wash
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="more" className="p-3">
                    More..
                  </Nav.Link>
                </Nav.Item> */}
              </Nav>
            </Container>
          </div>
          <div>
            <Tab.Content>
              <Container>
                <Tab.Pane eventKey="artist">
                  <Artist />
                </Tab.Pane>
                <Tab.Pane eventKey="beauty">Second tab content</Tab.Pane>
                <Tab.Pane eventKey="carpenter">Second tab content</Tab.Pane>
                <Tab.Pane eventKey="painter">Second tab content</Tab.Pane>
                <Tab.Pane eventKey="cleaning">Second tab content</Tab.Pane>
                <Tab.Pane eventKey="logistic">Second tab content</Tab.Pane>
                <Tab.Pane eventKey="electrician">Second tab content</Tab.Pane>
                <Tab.Pane eventKey="carwash">Second tab content</Tab.Pane>
                <Tab.Pane eventKey="more">Second tab content</Tab.Pane>
              </Container>
            </Tab.Content>
          </div>
        </Tab.Container>
        <div className="container"></div>
      </Layout>
    </div>
  );
}
