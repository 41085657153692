import HairstyleImg from "../../../../assets/images/hairstyle.png";
import CarIcon from "../../../../assets/icons/cars.png";
import WifiIcon from "../../../../assets/icons/wifi.png";
import CreditCardIcon from "../../../../assets/icons/creditcard.png";
import AccessibleIcon from "../../../../assets/icons/acciable.png";
import HeartIcon from "../../../../assets/icons/heart.png";
import ChildIcon from "../../../../assets/icons/child.png";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { bookSlotsAction } from "../../../../redux/clientAction/allCategoriesAction";
import { addressAction, getAddressAction } from "../../../../redux/clientAction/addressAction"
import { paymentAction } from "../../../../redux/clientAction/paymentAction";
import { showLoaderAction } from "../../../../redux/action/loaderAction";
const ServiceBannerContent = () => {
  const dispatch = useDispatch()
  const [reviewsData, setReviewsData] = useState([])
  const [dateMonth, setDateMonth] = useState()
  const [date, setDate] = useState(new Date());
  const [slotProgress, setslotProgress] = useState()
  const[timeData,setTimeData] =  useState("")
  const [addressForm, setAddressForm] = useState({
    line1: "",
    line2: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    name: ""
  })
  const [bookSlot, setBookSlot] = useState({
    bookingDate: "",
    services: [],
    startTime: "",
    addressId: "",
    notes: ""
  })
  const [payment, setPayment] = useState({
    cardNumber: "",
    cardMonth: "",
    cardYear: "",
    cardCVV: "",
    cardHolderName: "",
  })
  const [error, setError] = useState({})
  const data = useSelector((state) => state.clientgetBussinessById.getBussinessByIdProfile)
  const service = data
  const BannerContent = data.workplace
  const reviews = useSelector((state) => state.clientgetBusinessReviewsById.getBusinessReviewsByIdProfile);
  useEffect(() => {
    let array = []
    if (reviews) {
      reviews?.reviews?.map((item) => {
        let todayDate = new Date()
        let dates = new Date(todayDate)
        let years = dates.getFullYear();
        let months = dates.getMonth() + 1;
        let dts = dates.getDate();
        if (dts < 10) {
          dts = '0' + dts;
        }
        if (months < 10) {
          months = '0' + months;
        }
        let dobs = months + '-' + dts + '-' + years;
        let date = new Date(item.time)
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();
        if (dt < 10) {
          dt = '0' + dt;
        }
        if (month < 10) {
          month = '0' + month;
        }
        let dob = month + '-' + dt + '-' + year;

        const dt_date1 = new Date(dob);
        const dt_date2 = new Date(dobs);
        const date1_time_stamp = dt_date1.getTime();
        const date2_time_stamp = dt_date2.getTime();
        let calc;
        if (date1_time_stamp > date2_time_stamp) {
          calc = new Date(date1_time_stamp - date2_time_stamp);
        } else {
          calc = new Date(date2_time_stamp - date1_time_stamp);
        }
        const calcFormatTmp =
          calc.getDate() + '-' + (calc.getMonth() + 1) + '-' + calc.getFullYear();
        //Convert to an array and store
        const calcFormat = calcFormatTmp.split('-');
        //Subtract each member of our array from the default date
        const days_passed = Number(Math.abs(calcFormat[0]) - 1);
        const months_passed = Number(Math.abs(calcFormat[1]) - 1);
        const years_passed = Number(Math.abs(calcFormat[2]) - 1970);

        //Set up custom text
        const yrsTxt = ['year', 'years'];
        const mnthsTxt = ['month', 'months'];
        const daysTxt = ['day', 'days'];

        //Convert to days and sum together
        const total_days =
          years_passed * 365 + months_passed * 30.417 + days_passed;

        //display result with custom text
        const result =
          (years_passed == 1
            ? years_passed + ' ' + yrsTxt[0] + ' '
            : years_passed > 1
              ? years_passed + ' ' + yrsTxt[1] + ' '
              : '') +
          (months_passed == 1
            ? months_passed + ' ' + mnthsTxt[0]
            : months_passed > 1
              ? months_passed + ' ' + mnthsTxt[1] + ' '
              : '') +
          (days_passed == 1
            ? days_passed + ' ' + daysTxt[0]
            : days_passed > 1
              ? days_passed + ' ' + daysTxt[1]
              : '');

        item.month = result
        array.push(item)
      })
    }
    setReviewsData(array)
  }, [reviews])
  const handleAddress = () => {
    if (addressForm.line1 === "" || addressForm.city === "") {
      setError({
        ...error,
        line1: addressForm.line1 === "" ? "Please Enter Address" : error.line1,
        city: addressForm.city === "" ? "Please Enter City" : error.city,
      })
      return false;
    }
    dispatch(addressAction(addressForm))
  }
  const getAddress = useSelector((state) => state.clientgetAddress.getAddressProfile)
  useEffect(() => {
    dispatch(getAddressAction())
  }, [])

  const slotsData = useSelector((state) => state.clientgetSlots.getSlotsProfile)
  const viewAddress = useSelector((state) => state.clientaddAddress.addressProfile)
  const viewBookingData = useSelector((state) => state.clientBookSlots.bookSlotsProfile)
  const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const monthsName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const d = new Date(viewBookingData.bookingDate);
  let day = weekday[d.getDay()];
  useEffect(() => {

    let dates = new Date(viewBookingData.bookingDate)
    let years = dates.getFullYear();
    let months = dates.getMonth() + 1;
    let ds = new Date(months.toString());
    let name = monthsName[ds.getMonth()];
    let dts = dates.getDate();
    if (dts < 10) {
      dts = '0' + dts;
    }
    if (months < 10) {
      months = '0' + months;
    }

    let dateMo = name + ' ' + dts + ',' + years;
    setDateMonth(dateMo)
  }, [viewBookingData])
  const handlePaymentMethod = () => {
    dispatch(paymentAction())
  }
  useEffect(() => {
    let array = []
    let startTime 
    let endTime
    service?.timings?.map((item, index) => {
      startTime = item.startTime
      ?.toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [startTime];

    if (startTime.length > 1) {
      // If time format correct
      startTime = startTime.slice(1); // Remove full string match value
      startTime[5] = +startTime[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      startTime[0] = +startTime[0] % 12 || 12; // Adjust hours
    }
    endTime = item.endTime
    ?.toString()
    .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [endTime];

  if (endTime.length > 1) {
    // If time format correct
    endTime = endTime.slice(1); // Remove full string match value
    endTime[5] = +endTime[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    endTime[0] = +endTime[0] % 12 || 12; // Adjust hours
  }
      let obj = {
        breakEndTime: item.breakEndTime,
        breakStartTime: item.breakStartTime,
        isClosed: item.isClosed,
        weekDay: item.weekDay,
        _id: item._id,
        endTime: endTime.join(''),
        startTime: startTime.join('')
      }
      array.push(obj)
    })
    setTimeData(array)
  }, [service])

  const reportsArray = [
    {reportTitle:"Sexual Content",reportComment:"Sexual Content"},
    {reportTitle:"Violent or repulsive content",reportComment:"Violent or repulsive content"},
    {reportTitle:"Hateful dangerous act",reportComment:"Hateful dangerous act"},
    {reportTitle:"Child abuse",reportComment:"Child abuse"},
    {reportTitle:"infringes my rights",reportComment:"infringes my rights"},
    {reportTitle:"Promotes terrorism",reportComment:"Promotes terrorism"},
    {reportTitle:"Spam or misleading",reportComment:"Spam or misleading"}
  ]
  return { dateMonth, payment, setPayment, handlePaymentMethod,reportsArray, timeData,BannerContent, service, reviewsData, slotsData, date, slotProgress, setslotProgress, addressForm, setAddressForm, handleAddress, error, setError, getAddress, viewAddress, bookSlot, setBookSlot, viewBookingData, day };
};
export default ServiceBannerContent;
