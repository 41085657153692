import React from "react";
import { Password } from "primereact/password";

export default function PasswordComponent({
  inputClass,
  extraClassName,
  name,
  placeholder,
...props}
) {
  return (
    <div className={` ${extraClassName}`}>
      <Password
        toggleMask
        placeholder={placeholder}
        className={`w-100 rounded border-none ${inputClass}`}
        name={name}
        {...props}
      />
    </div>
  );
}
