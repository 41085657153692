import React, { useState,useEffect } from "react";
import { Card } from "react-bootstrap";
import MainButton from "../../../components/buttons/MainButton";
import OTPInput from "react-otp-input";
import NewPassword from "../new-password/NewPassword";
import CustomModal from "../../../components/Modal/CustomModal";

export default function Otp({form,setForm,handleSubmit,setErrors,errors,setForgetVisible,setSendOtpVisible,setLoginVisible}) {
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [counter, setCounter] = useState(60);
  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000)
    }
  }, [counter]);
  const resendOtp = () => {
    setCounter(60);
    handleSubmit()
  }
 const handlePassword =()=>{
  if(form.otp===""){
    setErrors({
      ...errors,
      otp:form.otp=== ""?"Please Enter OTP":errors.otp
    })
    return false;
  }
  else{
    setNewPasswordVisible(true)
  }
 }
  return (
    <div>
      <Card className=" border-0 w-100">
        <div className="p-4 login">
          <h3 className="heading text-center fw-bold">OTP Verifications</h3>
          <p className="text-center text-gray">
            Code has been send to registered email: <br />{" "}
            <span>{form.email}</span>
          </p>
          <div className="mb-3 verify-input">
            <OTPInput
            value ={form.otp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  otp:null
                })
               setForm({
                ...form,
                otp:e
               })
              }}
            />
            {errors.otp && <span className="error">{errors.otp}</span>}
          </div>
          <p className="text-main text-center fw-semibold">
          {counter === 0 ? <span onClick={resendOtp}>Resend code </span> : <><span>Resend code </span><span className="text-dark">in</span> {counter}
          <span className="text-dark">s</span></>}
          </p>
          <MainButton
            label="Continue"
            className="fw-semibold"
            onClick={() =>handlePassword()}
          />
        </div>
      </Card>
      <CustomModal
        visible={newPasswordVisible}
        onHide={() => setNewPasswordVisible(false)}
      >
        <NewPassword  form={form} setForm={setForm} errors ={errors} setErrors ={setErrors} setSendOtpVisible = {setSendOtpVisible} setForgetVisible = {setForgetVisible} setLoginVisible={setLoginVisible}/>
      </CustomModal>
    </div>
  );
}
