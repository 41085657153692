import { types } from "../types/types";

const intitalState = {
    addressProfile: {},
    getAddressProfile: [],
    putAddressProfile: [],
    delteAddressProfile: {}
}
export const addressReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CLIENT_ADD_ADDRESS:
            return {
                ...state,
                addressProfile: action.payload
            };
        default:
            return { ...state }
    }
}


export const getAddressReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CLIENT_GET_ADDRESS:
            return {
                ...state,
                getAddressProfile: action.payload
            };
        default:
            return { ...state }
    }
}

export const putAddressReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CLIENT_PUT_ADDRESS:
            return {
                ...state,
                putAddressProfile: action.payload
            };
        default:
            return { ...state }
    }
}

export const deleteAddressReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.DELETE_ADDRESS:
            return {
                ...state,
                delteAddressProfile: action.payload
            };
        default:
            return { ...state }
    }
}