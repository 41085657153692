import {api} from "../../services/api";
import Constants from "../../services/Constants";
import { authenticate, isAuthenticated,providerAuthenticate,providerIsAuthenticated } from "../../services/authService";
import { types } from "../types/types";
import { showToast } from "./toastAction";
import { hideLoaderAction,showLoaderAction } from "./loaderAction";
export const loginAction = (data, navigate) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.LOGIN, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.USER_DETAIL,
                payload: res.data,
            });

            providerAuthenticate(res.data.token, () => {
                if (providerIsAuthenticated()) {
                    localStorage.setItem("name",res.data.firstName)
                    localStorage.setItem("email",res?.data?.email)
                    localStorage.setItem("image",res?.data?.image)
                    navigate("/provider/appointment");
                    dispatch(showToast({ severity: "success", summary: res.message }));
                }
            });
            dispatch(hideLoaderAction())
        }
    } else {
        navigate("/login");
        dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch(hideLoaderAction())
    }
}