import jwt_decode from "jwt-decode";

export const authenticate = (token, next) => {
    if (typeof window !== "undefined") {
        localStorage.setItem("token", JSON.stringify(token));
        next();
    }
};
export const isAuthenticated = () => {
    
    if (typeof window == "undefined") {
        return false;
    }
    if (localStorage.getItem("token")) {
        return JSON.parse(localStorage.getItem("token"));
    } else {
        return false;
    }
};

export const providerAuthenticate = (token, next) => {
    if (typeof window !== "undefined") {
        localStorage.setItem("providerToken", JSON.stringify(token));
        next();
    }
};
export const providerIsAuthenticated = () => {
    if (typeof window == "undefined") {
        return false;
    }
    if (localStorage.getItem("providerToken")) {
        return JSON.parse(localStorage.getItem("providerToken"));
    } else {
        return false;
    }
};