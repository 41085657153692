import {api} from "../../services/api";
import Constants from "../../services/Constants";
import { authenticate,isAuthenticated,providerAuthenticate,providerIsAuthenticated } from "../../services/authService";
import { types } from "../types/types";
import { showToast } from "./toastAction";
import { hideLoaderAction,showLoaderAction } from "./loaderAction";
export const signupAction = (data,next) =>async(dispatch)=>{
    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.SIGNUP, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.SINGUP_DETAIL,
                payload: res.data,
            });
            
            providerAuthenticate(res.data.token, () => {
                if (res.success) {
                    dispatch(showToast({ severity: "success", summary: res.message }));  
                    localStorage.setItem("name",res?.data?.firstName)
                    localStorage.setItem("email",res?.data?.email)
                    localStorage.setItem("image",res.data?.image)
                    next()  
                } 
            });
            dispatch(hideLoaderAction())
        }        
    }else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch(hideLoaderAction())
    }
}          