import React from "react";
import InputTextComponent from "../../components/input/InputTextComponent";
import { Link } from "react-router-dom";
import MainButton from "../../components/buttons/MainButton";
import { PhoneInput } from "react-contact-number-input";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/action/toastAction";
export default function About({ error, setError, selectedStep, setSelectedStep, form, setForm }) {
  const dispatch =  useDispatch();
  let handleOnChange = value => {
    setForm({
      ...form, mobile: value.phoneNumber,
      countryCode:value.countryCode
    });
  };
  const handleSubmit = async()=>{
    if(form.businessName === "" ||  form.mobile === null){
      dispatch(showToast({ severity: "error", summary: "Required all Fields", content: "Required all Fields" }));
    }else{
      setSelectedStep("2")
    }
  }
  return (
    <div>
      {selectedStep === "1" &&
        <div className="d-block  mt-2 ">
          {/* <i className="pi pi-arrow-left text-main fs-3 mb-2 cursor-pointer" onClick={() => setSelectedStep()}></i> */}
          <h4 className="text-main fw-bold">About You</h4>
          <small className="">Tell us more about yourself your business.</small>
          <div className="mt-3">
            <InputTextComponent
              extraClassName="mb-3"
              placeholder="Business name"
              value={form.businessName}
              onChange={(e) => {
                setError({
                  ...error,
                  businessName: null,
                });
                setForm({ ...form, businessName: e.target.value });
              }}
            />
            <PhoneInput className="w-100" value={form.mobile} onChange={handleOnChange} />
          </div>
          <Link to="">
            <MainButton label="Continue" onClick={handleSubmit} />
          </Link>
        </div>
      }
    </div>
  );
}
