import React, { useState } from "react";
import InputTextComponent from "../../../components/input/InputTextComponent";
import { Password } from "primereact/password";
import FacebookIcon from "../../../assets/icons/facebook.png";
import GoogleIcon from "../../../assets/icons/google.png";
import AppleIcon from "../../../assets/icons/Apple.png";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import MainButton from "../../../components/buttons/MainButton";
import CustomModal from "../../../components/Modal/CustomModal";
import Logo from "../../../assets/icons/Logo.png";
import ForgetPassword from "../forgetPassword/ForgetPassword";
import CreateAccount from "../sigup/CreateAccount";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clientLoginAction } from "../../../redux/clientAction/loginAction";
export default function Login() {
  const [forgetVisible, setForgetVisible] = useState(false);
  const [signupVisible, setSignupVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [form, setForm] = useState({
    email: "",
    password: ""
  })
  const [errors, setErrors] = useState({
    email: "",
    password: ""
  })
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.email === "" || form.password=== "") {
      setErrors({
        ...errors,
        email:form.email === ""?"Please Enter Email":errors.email,
        password:form.password ===""?"Please Enter Password":errors.password
      })
      return false;
    }
    dispatch(clientLoginAction(form, navigate))
  };
  return (
    <div>
      <Card className=" border-0 w-100">
        <div className="p-4 login">
          <h4 className="heading text-center fw-semibold">
            Login into InstaJamica
          </h4>
          <div className="mt-3">
            <span className="p-input-icon-left w-100">
              <i className="pi pi-envelope" />
              <InputTextComponent id="email" type="email" placeholder="Email" value={form.email} onChange={(e) => {
                setField("email", e.target.value);
              }} />
              {errors.email && <span className="error">{errors.email}</span>}
            </span>
          </div>
          <div className="mt-3">
            <span className="p-input-icon-left" style={{ width: "100%" }}>
              <i className="pi pi-lock" style={{ zIndex: "1" }} />
              <Password toggleMask feedback={false} value={form.password} placeholder = "Password" onChange={(e) => {
                setField("password", e.target.value);
              }} />
              {errors.password && <span className="error">{errors.password}</span>}
            </span>
          </div>
          <div className="text-end mt-4 ">
            <span
              className=" h6 text-end text-main text-decoration-none text-end cursor-pointer"
              onClick={() => setForgetVisible(true)}
            >
              Forgot Password?
            </span>
          </div>
          <div className="my-3 d-block ">
            <MainButton label="Login" className="border-0 fw-semibold" onClick={handleSubmit} />
          </div>
          <div>
            <p className="sub-heading position-relative text-center">
              or continue with
            </p>
          </div>
          <div className="d-flex justify-content-center my-1">
            <div className="logos border cursor-pointer">
              <img src={FacebookIcon} alt="" />
            </div>
            <div className="logos border cursor-pointer ms-3">
              <img src={GoogleIcon} alt="" />
            </div>
            <div className="logos border cursor-pointer ms-3">
              <img src={AppleIcon} alt="" />
            </div>
          </div>
          <div className="d-flex justify-content-center my-3">
            <p className="sub-heading-2">Don’t have an account?</p>&nbsp;
            <span className="sign-text h6 link text-main text-decoration-none cursor-pointer" onClick={() => setSignupVisible(true)}>
              Sign up
            </span>
          </div>
        </div>
      </Card>
      <CustomModal
        header={<img src={Logo} alt="logo" className="mx-auto d-block" />}
        visible={forgetVisible}
        onHide={() => setForgetVisible(false)}
      >
        <ForgetPassword forgetVisible = {forgetVisible} setForgetVisible = {setForgetVisible}/>
      </CustomModal>
      <CustomModal header={<img src={Logo} alt="logo" className="mx-auto d-block" />} visible={signupVisible} onHide={() => setSignupVisible(false)}>
        <CreateAccount />
      </CustomModal>
    </div>
  );
}
