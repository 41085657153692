import React,{ useEffect }  from "react";
import { Col, Row } from "react-bootstrap";
import { privacyPolicyAction } from "../../../../redux/clientAction/ProfileAction";
import { useSelector,useDispatch } from "react-redux";

export default function PrivacyPolicy() {
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(privacyPolicyAction())
  },[])
  const privacy = useSelector((state)=>state.privacyPolicy.privacyPolicyProfile)
  return (
    <div className="px-4">
      <p className="text-lg bg-main p-3 text-white curve mb-3">
        Privacy Policy
      </p>
      <Row>
        <Col sm={12}>
        <div dangerouslySetInnerHTML={{__html: privacy.data}} />
          {/* <p className="text-dark fw-bold text-lg">1. Overview</p>
          <small className="text-gray">
            We are a networking platform dedicated to bringing the world
            together through live experiences. Through our platform, mobile apps
            and services, we enable people all over the world to create,
            discover, share and register for activities.
          </small>
          <br />
          <br />
          <p className="text-dark fw-bold text-lg">2. Our Privacy Statement</p>
          <small className="text-gray">
            This Privacy Policy sets forth our policy with respect to
            information that can be associated with or which relates to a person
            and/or could be used to identify a person ("Personal Data") that is
            collected from Users on or through the Services.
          </small>
          <br />
          <br />
          <small className="text-gray">
            This policy also covers personal data collected from Evenline sales
            customers and prospects. We take the privacy of your Personal Data
            seriously. Because of that, we have created this Privacy Policy.
            Please read this Privacy Policy as it includes important information
            regarding your Personal Data and other information.
          </small>
          <br />
          <br />
          <p className="text-dark fw-bold text-lg">
            3. Personal Date We Collect
          </p>
          <small className="text-gray">
            When you use or interact with us through the Services or engage with
            Cool Connects as a sales customer or prospect, we may collect
            Personal Data.
          </small>
          <br />
          <br />
          <small className="text-gray">
            Sometimes this will be on our own behalf and other times this will
            be on behalf of an Organizer using our Services to run an event.
            This is an important distinction for purposes of certain data
            protection laws and is explained in more
          </small> */}
        </Col>
      </Row>
    </div>
  );
}
