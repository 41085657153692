import React from "react";
import ArrowRight from "../../assets/icons/arrow-right.png";
import Layout from "../../layout";
import InputTextComponent from "../../components/input/InputTextComponent";
import InputNumberComponent from "../../components/input/InputNumberComponent";
import InputTextAreaComponent from "../../components/input/InputTextAreaComponent";
import MainButton from "../../components/buttons/MainButton";

export default function BussinessInfo() {
  return (
    <div>
      <Layout>
        <div className="p-5">
          <div className="d-flex align-items-center mb-3">
            <img src={ArrowRight} width={25} alt="" />
            <h4 className="text-main fw-bold mb-0 ms-3">
              Business Name & Info
            </h4>
          </div>
          <div className="bg-white p-5 rounded">
            <div className="row">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-6 border-end pe-5">
                    <h5 className="text-gray fw-bold">General Info</h5>
                    <InputTextComponent
                      label="Business name"
                      placeholder="AC Repair"
                      extraClassName="mb-3"
                    />
                    <InputNumberComponent
                      label="Phone Number"
                      placeholder="(836) 895-5263"
                      extraClassName="mb-3"
                    />
                    <InputTextComponent
                      type="email"
                      label="Email Address"
                      placeholder="john@gmail.com"
                      extraClassName="mb-3"
                    />
                    <InputTextAreaComponent
                      label="Short Description"
                      placeholder="Description of your business(recommended)"
                      extraClassName="mb-3"
                    />
                    <div className="d-flex">
                      <MainButton label="Save" className="px-5" />
                    </div>
                  </div>
                  <div className="col-md-6 ps-5">
                    <h5 className="text-gray fw-bold">Social Media Link</h5>
                    <div className="p-inputgroup bg-light-color rounded mb-3">
                      <span className="p-inputgroup-addon border-0 bg-transparent border-end">
                        <i className="pi pi-instagram"></i>
                      </span>
                      <InputTextComponent
                        placeholder="Instagram"
                        extraClassName="w-100"
                      />
                    </div>
                    <div className="p-inputgroup bg-light-color rounded mb-3">
                      <span className="p-inputgroup-addon border-0 bg-transparent border-end">
                        <i className="pi pi-facebook"></i>
                      </span>
                      <InputTextComponent
                        placeholder="Facebook"
                        extraClassName="w-100"
                      />
                    </div>
                    <div className="p-inputgroup bg-light-color rounded mb-3">
                      <span className="p-inputgroup-addon border-0 bg-transparent border-end">
                        <i className="pi pi-globe"></i>
                      </span>
                      <InputTextComponent
                        placeholder="Website"
                        extraClassName="w-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
