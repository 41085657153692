import React, { useState } from "react";
import Layout from "../../layout";
import MainButton from "../../components/buttons/MainButton";
import MemberCard from "../../components/cards/card";

export default function MemberShip() {
  const [ChangeColor, setChangeColor] = useState(true);
  const onClickChangeColor = () => {
    setChangeColor(!ChangeColor);
  };
  return (
    <div>
      <Layout>
        <div className="p-5">
          <div className="d-flex mb-3">
            <h4 className="text-main fw-bold mb-0 ms-3">Membership</h4>
          </div>
          <div className="bg-white rounded p-4">
            <div className="row justify-content-center text-center align-items-end mb-3">
              <div className="col-md-8">
                <div className="text-center">
                  <h2 className="text-main fw-bold">Full Access</h2>
                  <p className="text-main m-0">
                    Get Plus membership and access
                  </p>
                  <p className="text-main">features of InstaJamaica.</p>
                </div>

                <div className="row align-items-center justify-content-center m-0 p-0">
                  <div className="col-lg-3">
                    <MemberCard title="Monthly" price="$15">
                      <div className="d-flex text-start mt-3">
                        <div className="box-check rounded-circle d-flex flex-column justify-content-center align-items-center">
                          <i
                            className="pi pi-check"
                            style={{ color: "white", fontSize: "10px" }}
                          ></i>
                        </div>
                        <div className="conditions text-xs ms-2">
                          Offers visibility to potential customers
                        </div>
                      </div>
                      <div className="d-flex text-start mt-3 mb-3">
                        <div className="box-check rounded-circle d-flex flex-column justify-content-center align-items-center">
                          <i
                            className="pi pi-check"
                            style={{ color: "white", fontSize: "10px" }}
                          ></i>
                        </div>
                        <div className="conditions text-xs ms-2">
                          Enhance credibility
                        </div>
                      </div>
                      <MainButton label="Choose plan" className="text-sm" />
                    </MemberCard>
                  </div>
                  <div className="col-lg-3">
                    <MemberCard
                      headingclass="text-white-color"
                      priceclass="text-white-color"
                      title="Half Yearly"
                      price="$25"
                      cardclass="bg-main"
                    >
                      <div className="d-flex text-start mt-3">
                        <div className="box-check bg-white rounded-circle d-flex flex-column justify-content-center align-items-center">
                          <i
                            className="pi pi-check text-main"
                            style={{ fontSize: "12px" }}
                          ></i>
                        </div>
                        <div className="conditions text-white-color text-xs ms-2">
                          Allows providers to gain exposure,
                        </div>
                      </div>

                      <div className="d-flex text-start mt-3">
                        <div className="box-check bg-white rounded-circle d-flex flex-column justify-content-center align-items-center">
                          <i
                            className="pi pi-check text-main"
                            style={{ fontSize: "12px" }}
                          ></i>
                        </div>
                        <div className="conditions text-white-color text-xs ms-2 w-auto">
                          Increasing opportunities for service provision.
                        </div>
                      </div>
                      <div className="mt-4">
                        <MainButton
                          label="Choose plan"
                          className="bg-white text-main text-sm"
                        />
                      </div>
                    </MemberCard>
                  </div>
                  <div className="col-lg-3">
                    <MemberCard title="Yearly" price="$250">
                      <div className="d-flex text-start mt-3">
                        <div className="box-check rounded-circle d-flex flex-column justify-content-center align-items-center">
                          <i
                            className="pi pi-check"
                            style={{ color: "white", fontSize: "12px" }}
                          ></i>
                        </div>
                        <div className="conditions text-xs ms-2">
                          Increase bookings from customers.
                        </div>
                      </div>

                      <div className="d-flex text-start mt-3">
                        <div className="box-check rounded-circle d-flex flex-column justify-content-center align-items-center">
                          <i
                            className="pi pi-check"
                            style={{ color: "white", fontSize: "12px" }}
                          ></i>
                        </div>
                        <div className="conditions text-xs ms-2">
                          Increasing opportunities for service provision.
                        </div>
                      </div>
                      <div className="mt-3">
                        <MainButton label="Choose plan" className="text-sm" />
                      </div>
                    </MemberCard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
