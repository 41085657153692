import React, { useEffect, useState } from "react";
import { Carousel } from "primereact/carousel";
import { Button } from "primereact/button";
import HomeContent from "./HomeContent";
import { Avatar } from "primereact/avatar";
import { searchBusinessAction, likeunlikeProtofiloAction, getBussinessAction } from "../../redux/clientAction/allCategoriesAction";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from 'primereact/dialog';
import { useNavigate } from "react-router-dom";
export default function Inspired() {
  const { categories } = HomeContent();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false);
  const [categoryName, setCategoryName] = useState("")
  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "991px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  let token = localStorage.getItem("token")
  const handleSubmit = (id) => {
    if (token) {
      dispatch(searchBusinessAction({ categoryId: id }))
    }

  }
  const handleLikeUnlike = (id, categories) => {
    if (token) {
      dispatch(likeunlikeProtofiloAction(id)).then(() => {
        dispatch(searchBusinessAction({ categoryId: categories }))
        dispatch(getBussinessAction())
      })
    }
  }
  const handleView = () => {
    if (categoryName !== "") {
      dispatch(searchBusinessAction({ search: categoryName }, navigate(`/service/${categoryName}`)))
    }
  }
  const search = useSelector((state) => state.recentSearchBussiness.recentSearchBussinessProfile)

  const ServiceTemplate = (categories) => {
    return (
      <div className="border-1 surface-border curve m-2 text-center py-4">
        <div className=" bg-white py-2" onClick={() => { handleSubmit(categories._id); setVisible(true); setCategoryName(categories.categoryName) }}>
          <Avatar
            image={`https://instajamaica-api.appdeft.biz/${categories.categoryImage}`}
            size="xlarge"
            shape="circle"
            className="rounded-circle overflow-hidden inspired-serviceimg"
          />
          <p className="mt-0 text-sm mb-0">{categories.categoryName}</p>
        </div>
        <Dialog visible={visible} modal={false} style={{ width: '35vw' }} onHide={() => setVisible(false)}>
          <div className="border-1 surface-border curve overflow-hidden m-2 bg-white p-2" >
            {search?.businesses?.length > 0 && search?.businesses?.map((item, index) => {
              return (
                <div className="border-1 surface-border curve overflow-hidden m-2 bg-white p-2">
                  {index <= 2 ? <span className="row text-dark text-decoration-none">
                    <div className="mb-4 col-md-4">
                      <img
                        src={`https://instajamaica-api.appdeft.biz/${item.image?.workPlaceURL}`}
                        alt=""
                        className="w-100 h-100 cover-img shadow-2"
                      />
                    </div>
                    <div className="col-md-8">
                      <h5 className="mb-1 fw-semibold" onClick={() => navigate(`/single-page/${item._id}`)}>
                        {item.businessName}
                      </h5>
                      <p className="mt-0 mb-3 text-md">{item.address?.line1},{item.address?.city},{item.address?.zipCode}</p>

                      <div className="d-flex align-items-center flex-wrap justify-content-between gap-1">
                        <p className="m-0">
                          {item?.rating} <i className="pi pi-star-fill text-golden"></i>{" "}
                          <small>/{item?.reviewCount} reviews</small>
                        </p>
                        <Button
                          onClick={() => handleLikeUnlike(item._id, item.category)}
                          icon={item.isLiked === 1 ? "pi pi-heart-fill" : "pi pi-heart"}
                          className={item.isLiked === 1 ? `bg-transparent border-0 text-dark liked` : `bg-transparent border-0 text-dark unliked`}
                        />
                      </div>
                    </div>
                  </span> : null}
                </div>
              )
            })}
            <div className="d-flex justify-content-end">
              <Button className = "rounded-3" onClick={handleView}>View All</Button>
            </div>

          </div>
        </Dialog>
      </div>
    );
  };
  return (
    <div>
      <div className="container-fluid position-relative py-4">
        <div className="d-flex justify-content-center">
          <h2 className="fw-semibold">Get inspired with InstaJamaica</h2>
        </div>
        <Carousel
          value={categories}
          showNavigators="false"
          numScroll={1}
          numVisible={8}
          className="inspiredservice-carousel recommendedservice px-5"
          responsiveOptions={responsiveOptions}
          itemTemplate={ServiceTemplate}
        />
      </div>
    </div>
  );
}
