import { ImCalendar } from "react-icons/im";
import { AiOutlineUsergroupDelete } from "react-icons/ai";
import { BiWallet } from "react-icons/bi";
import { MdCardMembership } from "react-icons/md";
import { MdOutlineVerified } from "react-icons/md";
import { ImProfile } from "react-icons/im";
import { IoMdSettings } from "react-icons/io";

export const MenuItems = [
  {
    path: "/provider/appointment",
    name: "Appointments",
    icon: <ImCalendar />,
  },
  {
    path: "/provider/client",
    name: "Clients",
    icon: <AiOutlineUsergroupDelete />,
  },
  {
    path: "/provider/appointment",
    name: "Wallet",
    icon: <BiWallet />,
  },
  {
    path: "/provider/appointment",
    name: "Membership",
    icon: <MdCardMembership />,
  },
  {
    path: "/provider/appointment",
    name: "Verification",
    icon: <MdOutlineVerified />,
  },
  {
    path: "/provider/appointment",
    name: "Profile",
    icon: <ImProfile />,
  },
  {
    path: "/provider/appointment",
    name: "Settings",
    icon: <IoMdSettings />,
  },
];
