import React from "react";
import { Carousel } from "primereact/carousel";
import { Button } from "primereact/button";
import HomeContent from "./HomeContent";
import { Link } from "react-router-dom";
import { getBussinessByIdAction,likeunlikeProtofiloAction,getBussinessAction } from "../../redux/clientAction/allCategoriesAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
export default function Recommended() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { bussiness } = HomeContent();
  let token = localStorage.getItem("token")
  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "991px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const handleSubmit = (id) => {
    dispatch(getBussinessByIdAction(id), navigate(`/single-page/${id}`))
  }
  const handleLikeUnLike = (id)=>{
    if(token){
      dispatch(likeunlikeProtofiloAction(id)).then(()=>{
        dispatch(getBussinessAction())
      })
    }
 
   
  }
  const ServiceTemplate = (bussiness) => {
    return (
      <div className="border-1 surface-border curve overflow-hidden m-2 bg-white p-2 h-100">
        <span  className="text-dark text-decoration-none">
          <div className="mb-4 header-img" onClick={() => handleSubmit(bussiness._id)}>
            <img
              src={`https://instajamaica-api.appdeft.biz/${bussiness.image?.workPlaceURL}`}
              alt=""
              className="w-100 cover-img shadow-2"
            />
          </div>
          <div>
            <h5 className="mb-1 fw-semibold">
              {bussiness.businessName}
            </h5>
            <p className="mt-0 mb-3 text-md">{bussiness.address?.line1},{bussiness.address?.city},{bussiness.address?.zipCode}</p>

            <div className="mt-5 d-flex flex-wrap justify-content-between gap-1">
              <p>
                {bussiness?.rating} <i className="pi pi-star-fill text-golden"></i>{" "}
                <small>/{bussiness?.reviewCount} reviews</small>
              </p>
              <Button
              size = "large"
              onClick={()=>handleLikeUnLike(bussiness._id)}
                icon={bussiness.isLiked === 1 ? "pi pi-heart-fill"  : "pi pi-heart"}
                className={bussiness.isLiked === 1 ? `bg-transparent border-0 text-dark liked`:`bg-transparent border-0 text-dark unliked`}
              />
            </div>
          </div>
        </span>
      </div>
    );
  };
  return (
    <div className="">
      {!token ?"":
      <div className="container position-relative py-5">
        <div className="d-flex justify-content-between">
          <h2 className="fw-semibold">Recommended Services</h2>
        </div>
        <Carousel
          value={bussiness?.businesses?.length > 0 ? bussiness?.businesses : []}
          showNavigators="false"
          numScroll={1}
          numVisible={4}
          className="service-carousel position-relative"
          responsiveOptions={responsiveOptions}
          itemTemplate={ServiceTemplate}
        />
      </div>}
    </div>
  );
}
