import React from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function TableList({
  tableHeading,
  tableData,
  onDelete,
  onEdit,
  onView,
  selectedRow,
  className,
  setSelectedRow,
  filters,
  globalFilterFields,
  header,
}) {
  // dynamic
  const dynamicColumns = tableHeading
    ? tableHeading.map((col, i) => {
        return (
          <Column
            key={i}
            selectionMode={col.selectionMode}
            field={col.field}
            header={col.header}
            body={eval(col.body)}
            className="relative header-content"
            style={{ fontSize: "14px" }}
            sortable={col.sortable}
          />
        );
      })
    : null;
  return (
    <div>
      {tableData ? (
        <DataTable
          paginator
          stripedRows
          responsiveLayout="scroll"
          paginatorTemplate=" FirstPageLink  PageLinks  LastPageLink RowsPerPageDropdown "
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={10}
          selection={selectedRow}
          onSelectionChange={(e) => setSelectedRow(e.value)}
          rowsPerPageOptions={[10, 20, 50]}
          value={tableData ? tableData : null}
          className={`text-center shadow-1 dynamicdata ${className}`}
          style={{ borderRadius: "10px" }}
          selectionMode="checkbox"
          filters={filters}
          globalFilterFields={globalFilterFields}
        >
          {dynamicColumns}
        </DataTable>
      ) : (
        <div className="d-4 p-3 bg-primary">Data not avialable</div>
      )}
    </div>
  );
}
