import React from "react";
import { NavLink } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import { MenuItems } from "./MenuItems";

export default function Sidebar() {
  return (
    <div className="main-sidebar text-center bg-main h-100">
      <div className="sidebar d-flex flex-column justify-content-center">
        <div className="mt-5 mb-4">
          <Avatar
            image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
            className="mr-2"
            size="large"
            shape="circle"
          />
        </div>
      </div>
      {MenuItems.map((items, index) => {
        return (
          <div className="mt-4 pt-1">
            <NavLink to={items.path} key={index} className="link text-decoration-none">
              <div className="icon-sidebar text-white">{items.icon}</div>
              <div className="link-text fw-normal text-xs text-white">{items.name}</div>
            </NavLink>
          </div>
        );
      })}
    </div>
  );
}
