export default class Constants {
    static BASE_URL = "https://instajamaica-api.appdeft.biz/api/provider";
    
    static END_POINT = {
        LOGIN: "/login",
        SIGNUP: "/signup",
        REQUESTOTP: "/request-otp",
        CREATEBUSINESS: "/create-business",
        CATEGORIES: "/categories",
        GETBUSINESSHOUR: "/business",
        CREATEBUSINESSHOURS: "/create-business-timings",
        WORKPLACE: "/workplace",
        SERVICES:"/services",
        CREATESERVICES:"/create-service",
        DELETESERVICES:"/service/",
        FORGETPASSWORD:"/forget-password",
        CHANGE_FORGET_PASSWORD:"/change-forget-password",
        APPOINTMENT :"/bookings",
       
    };
}