import React from "react";
import { PhoneInput } from "react-contact-number-input";
import MainButton from "../../components/buttons/MainButton";
import AppointmentImg from "../../assets/images/booking.png";
import BussinessImg from "../../assets/images/bussinessimg.png";
import AppstoreImg from "../../assets/images/appstore (1).png"
import GoogleImg from "../../assets/images/google.png"
import { Button } from "react-bootstrap";
export default function BookAppointment() {
  return (
    <div>
      <div className="container position-relative py-4 ">
        <div className="row">
          <div className="col-md-6">
            <div className="bg-green-light p-4 pb-0 text-center top-radius d-flex flex-column justify-content-between h-100">
              <h2 className="fw-bold">Find & book an appointment</h2>
              <p className="text-gray mb-5">
                Cut the phone tag. Find your next appointment and{" "}
                <span className="fw-bold">book instantly </span>
                anytime, anywhere.
              </p>
              <div className="row justify-content-center ">
                <div className="col-md-7 d-flex ">
                  <button className="border-0 bg-transparent"><img src={AppstoreImg} alt="" height={40} width={126} /></button>
                  <span className="ms-2"> <button className="border-0 bg-transparent"><img src={GoogleImg} alt="" height={40} width={126} /></button></span>
                </div>
              </div>
              <img src={AppointmentImg} alt="" width="100%" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="bg-yellow p-4 pb-0 text-center top-radius d-flex flex-column justify-content-between h-100">
              <h2 className="fw-bold">InstaJamaica for your business</h2>
              <p className="text-gray">
                Get started with Booksy to run your business, better. Calendar,
                Booking, Marketing, and Payments all in one.
              </p>
              {/* <div className="d-flex justify-content-center">
                <MainButton label="Grow My Business" className="bg-light-black" />
              </div> */}
              <img src={BussinessImg} alt="" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
