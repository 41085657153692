import React, { useState } from "react";
import MainButton from "../../../../components/buttons/MainButton";
import { Col, Row } from "react-bootstrap";
import HomeIcon from "../../../../assets/icons/home.png";
import InputNumberComponent from "../../../../components/input/InputNumberComponent";
import InputTextComponent from "../../../../components/input/InputTextComponent";
import { useSelector } from "react-redux";
import { Dialog } from 'primereact/dialog'
import { addressAction, getAddressAction,deleteAddressAction } from "../../../../redux/clientAction/addressAction";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getBussinessAction } from "../../../../redux/clientAction/allCategoriesAction";
export default function SavedAddress() {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
    country: ""
  })
  const [showFilter, setShowFilter] = useState(false)
  const [filterIndex,setFilterIndex]= useState(null)
  const [error, setError] = useState({})
  const getAddress = useSelector((state) => state.clientgetAddress.getAddressProfile)
  const handleSubmit = () => {
    if (data.line1 === "" || data.city === "" || data.zipCode === "") {
      setError({
        ...error,
        line1: data.line1 === "" ? "Please Enter Address" : error.line1,
        city: data.city === "" ? "Please Enter city" : error.city,
        zipCode: data.zipCode === "" ? "Please Enter ZipCode" : error.zipCode
      })
      return false;
    }
    dispatch(addressAction(data), setVisible(false)).then(() => {
      dispatch(getAddressAction()).then(() => {
        setData({
          line1: "",
          line2: "",
          city: "",
          zipCode: ""
        })
      })
    })
  }
  useEffect(() => {
    dispatch(getAddressAction())
  }, [])
  const handleFilter = (id,i) => {
    setFilterIndex(i)
    setShowFilter(true)
  }
  const handleDelete = (id)=>{
      dispatch(deleteAddressAction(id,()=>setShowFilter(false))).then(()=>{
        dispatch(getAddressAction())
      })
  }
  return (
    <div className="px-4">
      <div className="d-flex align-items-center justify-content-between bg-main p-3 text-white curve mb-3">
        <p className="text-lg m-0 ">Saved Addresses</p>
        <MainButton
          icon="pi pi-plus-circle"
          iconPos="left"
          className="bg-white text-black"
          label="Add Address"
          onClick={() => setVisible(true)}
        />
      </div>
      {getAddress?.length > 0 && getAddress?.map((item, index) => {
        return (
          <div className="shadow rounded mb-3 p-3">
            <Row className="justify-content-between">


              <Col xs={11}>
                <div className="d-flex align-items-center">
                  <img src={HomeIcon} alt="" width={15} />
                  <p className="m-0 ms-2">{item.type}</p>
                </div>
                <p className="text-gray m-0">
                  {item.line1} {item.line2},{item.city}
                </p>
              </Col>
              <Col xs={1}>
                <MainButton
                  icon="pi pi-ellipsis-v"
                  className="bg-white text-gray"
                  onClick={() => handleFilter(item._id,index)}
                />
              </Col>
              {showFilter && filterIndex===index ?
                <div className="edit-Address d-flex justify-content-end">
                  <div className="shadow text-center rounded-3" style={{ width: "15%", marginRight: "50px", marginTop: "-32px" }}>
                    <p className="mt-3" onClick={()=>handleDelete(item._id)}>Delete</p>
                  </div>
                </div>
                : null
              }

            </Row>

          </div>
        )
      })}
      <div>

        <Dialog visible={visible} modal={false} style={{ width: '50vw' }} onHide={() => setVisible(false)}>

          <p className="text-lg m-0 ">Add Address</p>
          <InputTextComponent placeholder="Street & Number" className="mb-3 w-100 bg-white rounded shadow-sm" value={data.line1} extraClassName="w-100" onChange={(e) => {
            setError({
              ...error,
              line1: null
            });
            setData({
              ...data,
              line1: e.target.value
            })
          }} />
          {error.line1 && <span className="error">{error.line1}</span>}
          <InputTextComponent placeholder="Appartment o Suite Number(Optional)" className="mb-3 bg-white rounded shadow-sm" value={data.line2} onChange={(e) => {
            setData({
              ...data,
              line2: e.target.value
            })
          }} />
          <InputTextComponent placeholder="City" className="mb-3 bg-white rounded shadow-sm" value={data.city} onChange={(e) => {
            setError({
              ...error,
              city: null
            });
            setData({
              ...data,
              city: e.target.value
            })
          }} />
          {error.city && <span className="error">{error.city}</span>}
          <InputNumberComponent placeholder="Zip code" useGrouping={false} className="mb-3 w-100 bg-white rounded shadow-sm" value={data.zipCode} onChange={(e) => {
            setError({
              ...error,
              zipCode: null
            });
            setData({
              ...data,
              zipCode: e.value
            })
          }} />
          {error.zipCode && <span className="error">{error.zipCode}</span>}
          <MainButton label="Save" onClick={() => handleSubmit()} />
        </Dialog>

      </div>
    </div>
  );
}
