import axios from "axios";
import Constants from "./Constants";
import { isAuthenticated,providerIsAuthenticated } from "./authService";
const successStatus = [200, 201, 204];
export const include = (arr, con) => arr && arr.includes(con)
export const api = (method, urlEndPoint, data = null) =>

   new Promise((myResolve) => {
        let headers = {
            "Content-Type": "application/json",
        };
        if (providerIsAuthenticated()) {
            headers = {
                ...headers,
                Authorization: `Bearer ${providerIsAuthenticated()}`,
            };
        }
        axios({
            method,
            url: Constants.BASE_URL + urlEndPoint,
            data,
            headers,
        })
            .then((response) => {
                myResolve({
                    message: response.data.message,
                    data: response.data.data,
                    success: response.data.success,
                    statusCode: include(successStatus, response.status),
                });
            })
            .catch((err) => {

                if (err?.response?.data?.message) {
                    myResolve({
                        message: err?.response?.data?.message,
                        statusCode: false,
                    });
                }
                myResolve({
                    message: err?.response?.data,
                    statusCode: false,
                });
            });
    });

export const formaDataapi = (method, urlEndPoint, data = null) =>
    new Promise((myResolve) => {
        let headers = {
            "Content-Type": "multipart/form-data",
        };
        const formData = new FormData()
        for (let i = 0; i < data.length; i++) {
            formData.append("files", data[i])
        }
        if (providerIsAuthenticated()) {
            headers = {
                ...headers,
                Authorization: `Bearer ${providerIsAuthenticated()}`,
            };
        }
        axios({
            method,
            url: Constants.BASE_URL + urlEndPoint,
            data: formData,
            headers,
        })  
            .then((response) => {
                myResolve({
                    message: response.data.message,
                    data: response.data.data,
                    success: response.data.success,
                    statusCode: include(successStatus, response.status),
                });
            })
            .catch((err) => {
                if (err?.response?.data?.message) {
                    myResolve({
                        message: err?.response?.data?.message,
                        statusCode: false,
                    });
                }
                myResolve({
                    message: err?.response?.data,
                    statusCode: false,
                });
            });
    });

    export const deleteapi = (method, urlEndPoint, data = null) =>

   new Promise((myResolve) => {
        let headers = {
            "Content-Type": "application/json",
        };
        if (providerIsAuthenticated()) {
            headers = {
                ...headers,
                Authorization: `Bearer ${providerIsAuthenticated()}`,
            };
        }
        axios({
            method,
            url: Constants.BASE_URL + urlEndPoint,
            headers,
        })
            .then((response) => {
                myResolve({
                    message: response.data.message,
                    data: response.data.data,
                    success: response.data.success,
                    statusCode: include(successStatus, response.status),
                });
            })
            .catch((err) => {
                if (err?.response?.data?.message) {
                    myResolve({
                        message: err?.response?.data?.message,
                        statusCode: false,
                    });
                }
                myResolve({
                    message: err?.response?.data,
                    statusCode: false,
                });
            });
    });