import ClientConstants from "../../services/ClientConstants";
import { clientApi, clientBussiness ,deleteapi} from "../../services/clientApi";
import { authenticate } from "../../services/authService";
import { types } from "../types/types";
import { showToast } from "../action/toastAction";
import { showLoaderAction, hideLoaderAction } from "../action/loaderAction";


//add-address

export const addressAction = (data) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await clientApi("post", ClientConstants.END_POINT.ADDRESS, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CLIENT_ADD_ADDRESS,
                payload: res.data,
            });
        }
        dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(hideLoaderAction())
    }
    else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch(hideLoaderAction())
    }
}


export const getAddressAction = () => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await clientApi("get", ClientConstants.END_POINT.GETADDRESS);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CLIENT_GET_ADDRESS,
                payload: res.data,
            });
        }
        // dispatch(showToast({severity:"success",summary:res.message}));
        dispatch(hideLoaderAction())
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch(hideLoaderAction());
    }
}

export const putAddressAction = (id, data) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await clientApi("put", `${ClientConstants.END_POINT.PUTADDRESS}/${id}`, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CLIENT_PUT_ADDRESS,
                payload: res.data,
            });
        }
        dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(hideLoaderAction())
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch(hideLoaderAction());
    }
}

export const deleteAddressAction = (id,next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await deleteapi("delete", `${ClientConstants.END_POINT.DELETEADDRESS}/${id}`)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.DELETE_ADDRESS,
                payload: res.data
            })
        }
        dispatch(showToast({ severity: "success", summary: res.message }))
        next()
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}