import { types } from "../types/types";


const intitalState = {
    categoryProfile: {},
    getBussinessProfile: [],
    getBussinessByIdProfile: [],
    getBusinessReviewsByIdProfile: [],
    getSlotsProfile: [],
    bookSlotsProfile: [],
    addressProfile: {},
    recentSearchProfile: {},
    recentSearchBussinessProfile: [],
    deleteSearchProfile: {},
    likeUnlikeProfile: [],
    reportsProfile: {},
}

export const clientAllCategoryReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CLIENT_ALL_CATEGORIES:
            return {
                ...state,
                categoryProfile: action.payload
            };
        default:
            return { ...state };
    }
}

export const clientGetBussiness = (state = intitalState, action) => {
    switch (action.type) {
        case types.CLIENT_GET_BUSSINESS:
            return {
                ...state,
                getBussinessProfile: action.payload
            };
        default:
            return { ...state }
    }
}

export const clientGetBussinessById = (state = intitalState, action) => {
    switch (action.type) {
        case types.CLIENT_GET_BUSSINESS_BY_ID:
            return {
                ...state,
                getBussinessByIdProfile: action.payload
            };
        default:
            return { ...state }
    }
}

export const clientGetBussinessReviews = (state = intitalState, action) => {
    switch (action.type) {
        case types.CLIENT_BUSINESS_REVIEWS_BY_ID:
            return {
                ...state,
                getBusinessReviewsByIdProfile: action.payload
            };
        default:
            return { ...state }
    }
}
export const getSlotsReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CLIENT_GET_SLOTS:
            return {
                ...state,
                getSlotsProfile: action.payload
            };
        default:
            return { ...state }
    }
}

export const bookSlotsReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CLIENT_BOOK_SLOTS:
            return {
                ...state,
                bookSlotsProfile: action.payload
            };
        default:
            return { ...state }
    }
}

export const recentSearchReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.RECENT_SEARCH:
            return {
                ...state,
                recentSearchProfile: action.payload
            };
        default:
            return { ...state }
    }
}

export const recentSearchBussinessReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.RECENT_SEARCH_BUSSINESS:
            return {
                ...state,
                recentSearchBussinessProfile: action.payload
            };
        default:
            return { ...state }
    }
}

export const deleteSearchReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.DELETE_SEARCH:
            return {
                ...state,
                deleteSearchProfile: action.payload
            };
        default:
            return { ...state }
    }
}

export const likeUnlikeReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.LIKE_UNLIKE:
            return {
                ...state,
                likeUnlikeProfile: action.payload
            };
        default:
            return { ...state }
    }
}

export const addReportReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ADD_BUSSINESS_REPORT:
            return {
                ...state,
                reportsProfile: action.payload
            };
        default:
            return { ...state }
    }
}