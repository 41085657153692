import React, { useEffect, useState } from "react";
import MainButton from "../../components/buttons/MainButton";
import LoactionPhoneImg from "../../assets/vector/locationphone.png";
import Cookies from 'js-cookie';
export default function LocationService() {
  const [value, setValue] = useState(true)
  const handleSubmit = () => {
    Cookies.set('name', "instaJamica", { expires: 7 });
    setValue(false)
  }
  return (
    <div>
      <div className="container position-relative py-4">
        <div className="row">
          <div className="col-md-5 text-center">
            <img src={LoactionPhoneImg} alt="" className="locator mx-auto" />
          </div>
          <div className="col-md-6">
            <div className="d-flex h-100 flex-column justify-content-center">
              <h4 className="fw-bold">Turn on location services</h4>
              <p className="text-gray">
                Get recommendations of great businesses! Turn on location
                services so we can show you what's nearby.
              </p>
              <div className="d-flex">
                <MainButton label="Search near me" />
                <MainButton
                  label="Not now"
                  className="ms-2 border-green bg-white text-main"
                />
              </div>
            </div>
          </div>
          {Cookies.get("name") === undefined ?
          <div className="bg-dark-gray-light p-4 radius-medium text-center svg-intersect position-fixed w-50  " style={{
            top: "50%", left: "50%", zIndex: "2", transform:
              "translate(-50%,-50%)"
          }}>
            <h5 className="fw-semibold">
              This website uses cookies to give you an awesome, catered user
              experience.
            </h5>
            <p className="text-sm">
              Continuing on after seeing this message means that you're cool
              with that.
            </p>
            <div className="d-flex justify-content-center">
              <MainButton label="Ok, continue" onClick={() => handleSubmit()} />
            </div>
          </div>:""}
        </div>
      </div>
    </div>
  );
}
