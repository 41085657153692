import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
const ProviderPrivate = () => {
    let providerToken = localStorage.getItem('providerToken');
    let auth;

    if (providerToken == null || providerToken == '') {
        auth = false;
    }
    else if (providerToken !== null) {
        let decode = jwt_decode(providerToken);
        if (!decode) {
            auth = false;
        }
        else if (decode.userType == 'admin') {
            auth = false;
        }
        else if (decode.userId !== null || decode.providerId !== null) {
            auth = true;
        }
        else {
            auth = false;
        }
    }
    else if (providerToken == null || providerToken == '') {
        auth = false;
    }
    else {
        auth = false
    }
    return auth ? <Outlet /> : <Navigate to="/" replace={true} />;
}
export default ProviderPrivate;