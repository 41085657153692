import ClientConstants from "../../services/ClientConstants";
import { clientApi, formaDataapi } from "../../services/clientApi";
import { authenticate } from "../../services/authService";
import { types } from "../types/types";
import { showToast } from "../action/toastAction";
import { hideLoaderAction,showLoaderAction } from "../action/loaderAction";

export const clientSignupAction = (data,next)=>async(dispatch)=>{
    dispatch(showLoaderAction());
    const res =  await clientApi("post",ClientConstants.END_POINT.CLIENTSIGNUP,data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CLIENT_SIGNUP_DETAIL,
                payload: res.data,
            });
            
            authenticate(res.data.token, () => {
                if (res.success) {
                    dispatch(showToast({ severity: "success", summary: res.message }));   
                } 
            });
            dispatch(hideLoaderAction())
            next()
        }        
    }else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch(hideLoaderAction())
    }
}

export const requestotpAction = (data,next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await clientApi("post", ClientConstants.END_POINT.REQUESTOTP, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CLIENT_REQUEST_OTP,
                payload: res.data,
            });
        }
        dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(hideLoaderAction())
        next()
    }

    else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch(hideLoaderAction())
    }
}

//get-user-profile

export const getUserProfileAction = ()=>async(dispatch)=>{
    dispatch(showLoaderAction());
    const res = await clientApi("get",ClientConstants.END_POINT.GETUSERPROFILE);
    if(res.success){
        if(res.data){
            dispatch({
                type:types.CLIENT_USER_PROFILE,
                payload:res.data,
            });
        }
        // dispatch(showToast({severity:"success",summary:res.message}));
        dispatch(hideLoaderAction())
    }else{
        dispatch(showToast({severity:"error",summary:res.message}));
        dispatch(hideLoaderAction());
    }
}

//update-user-profile

export const updateUserProfile=(data)=>async(dispatch)=>{
    console.log(data,"====data====")
    dispatch(showLoaderAction());
    const res =  await formaDataapi("put",ClientConstants.END_POINT.UPDATEUSERPROFILE,data);
    if(res.success){
        if(res.data){
            dispatch({
                type:types.CLIENT_UPDATE_USER_PROFILE,
                payload:res.data,
            });
        }
        dispatch(showToast({severity:"success",summary:res.message}));
        dispatch(hideLoaderAction())
    }else{
        dispatch(showToast({severity:"error",summary:res.message}))
        dispatch(hideLoaderAction())
    }
}