import React, { useState } from "react";
import InputTextComponent from "../../../components/input/InputTextComponent";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import MainButton from "../../../components/buttons/MainButton";
import CustomModal from "../../../components/Modal/CustomModal";
import Otp from "../otp/Otp";
import { useDispatch } from "react-redux";
import { clientForgetPasswordAction } from "../../../redux/clientAction/forgetPasswordAction";
import Login from "../Login/Login";
export default function ForgetPassword({ forgetVisible, setForgetVisible }) {
  const [sendVisible, setSendOtpVisible] = useState(false);
  const [form, setForm] = useState({
    email: "",
    otp: "",
    password: "",
    confirmPassword: ""
  })
  const [errors, setErrors] = useState({})
  const [loginVisible, setLoginVisible] = useState(false)
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (form.email === "") {
      setErrors({
        ...errors,
        email: form.email === "" ? "Please Enter Email" : errors.email
      })
      return false;
    }
    dispatch(clientForgetPasswordAction(form, () => setSendOtpVisible(true)))
  }
  return (
    <div>
      <Card className=" border-0 w-100">
        <div className="p-4 login">
          <h4 className="heading text-center fw-bold">Forgot Password</h4>
          <div className="mt-3">
            <span className="p-input-icon-left w-100">
              <i className="pi pi-envelope" />
              <InputTextComponent id="email" type="email" placeholder="Email" value={form.email} onChange=
                {(e) => {
                  setErrors({
                    ...errors,
                    email: null
                  })
                  setForm({
                    ...form,
                    email: e.target.value
                  })
                }
                } />
              {errors.email && <span className="error">{errors.email}</span>}
            </span>
          </div>
          <div className="my-3 d-block ">
            <MainButton label="Send OTP" className="border-0 fw-semibold" onClick={handleSubmit} />
          </div>
          <div className="d-flex justify-content-center my-3">
            <p className="sub-heading-2">Remember Password?</p>&nbsp;
            <span className="sign-text h6 link text-main text-decoration-none cursor-pointer" onClick={() => setLoginVisible(true)}>Login</span>
          </div>
        </div>
      </Card>

      <CustomModal visible={loginVisible} onHide={() => setLoginVisible(false)}>
        <Login />
      </CustomModal>
      <CustomModal visible={sendVisible} onHide={() => setSendOtpVisible(false)}>
        <Otp form={form} setForm={setForm} handleSubmit={handleSubmit} errors={errors} setErrors={setErrors} setForgetVisible ={setForgetVisible} setSendOtpVisible = {setSendOtpVisible} setLoginVisible = {setLoginVisible}/>
      </CustomModal>


    </div>
  );
}
