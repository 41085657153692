import React,{useState} from "react";
import { Checkbox } from "primereact/checkbox";

export default function CheckboxComponent({
  size,
  labelClassName,
  label,
  required,
  checkBoxContainer,
  name,
  checked,
  setChecked
}) {
  // const [checked, setChecked] = useState(false);
  return (
    <div className={size}>
      <div className={`flex align-items-center ${checkBoxContainer}`}>
        <Checkbox inputId="ingredient1" name="pizza" value="Cheese" onChange={e => setChecked(e.checked)} checked={checked} />
        {label ? (
          <label
            htmlFor={name}
            className={`text-sm text-gray form-label m-0 ms-2 ${labelClassName}`}
          >
            {label} {required && <span className="p-error">*</span>}
          </label>
        ) : null}
      </div>
    </div>
  );
}
