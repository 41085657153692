import React, { useRef } from "react";
import Logo from "../assets/icons/Logo.png";
import Notification from "../assets/icons/notification.png";
import { Avatar } from "primereact/avatar";
import ProfileImg from "../assets/images/profileimg.png";
import ChevronIcon from "../assets/icons/chevron.png";
import { OverlayPanel } from "primereact/overlaypanel";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from 'primereact/button';
export default function Topbar() {
  const op = useRef(null);
  const location = useLocation()
  const navigate = useNavigate()
  const name = localStorage.getItem("name")
  const email =  localStorage.getItem("email")
  const image = localStorage.getItem("image")
  const handleLogout =()=>{
    localStorage.removeItem("providerToken")
    navigate("/login")
  }
  return (
    <div>
      {location?.pathname?.includes("/login") ? "" : ""}
      <nav className="navbars">
        <div className="d-flex justify-content-between align-items-center p-4">

          <div className="mx-md-5 mx-1">
            <img src={Logo} alt="logo" className="img-fluid"/>
          </div>
          {location?.pathname?.includes("/login") || location?.pathname?.includes("/provider/forget-password") || location?.pathname?.includes("/provider/forget-password") ? "" : location?.pathname?.includes("/provider/signup") || location?.pathname?.includes("/provider/bussiness") || location?.pathname?.includes("/new-password") || location?.pathname?.includes("/provider/profile-setup") || location?.pathname?.includes("/provider/add-workplace") || location?.pathname?.includes("/provider/add-service") || location?.pathname?.includes("/provider/setup-done")? 
          <div className="card flex justify-content-center">
            <Button label="Login" onClick={() => navigate("/login")} />
          </div> :
            <div className="d-flex align-items-center">
              <div
                className="cursor-pointer"
                onClick={(e) => op.current.toggle(e)}
              >
                <img src={Notification} width={20} alt="" />
              </div>
              <OverlayPanel className="p-0" ref={op} style={{ width: "20vw" }}>
                <div className="d-flex align-items-center justify-content-between p-3">
                  <p className="text-main fw-semibold m-0">Notifcations</p>
                  <Link to="" className="text-main text-xs">Read All</Link>
                </div>
                <ul className="list-unstyled notification-list">
                  <li className="p-3">
                    <p className="fw-semibold text-sm m-0">Your ad just went online!</p>
                    <small className="text-gray">3 days ago</small>
                  </li>
                  <li className="p-3">
                    <p className="fw-semibold text-sm m-0">Your ad just went online!</p>
                    <small className="text-gray">3 days ago</small>
                  </li>
                  <li className="p-3">
                    <p className="fw-semibold text-sm m-0">Your ad just went online!</p>
                    <small className="text-gray">3 days ago</small>
                  </li>
                </ul>
              </OverlayPanel>
              <div className="d-flex align-items-center ms-3">
                <Avatar image={`https://instajamaica-api.appdeft.biz/api/${image}`} size="xlarge" shape="circle" />
                <div className="ms-2">
                  <p className="fw-semibold m-0">{name}</p>
                  <small>{email}</small>
                </div>
                <img src={ChevronIcon} className="ms-2" alt="" onClick={()=>handleLogout()} />
              </div>
            </div>
          }
        </div>
      </nav>
    </div>
  );
}
