import React from "react";
import Rectangle from "../../../src/assets/images/rectangle1.png"
import Rectangle2 from "../../../src/assets/images/Rectangle2.png"
import Rectangle3 from "../../../src/assets/images/Rectangle3.png"
const BookingDetails = () => {
    return (
        <div className="container mt-5 py-4">
            <div className="row d-flex ">
                <div className="col-md-6  booking-detail">
                    <h1 className="fw-bold mb-3" style={{ fontSize: "34px", color: "#242424" }}>Appointments done better</h1>
                    <p className="" style={{ color: "#80808C" }}>
                        Looking for your next appointment with a local barber, hair <br />
                        stylist, massage therapist or nail artist? Need appointment <br />
                        booking for a beard trim, an eyebrow wax, or a deep tissue <br />
                        massage?<br /><br />
                        Booksy is a free beauty scheduling app and makes <br />
                        appointments easy to find and book within seconds. No<br />
                        more phone tag. Book anytime, from anywhere, 24/7.<br />
                    </p>
                    <p className="fw-semibold" style={{ color: "#80808C" }}>Discover top businesses in your area and book instantly <br />
                        with Booksy.</p>
                </div>
                <div className="col-md-6  booking-detail">
                    <img src={Rectangle} alt="image" className="w-100" />
                </div>
                <div className="col-md-6 booking-detail">
                    <img src={Rectangle2} alt="image" className="w-100" />
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                    <div className="">
                        <h1 className="fw-bold mb-3 py-4" style={{ fontSize: "34px", color: "#242424" }}>Something come up? <br />
                            We’ve got you.</h1>
                        <p className="" style={{ color: "#80808C", fontSize: "18px" }}>
                            Download Booksy, a free online appointment booking<br />
                            app, and manage your appointments from anywhere.<br />
                            Reschedule or cancel without picking up the phone.<br /><br />

                            And because we know life gets busy, we’ll send you<br />
                            reminders. You’ll never forget or miss out on another<br />
                            appointment.
                        </p>
                    </div>

                </div>
                <div className="col-md-6 booking-detail">
                    <h1 className="fw-bold mb-3" style={{ fontSize: "34px", color: "#242424" }}>Book with the best, near you</h1>
                    <p className="" style={{ color: "#80808C", fontSize: "18px" }}>Take a scroll around the block to see top health and<br />
                        beauty businesses on Booksy’s marketplace.<br /><br />
                        Check out their vibe from their business profile and hear<br />
                        what other people are saying with verified reviews. You<br />
                        can even look through their portfolio of work.<br /><br />
                        Save time and leave the stress to someone else. With<br />
                        Booksy, setting up your next beauty appointment is free<br />
                        and easy.</p>
                </div>
                <div className="col-md-6 booking-detail">
                    <img src={Rectangle3} alt="image" className="w-100" />
                </div>
            </div>
        </div>
    )
}

export default BookingDetails;