import { api } from "../../services/api";
import Constants from "../../services/Constants";
import { types } from "../types/types";
import { showToast } from "./toastAction";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
export const forgetPasswordAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.FORGETPASSWORD, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.FORGET_PASSWORD,
                payload: res.data,
            });
        }
        dispatch(showToast({ severity: "success", summary: res.message }));
        next();
        dispatch(hideLoaderAction())
    }

    else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch(hideLoaderAction())
    }
}
export const changeForgetPassword = (data, next = null) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.CHANGE_FORGET_PASSWORD, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CHANGE_FORGET_PASSWORD,
                payload: res.data,
            });
        }
        dispatch(showToast({ severity: "success", summary: res.message }));
        if (next !== null) next();
        dispatch(hideLoaderAction())
    }

    else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch(hideLoaderAction())
    }
}