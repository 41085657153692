export const types = {
    USER_DETAIL: "USER_DETAIL",
    SINGUP_DETAIL:"SIGNUP_DETAIL",
    CHANGE_SINGUP_DATA : "CHANGE_SINGUP_DATA",
    REQUEST_OTP:"REQUEST_OTP",
    SHOW_TOAST: "SHOW_TOAST",
    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",
    CREATE_BUSINESS : "CREATE_BUSINESS",
    GET_CATEGORIES:"GET_CATEGORIES",
    GET_BUSSINESS_HOURS:"GET_BUSSINESS_HOURS",
    CREATE_BUSINESS_HOURS:"CREATE_BUSINESS_HOURS",
    WORKPLACE:"WORKPLACE",
    GET_SERVICES:"GET_SERVICES",
    CREATE_SERVICE:"CREATE_SERVICE",
    DELETE_SERVICES:"DELETE_SERVICES",
    FORGET_PASSWORD:"FORGET_PASSWORD",
    CHANGE_FORGET_PASSWORD:"CHANGE_FORGET_PASSWORD",
    APPOINTMENT:"APPOINTMENT",
    CLIENT_SIGNUP_DETAIL:"CLIENT_SIGNUP_DETAIL",
    CLIENT_REQUEST_OTP:"CLIENT_REQUEST_OTP",
    CLIENT_LOGIN_DETAIL:"CLIENT_LOGIN_DETAIL",
    CLIENT_FORGET_PASSWORD:"CLIENT_FORGET_PASSWORD",
    CLIENT_CHANGE_FORGET_PASSWORD:"CLIENT_CHANGE_FORGET_PASSWORD",
    CLIENT_ALL_CATEGORIES:"CLIENT_ALL_CATEGORIES",
    CLIENT_GET_BUSSINESS:"CLIENT_GET_BUSSINESS",
    CLIENT_GET_BUSSINESS_BY_ID:"CLIENT_GET_BUSSINESS_BY_ID",
    CLIENT_BUSINESS_REVIEWS_BY_ID:"CLIENT_BUSINESS_REVIEWS_BY_ID",
    CLIENT_GET_SLOTS:"CLIENT_GET_SLOTS",
    CLIENT_BOOK_SLOTS:"CLIENT_BOOK_SLOTS",
    CLIENT_ADD_ADDRESS:"CLIENT_ADD_ADDRESS",
    CLIENT_GET_ADDRESS:"CLIENT_GET_ADDRESS",
    CLIENT_PUT_ADDRESS:"CLIENT_PUT_ADDRESS",
    CLIENT_CARD_TOKEN:"CLIENT_CARD_TOKEN",
    CLIENT_USER_PROFILE:"CLIENT_USER_PROFILE",
    CLIENT_UPDATE_USER_PROFILE:"CLIENT_UPDATE_USER_PROFILE",
    RECENT_SEARCH:"RECENT_SEARCH",
    RECENT_SEARCH_BUSSINESS:"RECENT_SEARCH_BUSSINESS",
    DELETE_SEARCH:"DELETE_SEARCH",
    LIKE_UNLIKE:"LIKE_UNLIKE",
    GET_MY_BOOKING:"GET_MY_BOOKING",
    GET_BOOKING:"GET_BOOKING",
    CANCEL_BOOKING:"CANCEL_BOOKING",
    ADD_REVIEW:"ADD_REVIEW",
    TERM_SERVICE:"TERM_SERVICE",
    PRIVACY_POLICY:"PRIVACY_POLICY",
    ADD_BUSSINESS_REPORT:"ADD_BUSSINESS_REPORT",
    DELETE_ADDRESS:"DELETE_ADDRESS"
};
