// import api from "../../services/api";
import { api } from "../../services/api";
import Constants from "../../services/Constants";
import { types } from "../types/types";
import { showToast } from "./toastAction";
import { hideLoaderAction,showLoaderAction } from "./loaderAction";

export const createBusinessAction  = (data,next)=>async(dispatch)=>{
    dispatch(showLoaderAction());
    const res =  await api("post",Constants.END_POINT.CREATEBUSINESS,data);
    if(res.success){
        if(res.data){
            dispatch({
                type: types.CREATE_BUSINESS,
                payload: res.data,
            });
        }
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "success", summary: res.message }));
        next()
    }else{
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}

export const getCategories = ()=>async(dispatch)=>{
    dispatch(showLoaderAction());
    const res = await api("get",Constants.END_POINT.CATEGORIES);
    if (res.statusCode) {
        if (res.data) {
            dispatch({
                type: types.GET_CATEGORIES,
                payload: res.data,
            });
        }  
        dispatch(hideLoaderAction())         
    }
}