const ClientData = () => {

    const ClientName = [
        {
            name: "John Bride",
            path:""
        },
        {
            name: "Ish Sodhi",
            path:""
        },
        {
            name: "Harry Brook",
            path:""
        },
        {
            name: "Will Smith",
            path:""
        },
        {
            name: "Henry Nicholls",
            path:""
        },
        {
            name: "Jos Buttler",
            path:""
        },
    ]

    return{ClientName};

};
export default ClientData;