import React, { useState } from "react";
import Layout from "../../layout";
import ArrowRight from "../../assets/icons/arrow-right.png";
import MainButton from "../../components/buttons/MainButton";
import TableList from "../../components/table/table";
import TableData from "./ServiceData";
import { Dialog } from "primereact/dialog";
import InputTextComponent from "../../components/input/InputTextComponent";
import DropDownComponent from "../../components/input/DropDownComponent";
import InputNumberComponent from "../../components/input/InputNumberComponent";

export default function ServiceSetup() {
  const [visible, setVisible] = useState(false);
  const { WalletTableHeading, WalletDetail } = TableData();
  return (
    <div>
      <Layout>
        <div className="p-5">
          <div className="d-flex align-items-center mb-3">
            <img src={ArrowRight} width={25} alt="" />
            <h4 className="text-main fw-bold mb-0 ms-3">Services Setup</h4>
          </div>
          <div className="bg-white rounded p-5">
            <div className="d-flex justify-content-end mb-3">
              <MainButton
                label="+   Add New"
                onClick={() => setVisible(true)}
              />
            </div>
            <TableList
              className="light_color-bg curve overflow-hidden"
              tableHeading={WalletTableHeading}
              tableData={WalletDetail}
            />
          </div>
          <Dialog
            header={
              <div className="flex align-items-center">
                <span className="vertical-align-middle text-main fw-bold">
                  Add Service
                </span>
              </div>
            }
            visible={visible}
            style={{ width: "25vw" }}
            onHide={() => setVisible(false)}
            className="curve"
          >
            <div>
              <InputTextComponent
                label="Service Name"
                labelClassName="fw-bold text-black"
                extraClassName="mb-3"
              />
              <p className="fw-semibold text-black">Service duration</p>
              <div className="row mb-3">
                <DropDownComponent
                  label="Hours"
                  extraClassName="col-md-6"
                  inputClass="border-0"
                />
                <DropDownComponent
                  label="Minutes"
                  extraClassName="col-md-6"
                  inputClass="border-0"
                />
              </div>
              <div className="p-inputgroup w-100 bg-light-color rounded mb-3">
                <span className="p-inputgroup-addon border-0 bg-transparent border-end">
                  $
                </span>
                <InputNumberComponent
                  placeholder="Price"
                  extraClassName="w-100"
                />
              </div>
              <MainButton label="Add" />
            </div>
          </Dialog>
        </div>
      </Layout>
    </div>
  );
}
