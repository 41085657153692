import { combineReducers } from "redux";
import { signupReducer, ProviderLoginReducer } from "./loginReducers";
import toastReducer from "./toastReducer";
import loaderReducer from "./loaderReducer";
import { createBusinessReducer, getCategories } from "./createBusinessReducer";
import { getBusinessHoursReducer, createBusinessHoursReducer, createWorkplaceReducer, getServicesReducer, createServiceReducer, deleteServiceReducer } from "./businessHoursReducer";
import forgetPasswordReducer from "./forgetPasswordReducer";
import { appointmentReducer } from "./appointmentReducer";
import { clientSignupReducer, getUserProfileReducer, updateUserProfileReducer } from "./clientSignupReducer";
import clientForgetPasswordReducer from "./clientForgetPassword";
import { addReportReducer, bookSlotsReducer, clientAllCategoryReducer, clientGetBussiness, clientGetBussinessById, clientGetBussinessReviews, getSlotsReducer, likeUnlikeReducer, recentSearchBussinessReducer, recentSearchReducer } from "./clientAllCategoryReducer";
import { addressReducer, deleteAddressReducer, getAddressReducer, putAddressReducer } from "./clientAddressReducer";
import { paymentReducer } from "./paymentReducer";
import { cancelBookingReducer, getBookingReducer, getMyBookingReducer, privacyPolicyReducer, termServiceReducer } from "./clientProfileReducer";

export default combineReducers({
    toast: toastReducer,
    signup: signupReducer,
    login: ProviderLoginReducer,
    loader: loaderReducer,
    createbussiness: createBusinessReducer,
    getCategories: getCategories,
    getBusinessHours: getBusinessHoursReducer,
    createBusinessHours: createBusinessHoursReducer,
    createWorkplace: createWorkplaceReducer,
    getServices: getServicesReducer,
    createServices: createServiceReducer,
    deleteServices: deleteServiceReducer,
    forgetPassword: forgetPasswordReducer,
    appointment: appointmentReducer,
    clientSignUp: clientSignupReducer,
    clientForgetPassword: clientForgetPasswordReducer,
    allCatergory: clientAllCategoryReducer,
    clientgetBussiness: clientGetBussiness,
    clientgetBussinessById: clientGetBussinessById,
    clientgetBusinessReviewsById: clientGetBussinessReviews,
    clientgetSlots: getSlotsReducer,
    clientBookSlots: bookSlotsReducer,
    clientaddAddress: addressReducer,
    clientgetAddress: getAddressReducer,
    clientupdateAddress: putAddressReducer,
    paymentMethod: paymentReducer,
    getUserData: getUserProfileReducer,
    updateUserProfile: updateUserProfileReducer,
    recentSearch: recentSearchReducer,
    recentSearchBussiness: recentSearchBussinessReducer,
    deleteSearch: deleteServiceReducer,
    likeUnlike: likeUnlikeReducer,
    getMyBooking: getMyBookingReducer,
    getBooking: getBookingReducer,
    cancelBooking: cancelBookingReducer,
    termServices: termServiceReducer,
    privacyPolicy: privacyPolicyReducer,
    reportAdd: addReportReducer,
    deleteAddress:deleteAddressReducer
});