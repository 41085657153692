import React, { useEffect, useState } from "react";
import ArrowRight from "../../../assets/icons/arrow-right.png"
import { Link } from "react-router-dom";
import MainButton from "../../../components/buttons/MainButton";
import OtpInput from 'react-otp-input';
export default function OtpVerify({form,setForm,handleSubmit,setSelectedStep,setErrors,errors,handleSubmitOtp}) {
  const [counter, setCounter] = useState(60);
  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000)
    }
  }, [counter]);
  const resendOtp = () => {
    setCounter(60);
    handleSubmitOtp()
  }
  function handleChange(otp) {
    setErrors({
      ...errors,
      otp:null
    })
    setForm({
      ...form,
      otp: otp
    });
  }
  return (
    <>
    <img src = {ArrowRight} alt ="" onClick={()=>setSelectedStep("1")}/>
      <h3 className="heading text-center fw-bold">OTP Verification</h3>
      <p className="text-center text-gray">
        Code has been send to registered email: <br />{" "}
        <span>{form.email}</span>
      </p>
      <div className="row verify-input justify-content-center mb-3">
      <OtpInput
          value={form.otp}
          numInputs={6}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          onChange={(e) => {
            handleChange(e)
          }}/>
          {errors.otp && <span className="error">{errors.otp}</span>}
      </div>
      <p className="text-main text-center fw-semibold">
      {counter === 0 ? <span onClick={resendOtp}>Resend code </span> : <><span>Resend code </span><span className="text-dark">in</span> {counter}
          <span className="text-dark">s</span></>}
      </p>
      <Link to="">
        <MainButton label="Continue"  onClick={handleSubmit}/>
      </Link>
    </>
  );
}
