const WalletData = () => {
  const WalletTableHeading = [
    {
      field: "date",
      header: "Date",
    },
    {
      field: "clientname",
      header: "Client’s Name ",
    },
    {
      field: "amount",
      header: "Amount",
    },
  ];

  const WalletDetail = [
    {
      date: "26-August-2022",
      clientname: "John Bride",
      amount: "$ 45.00 USD",
    },
  ];

  return { WalletTableHeading, WalletDetail };
};
export default WalletData;
