import React from "react";
import { InputTextarea } from 'primereact/inputtextarea';

export default function InputTextAreaComponent({
    label,
    name,
    errorMessage,
    extraClassName,
    inputClass,
    labelClassName,
    required,
    ...props
  }) {
  return (
    <div>
      <div className={extraClassName}>
        {label ? (
          <label
            htmlFor={name}
            className={`text-sm text-gray form-label ${labelClassName}`}
          >
            {label} {required && <span className="p-error">*</span>}
          </label>
        ) : null}
        <InputTextarea
          id={name}
          name={name}
          className={`w-100 rounded border-none ${inputClass} `}
          rows={5}
          cols={30}
          {...props}
        />
      </div>
    </div>
  );
}
