import { types } from "../types/types";
const intitalState = {
    userProfile: {},
    loginProfile:[],
};

export const signupReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.SINGUP_DETAIL:
            return {
                ...state,
                userProfile: action.payload,
            };
        case types.CHANGE_SINGUP_DATA:
            return {
                ...state,
                userProfile: action.payload,
            };
        default:
            return { ...state };
    }
};
export const ProviderLoginReducer = (state = intitalState,action)=>{
    switch(action.type){
        case types.USER_DETAIL:
            return{
                ...state,
                loginProfile:action.payload,
            };
            default:
                return {...state}
    }
}
