import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "./Layout";
import { Container } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { allCategoriesAction, searchBusinessAction } from "../../redux/clientAction/allCategoriesAction";
import { useParams, useNavigate } from "react-router-dom";
import { ProgressSpinner } from 'primereact/progressspinner';
import Geocode from 'react-geocode';
const Services = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const search = useSelector((state) => state.recentSearchBussiness.recentSearchBussinessProfile)
  const categories = useSelector((state) => state.allCatergory.categoryProfile)
  const API_Key = 'AIzaSyAaVnz03Xmd1cZGKPcVcKFHg0rAX4o_BAs';
  const [address, setAddress] = useState({
    address: {
      formatted_address: '',
      latitude: '',
      longitude: '',
    },
  });
  const successPosition = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setAddress({
      ...address,
      address: { ...address, latitude, longitude },
    });
    Geocode.setApiKey(API_Key);
    Geocode.setLanguage('en');
    Geocode.setRegion('es');
    Geocode.enableDebug();
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        const formatted_address = response.results[6].formatted_address;
        setAddress({
          ...address,
          address: { ...address, formatted_address },
        });
      },
      (error) => {
      }
    );
  };
  const deniedPosition = (error) => {
    alert(
      "You denied to location permission,\nAllow the permission from browser's settings or add you address manually."
    );
  };
  const getCurrentLoaction = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (pos) => successPosition(pos),
        (err) => deniedPosition(err)
      );
    } else {
      alert("Your Browser doesn't support location service !");
    }
  };
  useEffect(() => {
    getCurrentLoaction();
  }, []);
  useEffect(() => {
    dispatch(allCategoriesAction())
  }, [])
  const GlobalValueChange = (e) => {
    if (e.target.value === "1") {
      let payload = {
        isPromoted: true,
        search: name.category
      }
      dispatch(searchBusinessAction(payload))
    }
  }
  const name = useParams()
  useEffect(() => {
    setIsLoading(true)
    if (name.category) {
      dispatch(searchBusinessAction({ search: name.category }))
    }
    setIsLoading(false)
  }, [name.category])
  const handleCateorySubmit = (name) => {
    dispatch(searchBusinessAction({ search: name }))
  }
  return (
    <div>
      <Layout>
        <Tab.Container id="left-tabs-example" defaultActiveKey="artist">
          <div className="shadow-sm">
            <Container className="">
              <Nav
                variant="pills"
                className="flex-row justify-content-between service-tabs "
              >
                {categories?.length > 0 && categories?.map((item, index) => {
                  return (
                    <Nav.Item>
                      <Nav.Link eventKey={item?.categoryName} className="p-3" onClick={() => handleCateorySubmit(item?.categoryName)}>
                        {item?.categoryName}
                      </Nav.Link>
                    </Nav.Item>
                  )
                })}
              </Nav>
            </Container>
          </div>
          <div>
          </div>
        </Tab.Container>
        {isLoading === true ? <ProgressSpinner aria-label="Loading" /> :
          <Container className="py-5">
            <div className="row">
              <div className="col-lg-12 mb-3">
                <h2 className="fw-semibold text-dark ms-1 ">{name?.category ? name?.category : "All"} Near Me in {address?.address?.formatted_address}</h2>
              </div>
              <div className="col-lg-3 ms-2 mb-3 p-2 px-3 d-flex align-items-center rounded-3 bg-gray-light">
                <p className="m-0">Sort by:</p>
                <Form.Select className="w-75 bg-transparent border-0" onChange={GlobalValueChange}>
                  <option value="0">Sort by</option>
                  <option value="1">Recommended first</option>
                  <option value="2">Distance(nearest first)</option>
                  <option value="3">Reviews(top-rated first)</option>
                </Form.Select>
              </div>
            </div>
            <div className="row ">
              {search?.businesses?.length > 0 && search?.businesses?.map((item, index) => {
                return (
                  <div className="col-3 mb-5">
                    <Card style={{ width: '18rem', height: "28rem" }} onClick={() => navigate(`/single-page/${item._id}`)}>
                      <Card.Img variant="top" src={`https://instajamaica-api.appdeft.biz/${item?.image?.workPlaceURL}`} className="overflow-auto" style={{ height: "250px", width: "286px" }} />
                      <Card.Body>
                        <Card.Title className="text-start">{item?.businessName}</Card.Title>
                        <Card.Text>
                          <p className="text-gray-light fw-light" style={{ fontSize: '12px' }}>{item?.address?.line1}, {item?.address?.line2 === "" ? "" : item?.address?.line2}, {item.address?.city}, {item?.address?.zipCode}</p>
                          <div className="d-flex flex-wrap justify-content-between gap-1">
                            <p>
                              {item?.rating} <i className="pi pi-star-fill text-golden"></i>{" "}
                              <small className="text-gray-light fw-light">/{item?.reviewCount} reviews</small>
                            </p>
                            <Button
                              // onClick={()=>handleLikeUnLike(bussiness._id)}
                              icon="pi pi-heart"
                              className={`bg-transparent border-0 text-dark unliked`}
                            />
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                )
              })}
            </div>
          </Container>
        }
      </Layout>
    </div>
  )
}
export default Services;