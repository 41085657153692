import React,{useEffect} from "react";
import Nav from "react-bootstrap/Nav";
import { Container } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import { useSelector,useDispatch } from "react-redux";
import { searchBusinessAction,allCategoriesAction } from "../../../../redux/clientAction/allCategoriesAction";
import { useNavigate } from "react-router-dom";
const TabConatiner = () => {
  const dispatch=  useDispatch()
  const navigate =  useNavigate()
  const categories = useSelector((state) => state.allCatergory.categoryProfile)
  const handleCateorySubmit = (name)=>{
    dispatch(searchBusinessAction({search:name},navigate(`/service/${name}`)))
  }
  useEffect(() => {
    dispatch(allCategoriesAction())
  }, [])
  return (
    <div>
      <Tab.Container id="left-tabs-example" defaultActiveKey="artist">
        <div className="shadow-sm">
          <Container className="">
            <Nav
              variant="pills"
              className="flex-row justify-content-between service-tabs "
            >
              {categories?.length > 0 && categories?.map((item, index) => {
                return (
                  <Nav.Item>
                    <Nav.Link eventKey={item?.categoryName} className="p-3" onClick={()=>handleCateorySubmit(item?.categoryName)}>
                      {item?.categoryName}
                    </Nav.Link>
                  </Nav.Item>
                )
              })}

              {/* <Nav.Item>
                  <Nav.Link eventKey="beauty" className="p-3">
                    Beauty
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="carpenter" className="p-3">
                    Carpenter
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="painter" className="p-3">
                    Painter
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="cleaning" className="p-3">
                    Cleaning
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="logistic" className="p-3">
                    Logistic
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="electrician" className="p-3">
                    Electrician
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="carwash" className="p-3">
                    Car Wash
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="more" className="p-3">
                    More..
                  </Nav.Link>
                </Nav.Item> */}
            </Nav>
          </Container>
        </div>
      </Tab.Container>
    </div>
  )
}
export default TabConatiner;