import React, { useState } from "react";
import Layout from "../../layout";
import Logo from "../../assets/icons/Logo.png";
import InputTextComponent from "../../components/input/InputTextComponent";
import MainButton from "../../components/buttons/MainButton";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { forgetPasswordAction } from "../../redux/action/forgetPasswordAction";
import CreateNewPassword from "./CreateNewPassword";
import OtpVerification from "./OtpVerification";
import LayoutSidebar from "../../layout/LayoutSidebar";
export default function ForgetPassword() {
  const [form, setForm] = useState({
    email: "",
    otp: "",
    password: "",
    newPassword:""
  })
  const [selectedState, setSelectedState] = useState("1")
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(forgetPasswordAction(form, () => setSelectedState("2")))
  }
  return (
    <div>
      {selectedState === "1" &&
        <div>
          <LayoutSidebar>
            <div className="background-all d-flex justify-content-center align-items-center">
              <Card className="logincards border-0">
                <div className="p-4 login">
                  <img src={Logo} alt="logo" className="d-block mx-auto mt-5" />

                  <div className=" mt-4 ">
                    <h4 className="heading text-center fw-semibold">Forgot Password</h4>
                  </div>
                  <div className="mt-3">
                    <span className="p-input-icon-left w-100">
                      <i className="pi pi-envelope" />
                      <InputTextComponent placeholder="Email" value={form.email} onChange={(e) => setForm({
                        ...form,
                        email: e.target.value
                      })} />
                    </span>
                  </div>
                  <div className="my-3 d-block ">
                    <Link to="">
                      <MainButton label="Send OTP" className="border-0" onClick={handleSubmit} />
                    </Link>
                  </div>
                  <div className="d-flex justify-content-center my-3">
                    <p className="sub-heading-2">Don’t have an account?</p>&nbsp;
                    <Link
                      to="/signup"
                      className="link text-main text-decoration-none"
                    >
                      <span className="sign-text">Signup</span>
                    </Link>
                  </div>
                </div>
              </Card>
            </div>
          </LayoutSidebar>
        </div>
      }

      {selectedState === "2" && <OtpVerification form={form} setForm={setForm} selectedState={selectedState} setSelectedState={setSelectedState} handleSubmit = {handleSubmit} />}
      {selectedState === "3" && <CreateNewPassword form={form} setForm={setForm} selectedState={selectedState} setSelectedState={setSelectedState} />}
    </div>
  );
}
