import React from "react";
import Layout from "../../layout";
import ArrowRight from "../../assets/icons/arrow-right.png";
import CameraIcon from "../../assets/icons/camera.png";
import UploadImg from "../../assets/images/iamge6.png";
import CloseIcon from "../../assets/icons/closeicon.png";

export default function ProfileImage() {
  return (
    <div>
      <Layout>
        <div className="p-5">
          <div className="d-flex align-items-center mb-3">
            <img src={ArrowRight} width={25} alt="" />
            <h4 className="text-main fw-bold mb-0 ms-3">Profile Images</h4>
          </div>
          <div className="bg-white rounded p-5">
            <div className="row">
              <div className="col-8">
                <div className="row mb-4">
                  <div className="col-md-6">
                    <p className="text-main text-sm fw-semibold m-0">Logo</p>
                    <p className="text-sm text-gray">
                      Upload your business logo so it's visible on your
                      Instajamaica Profile.
                    </p>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="img-upload w-auto h-auto d-flex flex-column justify-content-center align-items-center rounded position-relative cursor-pointer p-2 py-3">
                          <img
                            src={CameraIcon}
                            width={30}
                            className="mb-2"
                            alt=""
                          />
                          <p className="text-gray-light m-0">Add Media</p>
                          <input
                            type="file"
                            className="position-absolute w-100 h-100 file-upload cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <p className="text-main text-sm fw-semibold m-0">
                      Portfolio
                    </p>
                    <p className="text-sm text-gray">
                      Give clients a sneak peek of your space before they even
                      walk through the door.
                    </p>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="img-show dashed-border d-flex flex-column justify-content-center align-items-center rounded position-relative cursor-pointer">
                          <img
                            src={CameraIcon}
                            width={30}
                            className="mb-2"
                            alt=""
                          />
                          <p className="text-gray-light m-0">Add Media</p>
                          <input
                            type="file"
                            className="position-absolute w-100 h-100 file-upload cursor-pointer"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="img-show d-flex flex-column justify-content-center align-items-center rounded position-relative cursor-pointer position-relative">
                          <div className="position-absolute close-btn bg-main rounded-circle d-flex justify-content-center align-items-center text-white">
                            <img src={CloseIcon} width={8} alt="" />
                          </div>
                          <img
                            src={UploadImg}
                            className="w-100 h-100 cover-img rounded"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <p className="text-main text-sm fw-semibold m-0">
                      Cover Photo
                    </p>
                    <p className="text-sm text-gray">
                      Your cover photo is the first thing that your customers
                      see on your Instajamaica Profile. Add a photo to give them
                      insight into what you're all about.
                    </p>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="img-upload d-flex flex-column justify-content-center align-items-center rounded position-relative cursor-pointer ">
                          <img
                            src={CameraIcon}
                            width={30}
                            className="mb-2"
                            alt=""
                          />
                          <p className="text-gray-light m-0">Add Media</p>
                          <input
                            type="file"
                            className="position-absolute w-100 h-100 file-upload cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <p className="text-main text-sm fw-semibold m-0">
                      Workplace photos
                    </p>
                    <p className="text-sm text-gray">
                      Give clients a sneak peek of your space before they even
                      walk through the door.
                    </p>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="img-show dashed-border d-flex flex-column justify-content-center align-items-center rounded position-relative cursor-pointer">
                          <img
                            src={CameraIcon}
                            width={30}
                            className="mb-2"
                            alt=""
                          />
                          <p className="text-gray-light m-0">Add Media</p>
                          <input
                            type="file"
                            className="position-absolute w-100 h-100 file-upload cursor-pointer"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="img-show d-flex flex-column justify-content-center align-items-center rounded position-relative cursor-pointer position-relative">
                          <div className="position-absolute close-btn bg-main rounded-circle d-flex justify-content-center align-items-center text-white">
                            <img src={CloseIcon} width={8} alt="" />
                          </div>
                          <img
                            src={UploadImg}
                            className="w-100 h-100 cover-img rounded"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
