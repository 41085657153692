import ClientConstants from "../../services/ClientConstants";
import { clientApi, clientBussiness } from "../../services/clientApi";
import { authenticate } from "../../services/authService";
import { types } from "../types/types";
import { showToast } from "../action/toastAction";
import { showLoaderAction, hideLoaderAction } from "../action/loaderAction";
import { Dialog } from "primereact/dialog";

export const allCategoriesAction = () => async (dispatch) => {
    // dispatch(showLoaderAction())
    const res = await clientApi("get", ClientConstants.END_POINT.allCategories);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CLIENT_ALL_CATEGORIES,
                payload: res.data
            })
        }
    }
}

//get-all-business 
export const getBussinessAction = () => async (dispatch) => {
    // dispatch(showLoaderAction())
    const res = await clientBussiness("post", ClientConstants.END_POINT.GETBUSSIBNESS);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CLIENT_GET_BUSSINESS,
                payload: res.data,
            });
        }
        dispatch(hideLoaderAction())
        // dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}

//get-bussiness-by-id
export const getBussinessByIdAction = (id, next) => async (dispatch) => {
    // dispatch(showLoaderAction())
    const res = await clientApi("get", `${ClientConstants.END_POINT.GETBUSSINESSBYID}/${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CLIENT_GET_BUSSINESS_BY_ID,
                payload: res.data
            })
            dispatch(hideLoaderAction())
        }
    }
}

export const searchBusinessAction = (data,next) => async (dispatch) => {
    // dispatch(showLoaderAction())
    const res = await clientApi("post", ClientConstants.END_POINT.GETBUSSIBNESS, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.RECENT_SEARCH_BUSSINESS,
                payload: res.data
            });
            // next()
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}
//get-reviews-by-id
export const getBussinessReviewsByIdAction = (id) => async (dispatch) => {
    // dispatch(showLoaderAction())
    const res = await clientApi("get", `${ClientConstants.END_POINT.GETBUSINESSREVIEWSBYID}/${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CLIENT_BUSINESS_REVIEWS_BY_ID,
                payload: res.data
            })
        }
        // dispatch(hideLoaderAction())
    }
}

//get-slots-by-id
export const getSlotsById = (id, data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await clientApi("post", `${ClientConstants.END_POINT.GETSLOTS}/${id}`, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CLIENT_GET_SLOTS,
                payload: res.data,
            });
        }
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}

//book-slots
export const bookSlotsAction = (id, data) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await clientApi("post", `${ClientConstants.END_POINT.BOOKSLOTS}/${id}`, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CLIENT_BOOK_SLOTS,
                payload: res.data
            });
        }
        dispatch(hideLoaderAction())
        // dispatch(showToast({ severity: "success", summary: res.message }));
        return res
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}

//Recent Search 

export const recentSearchAction = () => async (dispatch) => {
    // dispatch(showLoaderAction())
    const res = await clientApi("get", `${ClientConstants.END_POINT.RECENTSEARCH}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.RECENT_SEARCH,
                payload: res.data
            });
        }
        dispatch(hideLoaderAction())
        // dispatch(showToast({severity:"success",summary:res.message}));
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}


export const deleteSearchAction = (id) => async (dispatch) => {
    // dispatch(showLoaderAction())
    const res = await clientBussiness("delete", `${ClientConstants.END_POINT.DELETESEARCH}/${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.DELETE_SEARCH,
                payload: res.data
            });
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const likeunlikeProtofiloAction = (id) => async (dispatch) => {
    // dispatch(showLoaderAction())
    const res = await clientApi("get", `${ClientConstants.END_POINT.LIKEUNLIKE}/${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.LIKE_UNLIKE,
                payload: res.data
            });
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const addReportAction = (id, data) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await clientApi("post", `${ClientConstants.END_POINT.ADDREPORTS}/${id}`, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ADD_BUSSINESS_REPORT,
                payload: res.data,
            });
        }
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}