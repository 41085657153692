import React from "react";
import { Dialog } from "primereact/dialog";

export default function CustomModal({header, visible, onHide, children, className}) {
  return (
    <div>
      <Dialog
        header={header}
        visible={visible}
        style={{ width: "30rem" }}
        onHide={onHide}
        className={className}
      >
       {children}
      </Dialog>
    </div>
  );
}
