import React,{useState} from "react";
import { Link } from "react-router-dom";
import MainButton from "../../components/buttons/MainButton";
import CameraIcon from "../../assets/icons/camera.png";
import LayoutSidebar from "../../layout/LayoutSidebar";
import { Card } from "react-bootstrap";
import { ProgressBar } from "primereact/progressbar";
import { createworkplace } from "../../redux/action/businessHoursAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
export default function Workplace() {
  const navigate =  useNavigate();
  const dispatch =  useDispatch();
  const [files, setFiles] = useState([]);
  const onFileChange = (e) => setFiles(e.target.files);
  const onFileSubmit = () => {
    dispatch(createworkplace(files,()=>navigate("/provider/add-service")));
  };           
  const handleSkip = ()=>{
    navigate("/provider/add-service")
  }      
  return (
    <div>
      <LayoutSidebar>
        <div className=" background-all">
          <div className="row p-0" style={{ height: "100vh" }}>
            <div className="col-md-6 m-0 img-signup h-100 position-relative p-0">
              <div className=" h-100 d-flex align-items-center justify-content-center">
                <h1 className="text-white position-relative fw-bold">
                  Setup Your Business <br />
                  With InstaJamaica
                </h1>
              </div>
            </div>
            <div className="col-md-6 m-0">
              <div className="d-flex align-items-center justify-content-center h-100">
                <Card className="border-0" style={{ width: "30rem" }}>
                  <ProgressBar value={20} style={{ height: "6px" }}></ProgressBar>
                  <Card.Body className="p-5">
                    <div>
                      <div className="d-block  mt-2 ">
                        <i className="pi pi-arrow-left text-main fs-5 mb-3 cursor-pointer" onClick={()=>navigate("/provider/bussiness")}></i>
                        <h4 className="text-main fw-bold">Show off your Workplace</h4>
                        <small className="text-gray-light">
                          This photo will be displayed on your business profile when clients
                          look for you on Instajamaica.
                        </small>
                        <div className="rounded text-center add-photos p-3 d-flex align-items-center flex-column justify-content-center mt-3 mb-3 position-relative">
                          <img src={CameraIcon} width={30} alt = "" />
                          <input type="file" onChange={onFileChange} multiple  className="position-absolute top-0 left-0 w-100 h-100 opacity-0"/>
                          <p className="fw-semibold text-xs mb-0">Add workspace photo.</p>
                          <p className="text-xs fw-medium text-gray-light m-0">
                            This is optional, but highly recommended. Show your clients what
                            you’re all about.
                          </p>

                        </div>
                        {files[0] ?  
                          <Link to="">
                          <MainButton label="Continue" onClick={onFileSubmit} />
                        </Link>
                        : <Link to="/provider/add-service">
                          <MainButton
                            label="Skip"
                            className="bg-white cancelbtn text-gray fw-semibold"
                            onClick={handleSkip}
                          />
                        </Link>
                        }
                       
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </LayoutSidebar>
    </div>

  );
}
