import {api} from "../../services/api";
import Constants from "../../services/Constants";
import { types } from "../types/types";
import { showToast } from "./toastAction";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
export const requestotpAction = (data, next=null) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.REQUESTOTP, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.REQUEST_OTP,
                payload: res.data,
            });
        }
        dispatch(showToast({ severity: "success", summary: res.message }));
        if(next !== null) next();
        dispatch(hideLoaderAction())
    }

    else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch(hideLoaderAction())
    }
}