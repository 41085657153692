import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MainButton from "../../components/buttons/MainButton";
import { Checkbox } from "primereact/checkbox";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/action/toastAction";
export default function WhereDoWork({ form, setForm, setSelectedStep }) {
  const dispatch = useDispatch()
  const [firstChecked, setFirstChecked] = useState(false)
  const [secondChecked, setSecondChecked] = useState(false)
  const handleFirstChange = (e) => {
    if (e.target.name === "MY_LOCATION") {
      setFirstChecked(e.checked)
      setForm({
        ...form,
        workType: e.target.name
      })
      // setSecondChecked(false);
    }
    else if (e.target.name === "CLIENT_LOCATION") {
      setSecondChecked(e.checked)
      setForm({
        ...form,
        workType: e.target.name
      })
      // setFirstChecked(false)

    }
  }
  const handleSubmit = () => {
    if (form.workType === "") {
      dispatch(showToast({ severity: "error", summary: "Please Choose Worktype", content: "Please Choose Worktype" }));
    } else {
      setSelectedStep("4")
    }
  }
  useEffect(() => {
    if(firstChecked === true && secondChecked === false) {
      setForm({
        ...form,
        workType:"MY_LOCATION"
      })
    }
    else if(secondChecked === true && firstChecked === false ){
      setForm({
        ...form,
        workType :"CLIENT_LOCATION"
      })
    }
   else if(firstChecked === true && secondChecked === true){
      setForm({
        ...form,
        workType:"BOTH"
      })
    }else{
      setForm({
        businessName: form.businessName,
        category: form.category,
        countryCode: form.countryCode,
        mobile: form.mobile,
        workType: "",
        location: {
          coordinates: []
        },
        address: {
          line1: "",
          line2: "",
          city: "",
          zipCode: ""
        }
      })
    }
  }, [firstChecked, secondChecked])
  return (
    <div>
      <div className="d-block  mt-2 ">
        <i className="pi pi-arrow-left text-main fs-3 mb-2 cursor-pointer" onClick={() => setSelectedStep("2")}></i>
        <h4 className="text-main fw-bold mb-3">Where Do You Work?</h4>
        <div className="d-flex mb-3">
          <Checkbox name="MY_LOCATION" onChange={handleFirstChange} checked={firstChecked} value={form.workType} />
          <div className="ms-2">
            <p className="fw-bold mb-0">At my place</p>
            <small className="text-gray-light text-xs">
              My clients come to me. I own the place or work in a salon/suite
              alongside other professionals.
            </small>
          </div>
        </div>
        <div className="d-flex mb-3">
          <Checkbox name="CLIENT_LOCATION" onChange={handleFirstChange} checked={secondChecked} />
          <div className="ms-2">
            <p className="fw-bold mb-0">At my client’s location</p>
            <small className="text-gray-light text-xs">
              I'm on the go. My services are performed at the client's location.
            </small>
          </div>
        </div>
        <Link to="">
          <MainButton label="Continue" onClick={handleSubmit} />
        </Link>
      </div>
    </div>
  );
}
