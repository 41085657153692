import ClientConstants from "../../services/ClientConstants";
import { clientApi } from "../../services/clientApi";
import { authenticate } from "../../services/authService";
import { types } from "../types/types";
import { showToast } from "../action/toastAction";
import { hideLoaderAction,showLoaderAction } from "../action/loaderAction";

export const clientForgetPasswordAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await clientApi("post", ClientConstants.END_POINT.ClientForgetPassword, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CLIENT_FORGET_PASSWORD,
                payload: res.data,
            });
        }
        dispatch(showToast({ severity: "success", summary: res.message }));
        next();
        dispatch(hideLoaderAction())
    }

    else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch(hideLoaderAction())
    }
}

export const ClientChangeForgetPassword = (data, next = null) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await clientApi("post", ClientConstants.END_POINT.ClientcChangeForgetPassword, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CLIENT_CHANGE_FORGET_PASSWORD,
                payload: res.data,
            });
        }
        dispatch(showToast({ severity: "success", summary: res.message }));
        if (next !== null) next();
        dispatch(hideLoaderAction())
    }

    else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch(hideLoaderAction())
    }
}