import React from "react";

const Loader = () =>{
    return(
        <div className="main-loader">
            <i className="pi pi-spin pi-spinner"></i>
        </div>
    )
}

export default Loader;