import { types } from "../types/types";

const intitalState = {
    crateBusinessProfile: {},
    getCategoriesProfile:[]
};

export const createBusinessReducer = (state = intitalState, action)=>{
    switch(action.type){
        case types.CREATE_BUSINESS:
            return {
                ...state,
                crateBusinessProfile: action.payload,
            };
            default:
            return { ...state };
    }
}

export const getCategories = (state = intitalState,action)=>{
    switch(action.type){
            case types.GET_CATEGORIES:
                return{
                    ...state,
                    getCategoriesProfile:action.payload,
                };
                default:
                    return{ ...state};
    }
}