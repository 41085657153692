import React from "react";
import { Link } from "react-router-dom";
import ChevronIcon from "../../assets/icons/chevrongreen.png";
import HomeContent from "./HomeContent";

export default function Specialist() {
  const { SpecialistData } = HomeContent();
  return (
    <div className="container">
      <h2 className="fw-bold text-center mb-3">
        Find your InstaJamaica specialist by city
      </h2>
      <div className="row mb-3">
        {SpecialistData?.map((items, key) => {
          return (
            <div className="col-md-3">
              <Link
                to={items.path}
                className="text-black d-flex text-decoration-none align-items-center p-2"
              >
                <img src={ChevronIcon} alt="" width={10} />
                <p className="ms-2 text-sm mb-0">{items.heading}</p>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
