import React, {useState} from "react";
import InputTextComponent from "../../components/input/InputTextComponent";
import { PhoneInput } from "react-contact-number-input";
import MainButton from "../../components/buttons/MainButton";
import { Link } from "react-router-dom";
import DropDownComponent from "../../components/input/DropDownComponent";
import { getCategories } from "../../redux/action/createBusinessAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { showToast } from "../../redux/action/toastAction";
export default function BussinessCategory({form,setForm,setSelectedStep}) {
  const data =  useSelector((state)=>state.getCategories.getCategoriesProfile)
  const dispatch = useDispatch();
  const [selectedCity, setSelectedCity] = useState(null);
    useEffect(()=>{
      dispatch( getCategories())
    },[])
    const handleOnChange =async(e)=>{
      setSelectedCity(e.value);
      setForm({
        ...form,
        category:e.value._id
      })
    }
    const handleSubmit=async()=>{
      if(form.category === ""){
        dispatch(showToast({ severity: "error", summary: "Please Choose Category", content: "Please Choose Category" }));
      }else{
        setSelectedStep("3")
      }
    }
  return (
    <div>
      <div className="d-block  mt-2 ">
        <i className="pi pi-arrow-left text-main fs-3 mb-2 cursor-pointer" onClick={()=>setSelectedStep("1")}></i>
        <h4 className="text-main fw-bold">Choose your Business Category</h4>
        <div className="mt-3">
          <DropDownComponent placeholder="Choose Business" value={selectedCity} onChange={handleOnChange} options = {data} extraClassName="mb-3" optionLabel="categoryName"/>
        </div>
        <Link to="">
          <MainButton label="Continue" onClick={handleSubmit} />
        </Link>
      </div>
    </div>
  );
}