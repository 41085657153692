import React from "react";
import { Link } from "react-router-dom";

export default function SettingCard({ title, subtitle, onClick, size,link }) {
  return (
    <div className={size}>
      <Link to={link} className="text-decoration-none">
        <div
          className="setting-card bg-white rounded p-3 shadow h-100"
          onClick={onClick}
        >
          <div className="d-flex align-items-center justify-content-between flex-row h-100">
            <div className="d-flex flex-column justify-content-between">
              <h2 className="text-lg text-main fw-bold">{title}</h2>
              <small className="conditions text-dark">{subtitle}</small>
            </div>
            <div>
              <i
                className="pi pi-angle-right fs-3 text-dark"
                style={{ cursor: "pointer" }}
                onClick={onClick}
              ></i>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
