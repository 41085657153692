import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MainButton from "../../components/buttons/MainButton";
import CrossIcon from "../../assets/icons/crossicon.png";
import { Dialog } from "primereact/dialog";
import InputTextComponent from "../../components/input/InputTextComponent";
import DropDownComponent from "../../components/input/DropDownComponent";
import InputNumberComponent from "../../components/input/InputNumberComponent";
import LayoutSidebar from "../../layout/LayoutSidebar";
import { Card } from "react-bootstrap";
import { ProgressBar } from "primereact/progressbar";
import { useDispatch, useSelector } from "react-redux";
import { getServicesAction, createServiceAction, deleteServiceAction } from "../../redux/action/businessHoursAction";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
toast.configure()
export default function AddingService() {
  const [servicevisible, setServiceVisible] = useState(false);
  const [date, setDate] = useState()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state?.getServices?.getServicesProfile);
  useEffect(() => {
    dispatch(getServicesAction())
  }, [])

  const minutes = ["0", "30"]
  const generateTimeSlots = (hh, mm, ss) => {
    const startTime = new Date();
    startTime.setHours(1, 0, 0);

    const endTime = new Date();
    endTime.setHours(8, 0, 0);

    const genTimeSlots = [];

    while (startTime <= endTime) {
      const hours = startTime.getHours().toString().padStart(2, '0');
      const minutes = startTime.getMinutes().toString().padStart(2, '0');
      const timeSlot = `${hours}:${minutes}`;
      genTimeSlots.push(timeSlot);

      startTime.setMinutes(startTime.getMinutes() + 60);
    }
    let duration = []
    genTimeSlots.map((item) => {
      let initial = item.split(":")
      duration.push(initial[0])
    })
    setDate(duration)
    return genTimeSlots;
  };
  useEffect(() => {

    generateTimeSlots()

  }, []);
  const [form, setForm] = useState({
    serviceName: "",
    priceType: "FIXED",
    price: "",
    durationTime: "",
    min: ""
  })
  const handleCreate = () => {
    if (form.serviceName === "" || form.durationTime === undefined || form.price === "" || form.priceType === "" || form.durationTime === "") {
      toast.error("Required all fields")
    } else {
      const hours = form.durationTime === "30" ? parseInt(30) : parseInt(form.durationTime * 60);
      const minutes = parseInt(form.min) || parseInt(0)
      const addTime = form.durationTime === "30" ? hours :  hours + minutes
      const payload = {
        serviceName: form.serviceName,
        priceType: "FIXED",
        price: form.price,
        durationTime: addTime.toString() || hours || minutes
      }
      dispatch(createServiceAction(payload, () => setServiceVisible(false)))
      setForm({
        serviceName: "",
        price: "",
        durationTime: ""
      })
    }

  }
  const handleDelete = (id) => {
    dispatch(deleteServiceAction(id))
  }
  useEffect(() => {
    if (form.durationTime === "" || form.durationTime === undefined && form.min !== "") {
      setForm({
        ...form,
        durationTime: form.min
      })
    }
  }, [form.min])
  return (
    <div>
      <LayoutSidebar>
        <div className=" background-all">
          <div className="row p-0" style={{ height: "100vh" }}>
            <div className="col-md-6 m-0 img-signup h-100 position-relative p-0">
              <div className=" h-100 d-flex align-items-center justify-content-center">
                <h1 className="text-white position-relative fw-bold">
                  Setup Your Business <br />
                  With InstaJamaica
                </h1>
              </div>
            </div>
            <div className="col-md-6 m-0">
              <div className="d-flex align-items-center justify-content-center h-100">
                <Card className="border-0" style={{ width: "30rem" }}>
                  <ProgressBar value={20} style={{ height: "6px" }}></ProgressBar>
                  <Card.Body className="p-5">
                    <div>
                      <div className="d-block  mt-2 ">
                        <i className="pi pi-arrow-left text-main fs-5 mb-3 cursor-pointer" onClick={() => navigate("/provider/add-workplace")}></i>
                        <h4 className="text-main fw-bold">Start Adding Service</h4>
                        <small className="text-gray-light">
                          Add at least one service now. Later you can add more, edit details,
                          and group services into categories.
                        </small>
                        <div className="mb-3">
                          {data.length > 0 && data.map((item, index) => {
                            return (
                              <div className="row py-3 border-bottom">
                                <div className="col-1">
                                  <img src={CrossIcon} alt="" width={11} onClick={() => handleDelete(item._id)} />
                                </div>
                                <div className="col-4">
                                  <p className="fw-semibold m-0">{item.serviceName}</p>
                                </div>
                                <div className="col-4">
                                  <p className="fw-semibold text-gray-light m-0">{item.durationTime}min</p>
                                </div>
                                <div className="col-3">
                                  <p className="fw-semibold m-0">${item.price}.00</p>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <div className="d-flex mb-3">
                          <MainButton
                            label="+ Add Service"
                            className="bg-white cancelbtn text-gray fw-semibold"
                            onClick={() => setServiceVisible(true)}
                          />
                        </div>
                        <Link to="/provider/setup-done">
                          <MainButton label="Continue" />
                        </Link>
                      </div>
                      <Dialog
                        header={<h4 className="text-main fw-bold mb-0">Add Service</h4>}
                        visible={servicevisible}
                        style={{ width: "50vw" }}
                        onHide={() => setServiceVisible(false)}
                      >
                        <p className="m-0 text-gray-light mb-3">
                          This photo will be displayed on your business profile when clients
                          look for you on Instajamaica.
                        </p>
                        <InputTextComponent placeholder="Service Name" value={form.serviceName} name="serviceName"
                          onChange={(e) => {
                            setForm({
                              ...form,
                              serviceName: e.target.value
                            })
                          }} />
                        <p className="fw-bold text-sm">Service duration</p>
                        <div className="row">
                          <DropDownComponent placeholder="0h" extraClassName="col-md-6 mb-2" options={date} value={form.durationTime} name="durationTime"
                            onChange={(e) => {
                              setForm({
                                ...form,
                                durationTime: e.value
                              })
                            }} />
                          <DropDownComponent placeholder="0min" extraClassName="col-md-6 mb-2" options={minutes} value={form.min} onChange={(e) => {
                            setForm({
                              ...form,
                              min: e.value
                            })
                          }} />
                          <div className="col-12 mb-3">
                            <div className="p-inputgroup w-100">
                              <span className="p-inputgroup-addon border-0">$</span>
                              <InputNumberComponent placeholder="Price" inputClass="w-100" extraClassName="w-100" value={form.price} name="price"
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    price: e.value
                                  })
                                }} />
                            </div>
                          </div>
                        </div>
                        <MainButton label="Add" onClick={handleCreate} />
                      </Dialog>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </LayoutSidebar>
    </div>

  );
}
