import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Layout from "../../Layout";
import { Button, Container } from "react-bootstrap";
import { Avatar } from "primereact/avatar";
import EditProfile from "./EditProfile";
import MyBooking from "./MyBooking";
import Favourites from "./Favourites";
import BookingHistory from "./BookingHistory";
import Payment from "./Payment";
import SavedAddress from "./SavedAddress";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import AboutInstajamica from "./AboutInstajamica";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import TabConatiner from "./TabContainer";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useDispatch } from "react-redux";
import { getUserProfileAction } from "../../../../redux/clientAction/clientSignupAction";
import { updateUserProfile } from "../../../../redux/clientAction/clientSignupAction";
import cameraIcon from "../../../../assets/icons/greenCamera.png"
export default function CustomerProfile() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const accept = () => {
    handleLogout()
  }
  const [update, setUpdate] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    image: ""
  })
  const data = useSelector((state) => state?.getUserData?.getUserProfile)
  const [mobileData, setMobileData] = useState(0)
  const [errors, setErrors] = useState({})
  const reject = () => {
    console.log("HIiiiiiiiii")
  }
  const handleLogout = () => {
    localStorage.clear()
    navigate("/")
  }
  const userData = useSelector((state) => state.getUserData.getUserProfile)
  const confirm1 = () => {
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept,
      reject
    });
  };
  const handleChange = (e) => {
    setUpdate({
      ...update,
      [e?.target?.name]: e?.target?.value,
    });
    setErrors({
      ...errors,
      [e?.target?.name]: null,
    });
  };
  const token = localStorage.getItem("token")
  useEffect(() => {
    if (token) {
      dispatch(getUserProfileAction())
    }

  }, [token])
  useEffect(() => {
    if (data) {
      setUpdate(data)
      setMobileData(data.mobile)
    }
  }, [data])
  const handleSubmit = async () => {
    if (update.firstName === "" || update.lastName === "" || update.email === "" || update.mobile === "") {
      setErrors({
        ...errors,
        firstName: update.firstName === "" ? "Please Enter First Name" : errors.firstName,
        lastName: update.lastName === "" ? "Please Enter Last Name" : errors.lastName,
        email: update.email === "" ? "Please Enter Email" : errors.email,
        mobile: update.mobile === "" ? "Please Enter Mobile" : errors.mobile
      })
      return false;
    }
    const formData = new FormData()
    if (typeof update.image === "object") {
      formData.append("image", update.image)
    }
    formData.append("firstName", update.firstName)
    formData.append("lastName", update.lastName)
    formData.append("email", update.email)
    formData.append("mobile", update.mobile)
    formData.append("dob", update.dob)
    dispatch(updateUserProfile(formData))
  }
  let handleOnChange = value => {
    setErrors({
      ...errors,
      mobile: null,
    });
    setUpdate({
      ...update, mobile: value,
    });
  };
  return (
    <div>
      <Layout>
        <TabConatiner />
        <ConfirmDialog />
        <div className="bg-white-light p-5">

          <Container>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="editprofile"
            >
              <Row>
                <Col sm={3} className="border-end">
                  <div className="d-flex align-items-center mb-4">
                    <div className="position-relative">
                      <Avatar
                        image={typeof update.image === "object" ? URL.createObjectURL(update.image) : `https://instajamaica-api.appdeft.biz/${userData.image}`}
                        size="xlarge"
                        shape="circle"
                      />
                      <label
                        htmlFor="profile-img"
                        className="rounded-circle p-2 h6"
                      >
                        <img src = {cameraIcon} alt = "" width={24} height={24} className="camera-Img"/>
                        {/* <i className="pi pi-camera"></i> */}
                      </label>
                      {/* <MainButton
                        icon="pi pi-camera"
                        className="rounded-circle p-2 h6"
                        extraClass=" position-absolute bottom-0 end-0"

                      /> */}
                      <input
                        type="file"
                        mode="basic"
                        hidden
                        className="file-upload text-white border-round-lg"
                        id="profile-img"
                        chooseLabel="Choose a file"
                        name="profile-img"
                        accept="image/*"
                        maxFileSize={1000000}
                        onChange={(e) => {
                          setUpdate({
                            ...update,
                            image: e.target.files[0]
                          })
                        }}
                      />
                    </div>
                    <h5 className="fw-semibold m-0 ms-2">{userData.firstName}</h5>
                  </div>
                  <Nav variant="pills" className="flex-column profile-edit">
                    <Nav.Item className="mb-3">
                      <Nav.Link eventKey="editprofile">Edit Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mb-3">
                      <Nav.Link eventKey="mybooking">My Bookings</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mb-3">
                      <Nav.Link eventKey="favourite">Favourites</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mb-3">
                      <Nav.Link eventKey="bookinghistory">
                        Booking History
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mb-3">
                      <Nav.Link eventKey="payment">Payments</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mb-3">
                      <Nav.Link eventKey="savedaddress">
                        Saved Addresses
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mb-3">
                      <Nav.Link eventKey="termsofservice">
                        Terms of Service
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mb-3">
                      <Nav.Link eventKey="privacypolicy">
                        Privacy Policy
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mb-3">
                      <Nav.Link eventKey="aboutinstajamica">
                        About InstaJamica
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mb-3">
                      <Nav.Link onClick={() => confirm1()}>Logout</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="editprofile">
                      <EditProfile update={update} setUpdate={setUpdate} errors={errors} setErrors={setErrors} data={data} setMobileData={setMobileData} handleChange={handleChange} handleSubmit={handleSubmit} handleOnChange={handleOnChange} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="mybooking">
                      <MyBooking />
                    </Tab.Pane>
                    <Tab.Pane eventKey="favourite">
                      <Favourites />
                    </Tab.Pane>
                    <Tab.Pane eventKey="bookinghistory">
                      <BookingHistory />
                    </Tab.Pane>
                    <Tab.Pane eventKey="payment">
                      <Payment />
                    </Tab.Pane>
                    <Tab.Pane eventKey="savedaddress">
                      <SavedAddress />
                    </Tab.Pane>
                    <Tab.Pane eventKey="termsofservice">
                      <TermsOfService />
                    </Tab.Pane>
                    <Tab.Pane eventKey="privacypolicy">
                      <PrivacyPolicy />
                    </Tab.Pane>
                    <Tab.Pane eventKey="aboutinstajamica">
                      <AboutInstajamica />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </div>
      </Layout>
    </div>
  );
}
