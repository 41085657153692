import { types } from "../types/types";

const intitalState = {
    appointmentProfile:[]
}

export const appointmentReducer =(state =  intitalState,action)=>{
    switch (action.type) {
        case types.APPOINTMENT:
            return {
                ...state,
                appointmentProfile: action.payload,
            };
        default:
            return { ...state };
    }
}   