import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import { Accordion } from "react-bootstrap";
import TabConatiner from "../profile/TabContainer";
import { Divider } from 'primereact/divider';
const FaqInsta = () => {
    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: "smooth" });
    },[])
    return (
        <div>
            <Layout>
                <TabConatiner/>
                <div className="banner p-5 mb-5">
                    <h1 className="display-3 d-flex justify-content-center align-items-center h-100 fw-bold text-main">
                        FAQ
                    </h1>
                </div>
                <div class="container mb-5">
                    <div class="row">
                        <div class="col-5 d-flex">
                            <div className="card border-0" style={{ width: "90%" }}>
                                <div className="p-3 fw-bold text-muted">

                                    <p className="mb-5 text-white text-white p-2 rounded-3" style={{ backgroundColor: "#42B16E" }} >Consumer Refund Request</p>
                                    <p className="mb-5 ">Card Payment Error (Fraud and Other)</p>
                                    <p className="mb-5">Appointments: Book, Reschedule, or Cancel</p>
                                    <p className="mb-5">Unable to Book an Appointment</p>
                                    <p className="mb-5">Family & Friends</p>
                                    <p className="mb-5">Reviewing Your Experience</p>
                                    <p className="mb-5">Server Error</p>
                                    <p className="mb-5">Set-Up and Verification Codes</p>
                                    <p className="mb-5">Managing Your Booksy Account</p>
                                    <p className="mb-5">Interested in Booksy Biz</p>
                                    <p className="mb-5">Security</p>
                                </div>
                            </div>
                            <Divider layout="vertical" />
                        </div>
                        <div class="col-7">

                            <h3 className="faq-heading">Consumer Refund Request</h3>
                            <Accordion className="faq" defaultActiveKey="0" style = {{margin:"25px"}}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>I have canceled my booking. How can I get my prepayment back?</Accordion.Header>
                                    <Accordion.Body className="bg-lighty-green">
                                        <p className="text-gray">
                                        If a booking was canceled within the provided time, the Provider should refund a payment on their end. Booksy does not make refunds on the Provider’s behalf. If you do not receive your refund within 2-5 business days, please contact the Provider. Their contact details can be found on their Business Profile.
                                        </p>
                                       
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>I have canceled my booking and I was charged. Why?</Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>I was overcharged for a service.</Accordion.Header>
                                </Accordion.Item>
                            </Accordion>
                            <h3 className="faq-heading">Card Payment Error (Fraud and Other)</h3>
                            <Accordion className="faq " defaultActiveKey="0" style = {{margin:"25px"}}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>I try to add my card details and the system does not accept them.</Accordion.Header>
                                    {/* <Accordion.Body>
                                        If a booking was canceled within the provided time, the Provider should refund a payment on their end. Booksy does not make refunds on the Provider’s behalf. If you do not receive your refund within 2-5 business days, please contact the Provider. Their contact details can be found on their Business Profile.
                                    </Accordion.Body> */}
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>How can I change or remove a card that is linked to my InstaJamaica account?</Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Does InstaJamaica store my credit card information?</Accordion.Header>
                                </Accordion.Item>
                            </Accordion>
                            <h3 className="faq-heading">Appointments: Book, Reschedule, or Cancel</h3>
                            <Accordion className="faq " defaultActiveKey="0" style = {{margin:"25px"}}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>How can I book my appointment?</Accordion.Header>
                                    {/* <Accordion.Body>
                                        If a booking was canceled within the provided time, the Provider should refund a payment on their end. Booksy does not make refunds on the Provider’s behalf. If you do not receive your refund within 2-5 business days, please contact the Provider. Their contact details can be found on their Business Profile.
                                    </Accordion.Body> */}
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>How can I reschedule my appointment?</Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>How can I cancel my appointment?</Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>How much will you collect for canceling a booking?</Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>I have booked an appointment but it’s not confirmed yet. What do I do?</Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Is a InstaJamaica Profile required to book an appointment?</Accordion.Header>
                                </Accordion.Item>
                            </Accordion>
                            <h3 className="faq-heading">Unable to Book an Appointment</h3>
                            <Accordion className="faq " defaultActiveKey="0" style = {{margin:"25px"}}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Why Can’t I Book an Appointment?</Accordion.Header>
                                </Accordion.Item> 
                            </Accordion>
                            <h3 className="faq-heading">Family & Friends</h3>
                            <Accordion className="faq " defaultActiveKey="0" style = {{margin:"25px"}}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>How many members can I add to my account?</Accordion.Header>
                                </Accordion.Item> 
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Will a member be able to see appointments I book for myself?</Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>What is the difference between an active and inactive member?</Accordion.Header>
                                </Accordion.Item> 
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Will I have access to view bookings done personally by member accounts?</Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Can I unlink a member from my account?</Accordion.Header>
                                </Accordion.Item> 
                            </Accordion>
                            <h3 className="faq-heading">Reviewing Your Experience</h3>
                            <Accordion className="faq " defaultActiveKey="0" style = {{margin:"25px"}}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>How can I leave a review?</Accordion.Header>
                                </Accordion.Item> 
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Can I update a review?</Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>I am not satisfied with the service provided. What can I do about it?</Accordion.Header>
                                </Accordion.Item> 
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>One of the shops should not be listed on Booksy. How can I report it?</Accordion.Header>
                                </Accordion.Item>
                            </Accordion>
                            <h3 className="faq-heading">Server Error</h3>
                            <Accordion className="faq " defaultActiveKey="0" style = {{margin:"25px"}}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>I’m getting a Server Error when I’m trying to book.</Accordion.Header>
                                </Accordion.Item> 
                            </Accordion>
                            <h3 className="faq-heading">Set-Up and Verification Codes</h3>
                            <Accordion className="faq " defaultActiveKey="0" style = {{margin:"25px"}}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>I have created my Booksy account but I have not got a verification code.</Accordion.Header>
                                </Accordion.Item> 
                            </Accordion>
                            <h3 className="faq-heading">Managing Your InstaJamaica Account</h3>
                            <Accordion className="faq " defaultActiveKey="0" style = {{margin:"25px"}}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>How can I delete my InstaJamaica account?</Accordion.Header>
                                </Accordion.Item> 
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>How do I Unsubscribe from InstaJamaica emails?</Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>How do I stop getting emails from a business listed on InstaJamaica?</Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>I forgot my InstaJamaica password. What do I do?</Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>I forgot my InstaJamaica password. What do I do?</Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>How can I change my password on  InstaJamaica.</Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>How can I change my email address registered on InstaJamaica.</Accordion.Header>
                                </Accordion.Item>
                            </Accordion>
                            <h3 className="faq-heading">Interested in InstaJamaica</h3>
                            <Accordion className="faq " defaultActiveKey="0" style = {{margin:"25px"}}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>I’m a business owner and would like to join InstaJamaica. How can I create an account?</Accordion.Header>
                                </Accordion.Item> 
                            </Accordion>
                            <h3 className="faq-heading">Security</h3>
                            <Accordion className="faq " defaultActiveKey="0" style = {{margin:"25px"}}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>How can I protect my passwords, account and data?</Accordion.Header>
                                </Accordion.Item> 
                            </Accordion>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default FaqInsta;