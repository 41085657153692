import React from "react";
import Topbar from "./topbar";
export default function LayoutSidebar({ children }) {
  return (
    <div>
      <Topbar />
      <div className="d-flex" style={{height: "91vh"}}>
        <div className="container-fluid bg-all h-100 overflow-scroll scrooling" style={{ width: "100%" }}>
          {children}
        </div>
      </div>
    </div>
  );
}
