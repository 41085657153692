import React, { useEffect, useState } from "react";
import InputTextComponent from "../../../../components/input/InputTextComponent";
import ProfileIcon from "../../../../assets/icons/Profile.png";
import EmailIcon from "../../../../assets/icons/Exclude.png";
import PhoneIcon from "../../../../assets/icons/phone.png";
import CakeIcon from "../../../../assets/icons/cake.png";
import MainButton from "../../../../components/buttons/MainButton";
import { InputText } from "primereact/inputtext";
import PhoneInput from "react-phone-input-2";
import { Calendar } from "primereact/calendar";
export default function EditProfile({ update, setUpdate, errors, setErrors, data, setMobileData, handleChange, handleSubmit, handleOnChange }) {

  return (
    <div className="px-4">
      <p className="text-lg bg-main p-3 text-white curve mb-3">Edit Profile</p>
      <span className="p-input-icon-left w-100 login d-flex align-items-center border curve overflow-hidden mb-3">
        <img
          src={ProfileIcon}
          alt=""
          width={20}
          className="position-absolute ms-2"
        />

        <InputText id="inputtext"
          placeholder=""
          className="border-round-md "
          name="firstName"
          value={update?.firstName}
          onChange={(e) => handleChange(e)} />
      </span>
      <span className="p-input-icon-left w-100 login d-flex align-items-center border curve overflow-hidden mb-3">
        <img
          src={ProfileIcon}
          alt=""
          width={20}
          className="position-absolute ms-2"
        />
        <InputTextComponent placeholder="Last name" extraClassName="w-100" name="lastName" value={update.lastName} onChange={(e) => handleChange(e)} />
      </span>
      <span className="p-input-icon-left w-100 login d-flex align-items-center border curve overflow-hidden mb-3">
        <img
          src={EmailIcon}
          alt=""
          width={20}
          className="position-absolute ms-2"
        />

        <InputTextComponent placeholder="duzmann@gmail.com" extraClassName="w-100" value={update.email} name="email" onChange={(e) => handleChange(e)} />
      </span>
      <span className="p-input-icon-left w-100 login d-flex align-items-center border curve overflow-hidden mb-3">
        <img
          src={PhoneIcon}
          alt=""
          width={20}
          className="position-absolute ms-2"
        />
        <PhoneInput
          country={"us"}
          className="marginBottom"
          value={update.mobile}
          onChange={handleOnChange}
        />
      </span>
      <span className="p-input-icon-left w-100 login d-flex align-items-center border curve overflow-hidden mb-3">
        <img
          src={CakeIcon}
          alt=""
          width={20}
          className="position-absolute ms-2 z-1"
        />
        <Calendar placeholder="Date of Birth" extraClassName="w-100" value={new Date(update.dob)} onChange={(e) => handleChange(e)} name="dob" />
        {/* <InputNumberComponent  /> */}
      </span>
      <MainButton label="Save" onClick={() => handleSubmit()} />
    </div>
  );
}
