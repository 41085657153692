import React from "react";
import Layout from "../../layout";
import SettingCard from "../../components/cards/SettingCard";

export default function SettingInfo() {
  return (
    <div>
      <Layout>
        <div className="p-5">
          <div className="d-flex align-items-center mb-3">
            <h4 className="text-main fw-bold mb-0 ms-3">Settings</h4>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <SettingCard
                  size="col-md-6 mb-3"
                  link="/bussiness-info"
                  title="Business Name & Info"
                  subtitle="Tell customers about your business, list your contact information, and link your social media accounts"
                />
                <SettingCard
                  size="col-md-6 mb-3"
                  link="/profile-image"
                  title="Profile Images"
                  subtitle="Add your logo, upload workspace photos, and choose your cover photo"
                />
                <SettingCard
                  size="col-md-6 mb-3"
                  link="/bussiness-hour"
                  title="Business Hours"
                  subtitle="Add your upload workplace photos, and choose your cover photo"
                />
                <SettingCard
                  size="col-md-6 mb-3"
                  title="Location"
                  subtitle="Let us know where you and whether or not you offer services onthe go"
                />
                <SettingCard
                  size="col-md-6 mb-3"
                  link="/health-&-safty"
                  title="Health & Safety Rules"
                  subtitle="Established rules to create a safe environment for you and your Customers"
                />
                <SettingCard
                  size="col-md-6 mb-3"
                  link="/amenities"
                  title="Amenities"
                  subtitle="Inform your customers about the amenities that make your business stand out."
                />
                <SettingCard
                  size="col-md-6 mb-3"
                  link="/service-setup"
                  title="Service Setup"
                  subtitle="Add service details, adjust settings and categorize them for easy discovery"
                />
                <SettingCard
                  size="col-md-6 mb-3"
                  link="/notification"
                  title="Personal Settings"
                  subtitle="Modify your language, set your notification preferences, and switch business profiles"
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
