 import { types } from "../types/types";
const intitalState = {
    clientUserProfile: {},
    getUserProfile:{},
    updateUserProfile:{},
};
export const clientSignupReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CLIENT_SIGNUP_DETAIL:
            return {
                ...state,
                clientUserProfile: action.payload,
            };
        case types.CHANGE_SINGUP_DATA:
            return {
                ...state,
                clientUserProfile: action.payload,
            };
        default:
            return { ...state };
    }
};


//get-user-profile-reducer

export const getUserProfileReducer= (state=intitalState,action)=>{
    switch(action.type){
        case types.CLIENT_USER_PROFILE:
            return{
                ...state,
                getUserProfile:action.payload,
            }
           default:
            return { ...state };
    }
}

//update-user-profile-reducer

export const updateUserProfileReducer = (state= intitalState,action)=>{
    switch(action.type){
        case types.CLIENT_UPDATE_USER_PROFILE:
            return{
                ...state,
                updateUserProfileReducer:action.payload,
            }
            default:
                return {...state};
    }
}