import React, { useState } from "react";
import MainButton from "../../../../components/buttons/MainButton";
import { Col, Row } from "react-bootstrap";
import MasterCardIcon from "../../../../assets/icons/mastercard.png";
import VisaCardIcon from "../../../../assets/icons/visa.png";
import InputNumberComponent from "../../../../components/input/InputNumberComponent";
import InputTextComponent from "../../../../components/input/InputTextComponent";
import CustomModal from "../../../../components/Modal/CustomModal";
import addpaymentCardIcon from "../../../../assets/icons/addpaymentcard.png"
export default function Payment() {
  const [showEditPayment, setShowEditPayment] = useState(false);
  const [payNowVisible, setPayNowVisible] = useState(false);
  return (
    <div className="px-4">
      <div className="d-flex justify-content-between bg-main p-3 text-white curve mb-3">
        <p className="text-lg m-0">Payment Settings</p>
        {/* <MainButton
          className="bg-white text-black"
          label="Add Payment"
          onClick={() => setShowEditPayment(true)}
        /> */}
      </div>
      <Row>
        <Col sm={12}>
          <div className="d-flex mb-3 w-100 align-items-center">
            <div className="bg-dark-gray-light text-main w-100 text-center rounded-3 p-3 "  onClick={() => setPayNowVisible(true)}>
              <div className="">
                <img src={addpaymentCardIcon} alt="" width={24} height={24} />
                <span className="text-black fw-bold ms-3">
                  Add Card
                </span>
              </div>
            </div>
            {/* <MainButton
                icon="pi pi-credit-card"
                iconPos="left"
                label="Add Card"
                className="bg-dark-gray-light text-main w-100"
                onClick={() => setPayNowVisible(true)}
              /> */}
          </div>

        </Col>
        {showEditPayment ? (
          <Col sm={12}>
            <div className="d-flex mb-3">
              <MainButton
                icon="pi pi-credit-card"
                iconPos="left"
                label="Add Card"
                className="bg-dark-gray-light text-main"
                onClick={() => setPayNowVisible(true)}
              />
            </div>
            <p className="fw-semibold text-sm">Saved Cards</p>
            <div className="d-flex justify-content-between bg-dark-gray-light mb-3 p-3 curve">
              <div className="d-flex align-items-center">
                <img src={MasterCardIcon} alt="" width={30} />
                <p className="ms-3 m-0">MasterCard ending in 4620</p>
              </div>
              <p className="m-0 text-gray">07/20</p>
              <i className="pi pi-trash text-red"></i>
            </div>
            <div className="d-flex justify-content-between bg-dark-gray-light mb-3 p-3 curve">
              <div className="d-flex align-items-center">
                <img src={VisaCardIcon} alt="" width={40} />
                <p className="ms-2 m-0">MasterCard ending in 4620</p>
              </div>
              <p className="m-0 text-gray">07/20</p>
              <i className="pi pi-trash text-red"></i>
            </div>
          </Col>
        ) : (
          <Col sm={12}>
            <div className="d-flex justify-content-between bg-dark-gray-light mb-3 p-3 curve">
              <div className="d-flex align-items-center">
                <img src={MasterCardIcon} alt="" width={30} />
                <p className="ms-3 m-0">MasterCard ending in 4620</p>
              </div>
              <p className="m-0 text-gray">07/20</p>
            </div>
            <div className="d-flex justify-content-between bg-dark-gray-light mb-3 p-3 curve">
              <div className="d-flex align-items-center">
                <img src={VisaCardIcon} alt="" width={40} />
                <p className="ms-2 m-0">MasterCard ending in 4620</p>
              </div>
              <p className="m-0 text-gray">07/20</p>
            </div>
          </Col>
        )}
      </Row>
      <CustomModal
        header={
          <>
            <div className="d-flex align-items-center ">
              <MainButton
                icon="pi pi-arrow-left"
                className="text-gray bg-white"
              />
              <h5 className="fw-bold text-dark ms-3 mb-0">
                Book an Appointment
              </h5>
            </div>
          </>
        }
        visible={payNowVisible}
        onHide={() => setPayNowVisible(false)}
      >
        <div className="text-center">
          <h5 className="fw-semibold text-black">Add your Payment Card</h5>
          <small className="text-gray">
            Add card to your InstaJamica Account and get to know mobile
            payments-fast, convenient and secure.
          </small>
        </div>
        <Row className="m-0 mb-3">
          <Col sm={12} className="p-0 ">
            <span className="p-input-icon-left login w-100">
              <i className="pi pi-credit-card" />
              <InputNumberComponent
                placeholder="Card Number"
                className="border w-100 rounded-top"
              />
            </span>
          </Col>
          <Col sm={6} className="p-0 border">
            <InputNumberComponent placeholder="MM/YY" />
          </Col>
          <Col sm={6} className="p-0 border">
            <InputNumberComponent placeholder="CVV" />
          </Col>
          <Col sm={12} className="p-0 border rounded-bottom">
            <InputTextComponent placeholder="Card holder name" />
          </Col>
        </Row>
        <MainButton
          label="Add Card"
          className="mb-3"
        />
        <p className="text-center">
          By saving the card you agree to
          <span className="text-main">InstaJamica Terms & Conditions</span>
        </p>
      </CustomModal>
    </div>
  );
}
