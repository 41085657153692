import ClientConstants from "../../services/ClientConstants";
import { clientApi,clientBussiness } from "../../services/clientApi";
import { authenticate } from "../../services/authService";
import { types } from "../types/types";
import { showToast } from "../action/toastAction";
import { showLoaderAction,hideLoaderAction } from "../action/loaderAction";


export const paymentAction = (data)=>async(dispatch)=>{
    dispatch(showLoaderAction())
    const res= await clientBussiness("post",ClientConstants.END_POINT.CREATECARDTOKEN,data);
    if(res.success){
        if(res.data){
            dispatch({
                type:types.CLIENT_CARD_TOKEN,
                payload:res.data
            });
        }
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "success", summary: res.message }));
    }else{
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}