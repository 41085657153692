import React from "react";
import Topbar from "./topbar";
import Sidebar from "./sidebar";
export default function Layout({ children }) {
  return (
    <div>
      <Topbar />
      <div className="d-flex" style={{height: "91vh"}}>
        <Sidebar/>
          {/* {location.pathname.includes("/provider/signup") ?"":location.pathname.includes("/")?"": <div style={{ width: "6%" }}><Sidebar/></div>} */}
        
        <div className="container-fluid bg-all h-100 overflow-scroll scrooling" style={{ width: "100%" }}>
          {children}
        </div>
      </div>
    </div>
  );
}
