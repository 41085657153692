import ClientConstants from "../../services/ClientConstants";
import PagesConstants from "../../services/PagesConstants";
import { clientApi, clientBussiness,pagesApi } from "../../services/clientApi";
import { authenticate } from "../../services/authService";
import { types } from "../types/types";
import { showToast } from "../action/toastAction";
import { hideLoaderAction,showLoaderAction } from "../action/loaderAction";

export const getMyBooking = (data)=>async(dispatch)=>{
    dispatch(showLoaderAction())
    const res =  await clientBussiness("post",ClientConstants.END_POINT.GETMYBOOKING,data);
    if(res.success){
        if(res.data){
            dispatch({
                type:types.GET_MY_BOOKING,
                payload:res.data
            });
        }
        dispatch(hideLoaderAction())
    }else{
        dispatch(hideLoaderAction())
        dispatch(showToast({severity:"error",summary:res.message}))
    }
}

export const getBookingAction=(id)=>async(dispatch)=>{
    dispatch(showLoaderAction())
    const res = await clientApi("get",`${ClientConstants.END_POINT.GETBOOKING}/${id}`);
    if(res.success){
        if(res.data){
            dispatch({
                type:types.GET_BOOKING,
                payload:res.data
            });
        }
        dispatch(hideLoaderAction())
    }else{
        dispatch(hideLoaderAction())
        dispatch(showToast({severity:"error",summary:res.message}))
    }
}

export const cancelBooking = (id)=>async(dispatch)=>{
    dispatch(showLoaderAction())
    const res = await clientBussiness("delete",`${ClientConstants.END_POINT.CANCELBOOKING}/${id}`);
    if(res.success){
        if(res.data){
            dispatch({
                type:types.CANCEL_BOOKING,
                payload:res.data
            });
        }
        dispatch(hideLoaderAction())
    }else{
        dispatch(hideLoaderAction())
        dispatch(showToast({severity:"error",summary:res.message}))
    }
}

export const addReviewAction = (data,id) =>async(dispatch)=>{
    dispatch(showLoaderAction())
    const res =  await clientApi("post",`${ClientConstants.END_POINT.ADDREVIEW}/${id}`,data);
    if(res.success){
        if(res.data){
            dispatch({
                type:types.ADD_REVIEW,
                payload:res.data
            });
        }
        dispatch(hideLoaderAction())
    }else{
        dispatch(hideLoaderAction())
        dispatch(showToast({severity:"error",summary:res.message}))
    }
}

export const termAndServicesAction =()=>async(dispatch)=>{
    dispatch(showLoaderAction())
    const res =  await pagesApi("get",PagesConstants.END_POINT.TERMSERVICES);
    if(res.success){
        if(res.data){
            dispatch({
                type:types.TERM_SERVICE,
                payload:res.data
            });
        }
        dispatch(hideLoaderAction())
    }else{
        dispatch(hideLoaderAction())
        dispatch(showToast({severity:"error",summary:res.message}))
    }
}

export const privacyPolicyAction = ()=>async(dispatch)=>{
    dispatch(showLoaderAction())
    const res = await pagesApi("get",PagesConstants.END_POINT.PRIVACYPOLICY);
    if(res.success){
        if(res.data){
            dispatch({
                type:types.PRIVACY_POLICY,
                payload:res.data
            });
        }
        dispatch(hideLoaderAction())
    }else{
        dispatch(hideLoaderAction())
        dispatch(showToast({severity:"error",summary:res.message}))
    }
}