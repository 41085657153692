import { api } from "../../services/api";
import Constants from "../../services/Constants";
import { types } from "../types/types";
import { showToast } from "./toastAction";
//import { hideLoaderAction,showLoaderAction } from "./loaderAction";


export const appointmentBookingAction = (data)=>async(dispatch)=>{
    const res = await api("post",Constants.END_POINT.APPOINTMENT,data);
    if(res.statusCode){
        if(res.data){
            dispatch({
                type:types.APPOINTMENT,
                payload:res.data
            })
        }
        dispatch(showToast({ severity: "success", summary: res.message }));
    }else{
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}