import Login from "./pages/login";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./styles.scss";
import { HashRouter, Routes } from "react-router-dom/dist";
import { Route } from "react-router-dom";
import ForgetPassword from "./pages/forgetpassword/ForgetPassword";
import OtpVerification from "./pages/forgetpassword/OtpVerification";
import CreateNewPassword from "./pages/forgetpassword/CreateNewPassword";
import SignupIndex from "./pages/signup";
import ProfileSetup from "./pages/profile-setup";
import AppointmentCalendar from "./views/appointment/AppointmentCalendar";
import Clients from "./views/client";
import ClientDetail from "./views/client/ClientDetail";
import Wallet from "./views/wallet/Wallet";
import MemberShip from "./views/membership/MemberShip";
import Frontdocument from "./views/verification/Frontdocument";
import Backdocument from "./views/verification/Backdocument";
import Selfie from "./views/verification/Selfie";
import Verified from "./views/verification/Verified";
import VerificationSuccessfull from "./views/verification/VerificationSuccessfull";
import Profile from "./views/profile/Profile";
import Portfolio from "./views/profile/Portfolio";
import Review from "./views/profile/Review";
import SettingInfo from "./views/setting";
import BussinessInfo from "./views/setting/BussinessInfo";
import ProfileImage from "./views/setting/ProfileImage";
import BussinessHour from "./views/setting/BussinessHour";
import Healthandsafty from "./views/setting/Healthandsafty";
import Amenities from "./views/setting/Amenities";
import ServiceSetup from "./views/setting/ServiceSetup";
import Notification from "./views/setting/Notification";

import ToastContainer from "./Container/ToastContainer";
import Loader from "./components/loader";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Home from "./views/home";
import BussinessHours from "./pages/profile-setup/BussinessHours";
import Workplace from "./pages/profile-setup/Workplace";
import AddingService from "./pages/profile-setup/AddingService";
import SetupDone from "./pages/profile-setup/SetupDone";
import OtpVerify from "./views/home/sigup/OtpVerify";
import CustomerProfile from "./views/home/pages/profile/CustomerProfile";
import AboutInsta from "./views/home/pages/aboutpage/AboutInsta";
import SinglePage from "./views/home/pages/singlepage/SinglePage";
import FaqInsta from "./views/home/pages/FAQPage/FaqInsta";
import PrivacyData from "./views/home/pages/privacyPage/PrivacyData";
import TermService from "./views/home/pages/TermService/TermService";
import Contact from "./views/home/pages/ContactPage/Contact";
import Private from "./utils/Private";
import Services from "./views/home/Services";
import ProviderPrivate from "./utils/ProviderProvate";
function App() {
  const [isloading, setisLoading] = useState(false)
  const data = useSelector((state) => state?.loader?.isLoading)
  useEffect(() => {
    setisLoading(data)
  }, [data])
  let token = localStorage.getItem('token');


  return (
    <div className="App">
      {isloading && <Loader />}
      <ToastContainer />
      <HashRouter>
        <Routes>
          {/* {!token && <Route path="/" element={<Home />}></Route>} */}
          <Route path="/login" element={<Login />} />
          <Route path="/provider/forget-password" element={<ForgetPassword />} />
          <Route path="/new-password" element={<CreateNewPassword />} />
          <Route path="/provider/signup" element={<SignupIndex />} />
          <Route path="/provider/bussiness" element={<ProviderPrivate />}>
            <Route path="/provider/bussiness" element={<BussinessHours />} />
          </Route>
          <Route path="/provider/profile-setup" element={<ProviderPrivate />}>
            <Route path="/provider/profile-setup" element={<ProfileSetup />} />
          </Route>
          <Route path="/provider/appointment" element={<ProviderPrivate />}>
            <Route path="/provider/appointment" element={<AppointmentCalendar />} />
          </Route>
          <Route path="/provider/client" element={<ProviderPrivate />}>
            <Route path="/provider/client" element={<Clients />} />
          </Route>
          <Route path="/provider/client/client-detail" element={<ProviderPrivate />}>
            <Route path="/provider/client/client-detail" element={<ClientDetail />} />
          </Route>
          <Route path="/wallet" element={<Private />}>
            <Route path="/wallet" element={<Wallet />} />
          </Route>
          <Route path="/membership" element={<Private />}>
            <Route path="/membership" element={<MemberShip />} />
          </Route>
          <Route path="/verification" element={<Private />}>
            <Route path="/verification" element={<Frontdocument />} />
          </Route>
          <Route path="/verification/backdocument" element={<Private />}>
            <Route path="/verification/backdocument" element={<Backdocument />} />
          </Route>
          <Route path="/verification/selfie" element={<Private />}>
            <Route path="/verification/selfie" element={<Selfie />} />
          </Route>
          <Route path="/verified-account" element={<Route />}>
            <Route path="/verified-account" element={<Verified />} />
          </Route>
          <Route path="/verified-successful" element={<Private />} >
            <Route path="/verified-successful" element={<VerificationSuccessfull />} />
          </Route>
          <Route path="/profile" element={<Private />} >
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route path="/portfolio" element={<Private />}>
            <Route path="/portfolio" element={<Portfolio />} />
          </Route>
          <Route path="/reviews" element={<Private />}>
            <Route path="/reviews" element={<Review />} />
          </Route>
          <Route path="/setting" element={<Private />}>
            <Route path="/setting" element={<SettingInfo />} />
          </Route>
          <Route path="/bussiness-info" element={<Private />}>
            <Route path="/bussiness-info" element={<BussinessInfo />} />
          </Route>
          <Route path="/profile-image" element={<Private />}>
            <Route path="/profile-image" element={<ProfileImage />} />
          </Route>
          <Route path="/bussiness-hour" element={<Private />}>
            <Route path="/bussiness-hour" element={<BussinessHour />} />
          </Route>
          <Route path="/health-&-safty" element={<Private />}>
            <Route path="/health-&-safty" element={<Healthandsafty />} />
          </Route>
          <Route path="/provider/add-workplace" element={<ProviderPrivate />}>
            <Route path="/provider/add-workplace" element={<Workplace />} />
          </Route>
          <Route path="/provider/add-service" element={<ProviderPrivate />}>
            <Route path="/provider/add-service" element={<AddingService />} />
          </Route>
          <Route path="/provider/setup-done" element={<ProviderPrivate />}>
            <Route path="/provider/setup-done" element={<SetupDone />} />
          </Route>
          <Route path="/amenities" element={<Private />}>
            <Route path="/amenities" element={<Amenities />} />
          </Route>
          <Route path="/service-setup" element={<Private />}>
            <Route path="/service-setup" element={<ServiceSetup />} />
          </Route>
          <Route path="/notification" element={<Private />}>
            <Route path="/notification" element={<Notification />} />
          </Route>
          <Route path="/" element={<Home />} />
          <Route path="/single-page/:id" element={<Private />}>
            <Route path="/single-page/:id" element={<SinglePage />} />
          </Route>
          <Route path="/single-page/:id" element={<Private />}>
            <Route path="/single-page/:id/?success= true" element={<SinglePage />} />
          </Route>
          <Route path="/customer-profile" element={<Private />} >
            <Route path="/customer-profile" element={<CustomerProfile />} />
          </Route>
          <Route path="/service" element={<Private />} >
            <Route path="/service" element={<Services />} />
          </Route>
          <Route path="/service/:category" element={<Private />}>
            <Route path="/service/:category" element={<Services />} />
          </Route>
          <Route path="/about" element={<AboutInsta />} />
          <Route path="/faq" element={<FaqInsta />} />
          <Route path="/privacy" element={<PrivacyData />} />
          <Route path="/services" element={<TermService />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
