import React, { useState } from "react";
import Layout from "../../layout";
import ArrowRight from "../../assets/icons/arrow-right.png";
import DropDownComponent from "../../components/input/DropDownComponent";
import { Avatar } from "primereact/avatar";
import ProfileImg from "../../assets/images/profileimg.png";
import StarIcon from "../../assets/icons/star.png";

export default function Review() {
  const [selectedCity, setSelectedCity] = useState(null);
  const cities = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];
  return (
    <div>
      <Layout>
        <div className="row p-5">
          <div className="col-md-10">
            <div className="d-flex align-items-center mb-3">
              <img src={ArrowRight} width={25} />
              <h4 className="text-main fw-bold mb-0 ms-3">Portfolio</h4>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h5 className="text-gray fw-bold">January </h5>
              <DropDownComponent
                onChange={(e) => setSelectedCity(e.value)}
                options={cities}
                optionLabel="name"
                placeholder="Filter"
                inputClass="bg-main filter-select"
              />
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="bg-white rounded p-3">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="d-flex align-items-center">
                      <Avatar image={ProfileImg} size="xlarge" shape="circle" />
                      <p className="fw-bold mb-0 ms-2">John</p>
                    </div>
                    <div className="rating d-flex align-items-center rounded-pill px-3 text-main p-1">
                      <img src={StarIcon} width={15} />
                      <p className="ms-2 mb-0">5</p>
                    </div>
                  </div>
                  <div className="text-start">
                    <h6 className="text-sm mb-2">
                      The people who work here are very friendly and
                      professional, I really like it! 👍👍
                    </h6>
                    <small className="text-gray">1 months ago</small>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="bg-white rounded p-3">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="d-flex align-items-center">
                      <Avatar image={ProfileImg} size="xlarge" shape="circle" />
                      <p className="fw-bold mb-0 ms-2">John</p>
                    </div>
                    <div className="rating d-flex align-items-center rounded-pill px-3 text-main p-1">
                      <img src={StarIcon} width={15} />
                      <p className="ms-2 mb-0">5</p>
                    </div>
                  </div>
                  <div className="text-start">
                    <h6 className="text-sm mb-2">
                      The people who work here are very friendly and
                      professional, I really like it! 👍👍
                    </h6>
                    <small className="text-gray">1 months ago</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
