import React, { useState } from "react";
import Layout from "../../layout";
import { Calendar } from "primereact/calendar";
import TableList from "../../components/table/table";
import TableData from "./WalletData";

export default function Wallet() {
  const [date, setDate] = useState(null);
  const { WalletTableHeading, WalletDetail } = TableData();
  return (
    <div>
      <Layout>
        <div className="p-5">
          <div className="d-flex mb-3">
            <h4 className="text-main fw-bold mb-0 ms-3">My Wallet</h4>
          </div>
          <div className="bg-white rounded p-4">
            <div className="row justify-content-between align-items-end mb-3">
              <div className="col-md-4">
                <div className="bg-main rounded p-4">
                  <h3 className="text-white">Total Earning</h3>
                  <h3 className="text-white fw-bold">$ 7,610.00</h3>
                </div>
              </div>
              <div className="col-md-3 text-end">
                <Calendar
                  placeholder="26-August-2022"
                  value={date}
                  onChange={(e) => setDate(e.value)}
                  showIcon
                />
              </div>
            </div>
            <TableList
              className="light_color-bg"
              tableHeading={WalletTableHeading}
              tableData={WalletDetail}
            />
          </div>
        </div>
      </Layout>
    </div>
  );
}
