import React, { useRef, useEffect, useState } from 'react'
import InputTextComponent from "../../components/input/InputTextComponent";
import { GoogleMap, useJsApiLoader, MarkerF, StandaloneSearchBox, DirectionsRenderer } from '@react-google-maps/api';
import { useSelector } from "react-redux";
import { createBusinessAction } from "../../redux/action/createBusinessAction";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import MainButton from "../../components/buttons/MainButton";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Geocode from 'react-geocode';
import { showToast } from "../../redux/action/toastAction";
const containerStyle = {
    width: '100%',
    height: '206px'
};
const API_Key = 'AIzaSyAaVnz03Xmd1cZGKPcVcKFHg0rAX4o_BAs';
const MapComponent = ({ islatitude, islongitude, searchBox, openSerach, setVisibleMap, visibleMap, originValue, form, setForm, setError, error, setAddress, address, setIsLadtitude, setIsLongitude, inputbox, business }) => {
    const inputRef = useRef();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [addressData, setAddressData] = useState({
        address: {
          formatted_address: '',
          latitude: '',
          longitude: '',
        },
      });
      const successPosition = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setAddressData({
          ...addressData,
          addressData: { ...addressData, latitude, longitude },
        });
    
        // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
        Geocode.setApiKey(API_Key);
    
        // set response language. Defaults to english.
        Geocode.setLanguage('en');
    
        // set response region. Its optional.
        // A Geocoding request with region=es (Spain) will return the Spanish city.
        Geocode.setRegion('es');
    
        // Enable or disable logs. Its optional.API_Key
        Geocode.enableDebug();
    
        // Get address from latitude & longitude.
        Geocode.fromLatLng(latitude, longitude).then(
          (response) => {
            const formatted_address = response.results[0].formatted_address;
            setAddressData({
              ...addressData,
              addressData: { ...addressData, formatted_address },
            });
            // this.props.handleSelect(this.state.address);
          },
          (error) => {
          }
        );
      };
      const deniedPosition = (error) => {
        alert(
          "You denied to location permission,\nAllow the permission from browser's settings or add you address manually."
        );
      };
      const getCurrentLoaction = () => {
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(
            (pos) => successPosition(pos),
            (err) => deniedPosition(err)
          );
        } else {
          alert("Your Browser doesn't support location service !");
        }
      };
      useEffect(() => {
        getCurrentLoaction();
      }, []);
    const data = useSelector((state) => state.clientgetBussinessById.getBussinessByIdProfile)
    const center = {
        lat: islatitude?.islatitude || islatitude,
        lng: islongitude?.islongitude || islongitude
    };
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAaVnz03Xmd1cZGKPcVcKFHg0rAX4o_BAs",
        libraries: ["places"]
    })
    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        let addressvar = {
            line1: "",  
            line2: "",
            city: "",
            zipcode: ""
        }
        if (place) {
            setIsLadtitude(place.geometry.location.lat())
            setIsLongitude(place.geometry.location.lng())
            setAddress(place.formatted_address)
            place.address_components.map(address => {
                if (address.types.includes("postal_code")) {
                    addressvar.zipcode = address.long_name
                }
            })
            addressvar.line1 = place.formatted_address
            setForm((prev) => {
                return {
                    ...prev,
                    address: addressvar
                }

            })
        }
    }
    useEffect(() => {
        if (data) {
            setIsLadtitude((prev) => {
                return {
                    ...prev,
                    islatitude: data.location?.coordinates[0]
                }
            })
            setIsLongitude((prev) => {
                return {
                    ...prev,
                    islongitude: data.location?.coordinates[1]
                }
            })
        }
    }, [data])
    const initialMarker =
    {
        position: {
            lat: parseFloat(islatitude),
            lng: parseFloat(islongitude),
        },
        label: { color: "red", text: "P1" },
        draggable: true,

    }
    useEffect(() => {
        if (searchBox === true) {
            setForm({
                ...form,
                location: {
                    coordinates: [islatitude,islongitude]
                }
            })
        }

    }, [islatitude, islongitude])
    const [activeInfoWindow, setActiveInfoWindow] = useState("");
    const [marker, setMarker] = useState(initialMarker);
    const [status, setStatus] = useState(null)
    const [currentLatitude, setCurrentLatitude] = useState(0)
    const [currentLongitude, setCurrentLogitude] = useState(0)
    const [directionResponse, setDirectionResponse] = useState(null)
    const destination = { lat: currentLatitude, lng: currentLongitude };
    const handleSubmit = () => {
        try {
            if (form.businessName === "" || form.mobile === null || form.category === "" || form.workType === "" || form.address?.line1 === "" || form.address?.zipcode === "") {
                setError({
                    ...error,
                    businessName: form.businessName === "" ? "Please Enter bussiness Name" : error.businessName,
                    mobile: form.mobile === null ? "Please Enter Mobile Number" : error.mobile,
                    category: form.category === "" ? "Please Enter Category" : error.category,
                    workType: form.workType === "" ? "Please Enter WorkType" : error.workType,
                })
                toast.error("Please Enter valid Address")
                return false;
            }
            dispatch(createBusinessAction(form, () => navigate("/provider/bussiness")))
        } catch (err) {
            dispatch(showToast({ severity: "error", content: "Something went wrong" }));
        }
    }
    const markerClicked = (marker, index) => {
        setActiveInfoWindow(index)
    }
    const calculate = async () => {
        if (destination !== "") {
            const direction = new window.google.maps.DirectionsService()
            const result = await direction.route({
                origin: center,
                destination: destination,
                travelMode: "DRIVING"
            })
            setDirectionResponse(result)
        }
    }
    const getAddress = (islatitude, islongitude) => {

        const geocoder = new window.google.maps.Geocoder();
        const latlng = new window.google.maps.LatLng(islatitude, islongitude);
        const request = {
            latLng: latlng
        }
        let addressvar = {
            line1: "",
            line2: "",
            city: "",
            zipcode: ""
        }
        return new Promise((resolve, reject) => {
            geocoder.geocode(request, results => {
                if (results?.length >= 0) {
                    results[0].address_components.map(address => {
                        if (address.types.includes("sublocality_level_1") || address.types.includes("sublocality_level_2") || address.types.includes("route")) {
                            addressvar.line1 = address.long_name
                        }
                        else if (address.types.includes("locality") || address.types.includes("sublocality_level_1")) {
                            addressvar.line2 = address.long_name
                        }
                        else if (address.types.includes("administrative_area_level_3") || address.types.includes("administrative_area_level_1")) {
                            addressvar.city = address.long_name
                        }
                        else if (address.types.includes("postal_code")) {
                            addressvar.zipcode = address.long_name
                        }
                    })
                } else {
                    dispatch(showToast({ severity: "error", summary: "Please Enter Valid Location", content: "Please Enter Valid Location" }));
                }

                setForm((prev) => {
                    return {
                        ...prev,
                        address: addressvar
                    }

                })
                results?.length ? resolve(results[0].formatted_address) : reject(null);
            });
        })
    }
    const markerDragEnd = async (event, index) => {
        setIsLadtitude(event.latLng.lat())
        setIsLongitude(event.latLng.lng())
        const address = await getAddress(islatitude, islongitude)
        setAddress(address)
    }
    // const getLocation = () => {
    //     if (!navigator.geolocation) {
    //         setStatus('Geolocation is not supported by your browser');
    //     } else {
    //         setStatus('Locating...');
    //         navigator.geolocation.getCurrentPosition(
    //             async (position) => {
    //                 setStatus(null);
    //                 setCurrentLatitude(position.coords.latitude);
    //                 setCurrentLogitude(position.coords.longitude);
    //             },
    //             () => {
    //                 setStatus('Unable to retrieve your location');
    //             }
    //         );
    //     }
    // };
    // useEffect(() => {
    //     getLocation()
    // }, [inputbox])
    return isLoaded ? (
        <>
            {searchBox || openSerach ?
                    <StandaloneSearchBox onLoad={ref => inputRef.current = ref} onPlacesChanged={handlePlaceChanged}>
                        <span className="p-input-icon-left w-100 login mb-2">
                            <i className="pi pi-search" />
                            <InputTextComponent placeholder="Search" inputClass="ps-3" value = {addressData?.addressData?.formatted_address} onChange = {(e)=>{
                                setAddressData({
                                    ...addressData,
                                    addressData:e.target.value
                                })
                            }} />
                        </span>
                    </StandaloneSearchBox> : <div id="map" className='position-relative'>{!originValue ? <div className='form-group'> <label htmlFor='ORIGIN'>Origin</label> <br />
                        <input id='ORIGIN' className='form-control' type='text' value={address ? address : ""} /></div> : ""}

                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={10}
                    >
                        <MarkerF position={center} draggable={marker.draggable}
                            onDragEnd={event => markerDragEnd(event)}
                            onClick={event => markerClicked(marker)} />
                        {directionResponse && <MarkerF position={destination} />}
                        {directionResponse && <DirectionsRenderer directions={directionResponse} />}                    <></>
                    </GoogleMap>
                    {inputbox ? <div className='input-map position-absolute bg-white'>
                        <div className='d-flex align-items-center'>
                            <div className='p-2 d-flex align-items-center border-end border-1'>
                                <img src={business?.business?.image} height={50} width={50} className='input-image-map' />
                                <div className='ms-2'>
                                    <h6 className='m-0'>
                                        {business?.business?.businessName}
                                    </h6>
                                    <small>{business?.business?.address?.zipCode} {business?.business?.address?.line1} {business?.business?.address?.line2} {business?.business?.address?.city}.</small>
                                </div>
                            </div>
                            <div className='p-2'>
                                <i className='pi pi-send fs-2' onClick={() => calculate()}></i>
                            </div>
                        </div>


                    </div> : ""}
                </div>
            }
            {!searchBox && !originValue && !openSerach ? <Link to="">
                <MainButton label="Continue" onClick={handleSubmit} />
            </Link> : ""}
        </>

    ) : <></>
}

export default MapComponent