import HairstyleImg from "../../../../assets/images/hairstyle.png";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchBusinessAction } from "../../../../redux/clientAction/allCategoriesAction";
const ProfileData = () => {

  const search = useSelector((state) => state.recentSearchBussiness.recentSearchBussinessProfile)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(searchBusinessAction({getLiked: true }))
  }, [])
  const favouriteData = search?.businesses
  return { favouriteData }
}
export default ProfileData;