import {api,formaDataapi,deleteapi} from "../../services/api";
import Constants from "../../services/Constants";
import { types } from "../types/types";
import { showToast } from "./toastAction";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";


export const getBusinessHours = () => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("get", Constants.END_POINT.GETBUSINESSHOUR);
    if (res.statusCode) {
        if (res.data) {
            dispatch({
                type: types.GET_BUSSINESS_HOURS,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    }else{
        dispatch(hideLoaderAction())
    }
}

export const createBusinessHours = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("post", Constants.END_POINT.CREATEBUSINESSHOURS, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CREATE_BUSINESS_HOURS,
                payload: res.data,
            });
        }
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "success", summary: res.message }));
        next();
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}

export const createworkplace = (data,next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await formaDataapi("post", Constants.END_POINT.WORKPLACE, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.WORKPLACE,
                payload: res.data,
            })
        }
        dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(hideLoaderAction())
        next();
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}
export const getServicesAction =()=>async(dispatch)=>{
    dispatch(showLoaderAction)
    const res = await api("get", Constants.END_POINT.SERVICES);
    if (res.statusCode) {
        if (res.data) {
            dispatch({
                type: types.GET_SERVICES,
                payload: res.data
            })
            dispatch(hideLoaderAction)
        }
    }else{
        dispatch(hideLoaderAction())
    }
}

export const createServiceAction =(data,next)=>async(dispatch)=>{
    dispatch(showLoaderAction)
    const res = await api("post",Constants.END_POINT.CREATESERVICES,data);
    if(res.success){
        if(res.data){
            dispatch({
                type: types.CREATE_SERVICE,
                payload: res.data,
            })
        }
        dispatch(hideLoaderAction)
        dispatch(showToast({ severity: "success", summary: res.message }));
        next();
        dispatch(getServicesAction())
    }else{
        dispatch(hideLoaderAction)
        dispatch(showToast({ severity: "error", summary: res.message })); 
    }
}

export const deleteServiceAction=(data) =>async(dispatch)=>{
    dispatch(showLoaderAction)
    const res =  await deleteapi("delete",Constants.END_POINT.DELETESERVICES + data);
    if (res.statusCode) {
        if (res.data) {
            dispatch({
                type: types.DELETE_SERVICES,
                payload: res.data
            })  
        }
        dispatch(hideLoaderAction)
        dispatch(getServicesAction())
    }
}