import React from "react";
import Layout from "../../Layout";
import TabConatiner from "../profile/TabContainer";
const TermService = () => {
    return (
        <div>
            <Layout>
                <TabConatiner/>
                <div className="banner p-5">
                    <h1 className="display-3 d-flex justify-content-center align-items-center h-100 fw-bold text-main">
                        Terms of Service
                    </h1>
                </div>
                <div className="p-5">
                    <p className="text-gray mb-5">The Services (as defined herein) and Terms of Use are provided by InstaJamaica. </p>
                    <p className="text-gray mb-5">ANY USERS WHO DO NOT AGREE WITH THESE TERMS OF USE SHOULD NOT USE THE SERVICES! THIS AGREEMENT CONTAINS, AMONG OTHER THINGS, AN ARBITRATION PROVISION CONTAINING A CLASS ACTION WAIVER.</p>
                    <h3 className="privacy-heading ">Definitions, Agreement to be Bound</h3>
                    <p className="text-gray mb-5">a. Definitions. </p>
                    <p className="text-gray mb-3">References to the "Booking Services" mean those appointment-scheduling services made available by us through the InstaJamaica Application. </p>
                    <p className="text-gray mb-3">References to the "InstaJamaica Application" mean any mobile, web, or voice software application related to the Services designed, developed, and/or made available by us and available through the iTunes and Google Play stores as well as other third-party services, including but not limited to Amazon Alexa and Google Home. </p>
                    <p className="text-gray mb-3">References to the "InstaJamaica Site" mean the Web site bearing the URL http://www.booksy.com and all affiliated websites owned and operated by InstaJamaica, our subsidiaries, and related companies. </p>
                    <p className="text-gray mb-3">References to the "Commercial Content" mean content which advertises or promotes a commercial product or service.</p>
                    <p className="text-gray mb-3">References to a "Customer" mean any person or entity who uses the InstaJamaica Application InstaJamaica Site, or a Third Party Platform as defined in Section 3.8 to schedule an appointment, manage appointments, browse health-, beauty and wellness-related content and services, and/or pay for services rendered, whether said individual registers directly for the use of the InstaJamaica Application or is added by an SMB through whom Customer obtains services. </p>
                    <p className="text-gray mb-3">References to "Dispute" mean any claim, conflict, controversy, or disagreement between the Parties arising out of, or related in any way to, these Terms (or any Terms, supplement or amendment contemplated by these Terms,) including, without limitation, any action in tort, contract, or otherwise, at equity or at law, or any alleged breach, including, without limitation, any matter with respect to the meaning, effect, validity, performance, termination, interpretation, or enforcement of these Terms or any terms contemplated by the Terms.
                    </p>
                    <p className="text-gray mb-3">References to "Material Breach" mean any breach of these Terms upon the occurrence of which a reasonable person in the position of the non-breaching party would wish to immediately terminate these Terms because of that breach. </p>
                    <p className="text-gray mb-3">References to the "Services" mean, collectively, any and all services offered by us, including but not limited to (1) the InstaJamaica Site, (2) the InstaJamaica Application, (3) the Payment Services, and (4) any other services or features made available by InstaJamaica through the InstaJamaica Site, Payment Services (as set forth in Section 4.5 hereof,) or InstaJamaica Application. </p>
                    <p className="text-gray mb-3">References to a "SMB" mean a small or medium business and seller of goods, services, or products who use the Services to allow Customers to book, manage, view, and cancel appointments.</p>
                    <p className="text-gray mb-3">References to the "Terms", "Terms of Use" and/or "Agreement," mean these terms as set forth herein. </p>
                    <p className="text-gray mb-3">References to "us," "we," "our," and/or "InstaJamaica," refer to InstaJamaica Inc, 515 North State Street, Suite 460, Chicago, IL 60654 and all its designated agents, employees, and subsidiaries within the United States of America for those customers based and/or residing in North and South America and Australia. For customers based and/or residing in the European Union, the terms "InstaJamaica" and "we", "us" and/or "our" refer to InstaJamaica International Sp. z.o.o., Prosta 67, 00-838 Warsaw, Poland, and all its designated agents, employees, and subsidiaries within the European Union. For the avoidance of doubt, the entity to which you provide implied consent through your use of the Services depends on your place of residence and location of use of such Services. </p>
                    <p className="text-gray mb-5">References to "you," and/or "User" mean the User of the Services, whether as a SMB or Customer.</p>
                    <p className="text-gray mb-5">b. Agreement to be Bound</p>
                    <p className="text-gray mb-3">The following Terms of Use, together with the relevant information set out on the Services, including any features and services available, are subject to the Terms of Use set forth below. Please read them carefully as any use of the Services, whether directly through us or via a Third-Party Platform, constitutes an agreement, without acceptance, to be bound thereby by the User. By accessing or using the Services or clicking “accept” or “agree” to this Agreement, you represent (1) that you are at least eighteen (18) years old (and in some jurisdictions twenty-one (21) years old), (2) are not prohibited by law from accessing or using the Services, (3) you have read, understand, and agree to be bound by this Agreement, and (4) you have authority to register a SMB and act on its behalf.</p>
                    <p className="text-gray mb-3">These Terms of Use are subject to the Privacy Policy, which also governs your use of the Services. In addition, each SMB and Third-Party Platform shall have his, her, or its own Terms of Use, which bind all Customer/SMB transactions.</p>
                    <p className="text-gray mb-3">The Parties acknowledge and agree that each SMB, Customer, Third-Party Platform, subsidiary, parent, and affiliate of us shall be a third-party beneficiary to the Terms of Use and that such other persons and/or companies shall be entitled to directly enforce and rely upon any provision of these Terms of Use which confers a benefit upon them. No other parties shall be third party beneficiaries to these Terms of Use.</p>
                </div>
            </Layout>
        </div>
    )
}
export default TermService