import React from "react";
import { Link } from "react-router-dom";
import MainButton from "../../components/buttons/MainButton";
import MapComponent from "./mapComponent";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/action/toastAction";
export default function Address({setSelectedStep,form,setForm,islongitude,setIsLongitude,setIsLadtitude,islatitude,setAddress,address}) {
    const dispatch =  useDispatch()
        const handleChooseLocation = ()=>{
            if( form.address.line1 ===""){
                dispatch(showToast({ severity: "error", summary: "Please Enter location", content: "please Enter location" }));
            }else{
                setSelectedStep("5")
            }
        }
    return (
        <div>
            <div className="d-block  mt-2 ">
                <i className="pi pi-arrow-left text-main fs-3 mb-2 cursor-pointer" onClick={()=>setSelectedStep("3")}></i>
                <h4 className="text-main fw-bold mb-0">Enter Your Address</h4>
                <small className="text-xs mb-3">Where can clients find you?</small>
                <div className="mb-4">
                <MapComponent setSelectedStep={setSelectedStep} setAddress={setAddress} address ={address} searchBox={true} setForm = {setForm} form = {form} setIsLadtitude = {setIsLadtitude} setIsLongitude = {setIsLongitude} islatitude = {islatitude} islongitude = {islongitude} ></MapComponent>
                </div>
                <Link to="">
                    <MainButton label="Continue" onClick={handleChooseLocation}/>
                </Link>
            </div>
        </div>
    );
}
