import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import Layout from "../../layout";
import { appointmentBookingAction } from "../../redux/action/appointmentAction";
import { useDispatch, useSelector } from "react-redux";
export default function AppointmentCalendar() {
  const dispatch = useDispatch();
  var date = new Date();
  const[showEvent,setShowEvent] = useState([])
  const [data, setData] = useState({
    bookingsFrom:"",
    bookingsTo: ""
  })

  const dataget =  useSelector((state) => state?.appointment?.appointmentProfile)
  useEffect(()=>{
    dispatch(appointmentBookingAction(data))
  },[])
 useEffect(()=>{
  let events =[]
  let color =  null
  dataget.map((item)=>{
    let start =  item.startTime
    let date = new Date();
    start = start.split(' ');
    start[0] = start[0].split(':');
    date.setHours(start[0][0]);
    date.setMinutes(start[0][1]);
    if (start[1] == 'PM') date.setHours(date.getHours() + 12);
    let inputTimeInMin = parseInt(item.services[0].durationTime);
    date.setMinutes(date.getMinutes() + inputTimeInMin);
    let endtime = date.getHours() + ':' + date.getMinutes();
    if(item.status == "CONFIRMED"){
         color =  "#62B1B7"
    }
   let event = {
    start:item.bookingDate.slice(0, 10),
    endTimeText:endtime,
    title:item.services[0].serviceName,
    username:item.user.firstName,
    timeText:item.startTime,
    durationTime:item.services[0].durationTime,
    color:color
  
   }
   events.push(event)
 
  })
  setShowEvent(events)
 },[dataget])
 const handleSelect = (arg)=>{
  setData((prev)=>{
    return{
      ...prev,
      bookingsFrom:arg.start,
      bookingsTo:arg.end
    }
  })
 }
  return (
    <div>
      <Layout>
        <div className="w-100 h-100 p-5">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
            initialView="dayGridMonth"
            weekends={false}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
            }}
            events={showEvent}
            eventContent={renderEventContent}
            height={"100vh"}
            eventClick={(arg) => {
              handleSelect(arg)
            }}
          />
        </div>
      </Layout>
    </div>
  );
}
function renderEventContent(eventInfo) {
  return (
    <div className="p-3">
    <p className="m-0">{eventInfo.event.extendedProps.username}</p>
    <p className="m-0">{eventInfo.event.extendedProps.durationTime}min    {eventInfo.event.title}</p>
    <p className="m-0"></p>
    <p className="m-0">{eventInfo.event.extendedProps.timeText} - {eventInfo.event.extendedProps.endTimeText}</p> 
    </div>
  )
}