import React from "react";
import LogoMain from "../../assets/icons/logo1.png";
import { Link } from "react-router-dom";
import MainButton from "../../components/buttons/MainButton";
import LayoutSidebar from "../../layout/LayoutSidebar";
import { Card } from "react-bootstrap";
import { ProgressBar } from "primereact/progressbar";
export default function SetupDone() {
  return (
    <div>
      <LayoutSidebar>
        <div className=" background-all">
          <div className="row p-0" style={{ height: "100vh" }}>
            <div className="col-md-6 m-0 img-signup h-100 position-relative p-0">
              <div className=" h-100 d-flex align-items-center justify-content-center">
                <h1 className="text-white position-relative fw-bold">
                  Setup Your Business <br />
                  With InstaJamaica
                </h1>
              </div>
            </div>
            <div className="col-md-6 m-0">
              <div className="d-flex align-items-center justify-content-center h-100">
                <Card className="border-0" style={{ width: "30rem" }}>
                  <ProgressBar value={20} style={{ height: "6px" }}></ProgressBar>
                  <Card.Body className="p-5">
                    <div>
                      <div className="d-block  mt-2 text-center">
                        <img src={LogoMain} width={150} className="mb-3" alt = "" />
                        <h4 className="text-main fw-bold mb-2">
                          Congratulations <br />
                          You’re All Set
                        </h4>
                        <p>
                          We’re excited to help you run your business, your way. Spread the word
                          and invite your existing customers to schedule their next appointment
                          using InstaJamaica.
                        </p>
                        <Link to="/provider/appointment">
                          <MainButton label="ok" />
                        </Link>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </LayoutSidebar>
    </div>

  );
}
