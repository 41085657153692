import React from "react";
import Layout from "../../layout";
import { Avatar } from "primereact/avatar";
import { TabView, TabPanel } from 'primereact/tabview';
import Appointments from "./Appointments";
import Payments from "./Payments";

export default function ClientDetail() {
  return (
    <div>
      <Layout>
        <div className="d-flex mb-3">
          <i className="pi pi-arrow-left text-main fs-5 mb-2 cursor-pointer"></i>
          <h4 className="text-main fw-bold mb-0 ms-3">Emma Watson</h4>
        </div>
        <div className="bg-white p-4 rounded">
          <div className="row">
            <div className="col-md-6">
              <div className="bg-main rounded p-4 d-flex align-items-center mb-3">
                <Avatar label="P" className="" size="xlarge" shape="circle" />
                <div className="mx-auto">
                  <h2 className="text-lg m-0 text-white">Emma Watson</h2>
                </div>
              </div>
              <TabView>
                <TabPanel header="APPOINTMENTS">
                  <Appointments />
                </TabPanel>
                <TabPanel header="PAYMENTS">
                 <Payments />
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
