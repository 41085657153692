import React,{useState} from "react";
import Layout from "../../layout";
import { Card } from "react-bootstrap";
import WelcomeIntro from "./WelcomeIntro";
import MainButton from "../../components/buttons/MainButton";
import CreateAccount from "./CreateAccount";
import OtpVerify from "./OtpVerify";
import { signupAction } from "../../redux/action/signupAction";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../redux/action/toastAction";
import { useNavigate } from "react-router-dom";
import LayoutSidebar from "../../layout/LayoutSidebar";
export default function SignupIndex() {

  const dispatch =  useDispatch();
  const navigate =  useNavigate();
  const [selectedStep, setSelectedStep] = useState("1");
  const [form, setForm] = useState({
    email: "",
    firstName: "",
    otp: "",
    lastName: "",
    confirmPassword: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const handleSubmit = async()=>{
    try{
      if(form.email === "" || form.firstName===""||form.lastName==="" || form.password === "" || form.confirmPassword ===""|| form.otp==="" ){
        setErrors({
          ...errors,
          email:form.email ===""?"Please Enter email": errors.email,
          firstName:form.firstName ===""?"Please Enter FirstName":errors.firstName,
          lastName:form.lastName ===""?"Please Enter LastName":errors.lastName,
          password:form.password ===""?"Please Enter Password":errors.password,
          confirmPassword:form.confirmPassword ===""?"Please Enter confirm Password":errors.confirmPassword,
          otp:form.otp === "" ?"Please Enter OTP":errors.otp,
        })
        return false;
      }
      dispatch(signupAction(form, () => navigate("/provider/profile-setup")))
     
    }catch(err){
      dispatch(showToast({ severity: "error" ,content: "Something went wrong" }));
    }
   
  }
  return (
    <div>
      <LayoutSidebar>
        <div className=" background-all">
          <div className="row p-0" style={{ height: "100vh" }}>
            <div className="col-md-6 m-0 img-signup h-100 position-relative p-0">
              <div className=" h-100 d-flex align-items-center justify-content-center">
                <h1 className="text-white position-relative fw-bold">
                  Setup Your Business <br />
                  With InstaJamaica
                </h1>
              </div>
            </div>
            <div className="col-md-6 m-0">
              <div className="d-flex align-items-center justify-content-center h-100">
                <Card className="border-0 p-3" style={{ width: "30rem" }}>
                  <Card.Body>
               {selectedStep === "1" && <WelcomeIntro  setSelectedStep = {setSelectedStep} />}   
                   {selectedStep === "2" && <CreateAccount setSelectedStep = {setSelectedStep} setForm ={setForm} form = {form} setErrors = {setErrors} errors = {errors}/>} 
                  {selectedStep === "3" && <OtpVerify  setSelectedStep = {setSelectedStep} setForm = {setForm} form = {form} setErrors = {setErrors} errors = {errors} handleSubmit={handleSubmit}/>}  
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </LayoutSidebar>
    </div>
  );
}
