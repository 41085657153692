import React, { useState } from "react";
import Layout from "../../layout";
import ArrowRight from "../../assets/icons/arrow-right.png";
import { InputSwitch } from "primereact/inputswitch";

export default function Notification() {
  const [checked, setChecked] = useState(false);
  return (
    <div>
      <Layout>
        <div className="p-5">
          <div className="d-flex align-items-center mb-3">
            <img src={ArrowRight} width={25} alt="" />
            <h4 className="text-main fw-bold mb-0 ms-3">
              Notification Settings
            </h4>
          </div>
          <div className="bg-white rounded p-5">
            <div className="d-flex justify-content-between">
              <p>Send me push notifications (this device)</p>
              <InputSwitch
                checked={checked}
                onChange={(e) => setChecked(e.value)}
              />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
