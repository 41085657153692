import { types } from "../types/types";

const intitalState ={
        paymentProfile:{}
}

export const paymentReducer =(state=intitalState,action)=>{
    switch(action.type){
        case types.CLIENT_CARD_TOKEN:
            return{
                ...state,
                paymentProfile:action.payload
            };
            default:
                return{...state}
    }
}