import HairstyleImg from "../../assets/images/hairstyle.png";
import DrayerImg from "../../assets/images/drayer.png";
import Img from "../../assets/images/img.png";
import Img1 from "../../assets/images/img1.png";
import Img2 from "../../assets/images/img2.png";
import HaloweenImg from "../../assets/images/haloween.png";
import NailfastImg from "../../assets/images/nailfast.png";
import HairstyleImg1 from "../../assets/images/hairstyleimg.png";
import { useSelector } from "react-redux";
import { getBussinessAction,allCategoriesAction } from "../../redux/clientAction/allCategoriesAction";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
const HomeContent = () => {
  const dispatch = useDispatch()
  let token = localStorage.getItem("token")
  const bussiness =  useSelector((state)=>state.clientgetBussiness.getBussinessProfile)
  useEffect(()=>{
    if(token){
      dispatch(getBussinessAction())
    }
  },[token])
  useEffect(()=>{
    if(token){
      dispatch(allCategoriesAction())
    }
  },[token])
  const categories =  useSelector((state)=>state.allCatergory.categoryProfile)
  const RecommendedContent = [
    {
      img: HairstyleImg,
      serviceheading: "Yoko @ Dapper Cuts",
      detail: "2267 Main st, Fort Myers, 33901",
      rating: "5.2",
      reviews: "302 reviews",
    },
    {
      img: DrayerImg,
      serviceheading: "Manny De Barber",
      detail: "10872 W Colonial, Ocoee, 34761",
      rating: "5.2",
      reviews: "302 reviews",
    },
    {
      img: HairstyleImg,
      serviceheading: "GetRightWithAnt💈",
      detail: "18901 sw 106th ave, Unit 105, Miami, 33157",
      rating: "5.2",
      reviews: "302 reviews",
    },
    {
      img: DrayerImg,
      serviceheading: "Boss lady lee",
      detail: "6028 merrill rd, Jacksonville, 32277",
      rating: "5.2",
      reviews: "302 reviews",
    },
    {
      img: HairstyleImg,
      serviceheading: "Yoko @ Dapper Cuts",
      detail: "2267 Main st, Fort Myers, 33901",
      rating: "5.2",
      reviews: "302 reviews",
    },
  ];


  const ChooseUsData = [
    {
      img: Img,
      heading: "Appointments done better",
      para: "Looking for your next appointment with a local barber, hair stylist, massage therapist or nail artist? Need appointment booking for a beard trim, an eyebrow wax, or a deep tissue massage?",
      para1:
        "Booksy is a free beauty scheduling app and makes appointments easy to find and book within seconds. No more phone tag. Book anytime, from anywhere, 24/7.",
      para2:
        "Discover top businesses in your area and book instantly with Booksy.",
    },
    {
      img: Img1,
      heading: "Something come up? We’ve got you.",
      para: "Download Booksy, a free online appointment booking app, and manage your appointments from anywhere. Reschedule or cancel without picking up the phone.",
      para1:
        "And because we know life gets busy, we’ll send you reminders. You’ll never forget or miss out on another appointment.",
      para2: "",
    },
    {
      img: Img2,
      heading: "Book with the best, near you",
      para: "Take a scroll around the block to see top health and beauty businesses on Booksy’s marketplace.",
      para1:
        " Check out their vibe from their business profile and hear what other people are saying with verified reviews. You can even look through their portfolio of work.",
      para2:
        "Save time and leave the stress to someone else. With Booksy, setting up your next beauty appointment is free and easy.",
    },
  ];

  const SpecialistData = [
    {
      path: "",
      heading: "Charlotte",
    },
    {
      path: "",
      heading: "Tampa",
    },
    {
      path: "",
      heading: "Miami",
    },
    {
      path: "",
      heading: "Jacksonville",
    },
    {
      path: "",
      heading: "Columbus",
    },
    {
      path: "",
      heading: "San Antonio",
    },
    {
      path: "",
      heading: "San Diego",
    },
    {
      path: "",
      heading: "Austin",
    },
    {
      path: "",
      heading: "Philadelphia",
    },
    {
      path: "",
      heading: "Los Angeles",
    },
    {
      path: "",
      heading: "Washington",
    },
    {
      path: "",
      heading: "San Jose",
    },
    {
      path: "",
      heading: "Chicago",
    },
    {
      path: "",
      heading: "Dallas",
    },
    {
      path: "",
      heading: "New York City",
    },
    {
      path: "",
      heading: "Houston",
    },
    {
      path: "",
      heading: "San Francisco",
    },
    {
      path: "",
      heading: "Orlando",
    },
    {
      path: "",
      heading: "Phoenix",
    },
    {
      path: "",
      heading: "Atlanta",
    },
  ];

  const RecommendContent = [
    {
      img: HaloweenImg,
      detail: "How to Get Halloween Makeup Off",
    },
    {
      img: NailfastImg,
      detail: "How to Dry Your Nails Fast?",
    },
    {
      img: HairstyleImg1,
      detail: "The Most Beautiful Braid Hairstyles Out There",
    },
    {
      img: HaloweenImg,
      detail: "6028 merrill rd, Jacksonville, 32277",
    },
    {
      img: NailfastImg,
      detail: "2267 Main st, Fort Myers, 33901",
    },
  ];

  return {
    RecommendedContent,
    categories,
    ChooseUsData,
    SpecialistData,
    RecommendContent,
    bussiness
  };
};
export default HomeContent;
