import React from "react";
import Layout from "../../layout";
import ArrowRight from "../../assets/icons/arrow-right.png";
import CheckboxComponent from "../../components/input/CheckboxComponent";
import MainButton from "../../components/buttons/MainButton";

export default function Healthandsafty() {
  return (
    <div>
      <Layout>
        <div className="p-5">
          <div className="d-flex align-items-center mb-3">
            <img src={ArrowRight} width={25} alt="" />
            <h4 className="text-main fw-bold mb-0 ms-3">
              Health & Safety Rules
            </h4>
          </div>
          <div className="bg-white rounded p-5">
            <h4 className="text-gray fw-bold mb-3">Select rules</h4>
            <div className="row">
              <CheckboxComponent
                label="No walk-ins"
                size="col-md-6 mb-3"
                checkBoxContainer="bg-light-gray p-3 rounded"
              />
              <CheckboxComponent
                label="No walk-ins"
                size="col-md-6 mb-3"
                checkBoxContainer="bg-light-gray p-3 rounded"
              />
              <CheckboxComponent
                label="Employees wear masks"
                size="col-md-6 mb-3"
                checkBoxContainer="bg-light-gray p-3 rounded"
              />
              <CheckboxComponent
                label="Employees wear masks"
                size="col-md-6 mb-3"
                checkBoxContainer="bg-light-gray p-3 rounded"
              />
              <CheckboxComponent
                label="Employees wear disposable gloves"
                size="col-md-6 mb-3"
                checkBoxContainer="bg-light-gray p-3 rounded"
              />
              <CheckboxComponent
                label="Employees wear disposable gloves"
                size="col-md-6 mb-3"
                checkBoxContainer="bg-light-gray p-3 rounded"
              />
              <CheckboxComponent
                label="Employees temperature checks"
                size="col-md-6 mb-3"
                checkBoxContainer="bg-light-gray p-3 rounded"
              />
              <CheckboxComponent
                label="Employees temperature checks"
                size="col-md-6 mb-3"
                checkBoxContainer="bg-light-gray p-3 rounded"
              />
              <CheckboxComponent
                label="Employees wear disposable gloves"
                size="col-md-6 mb-3"
                checkBoxContainer="bg-light-gray p-3 rounded"
              />
              <CheckboxComponent
                label="Employees wear disposable gloves"
                size="col-md-6 mb-3"
                checkBoxContainer="bg-light-gray p-3 rounded"
              />
            </div>
            <div className="d-flex">
              <MainButton label="Save" className="px-4" />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
