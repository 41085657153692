import React, { useState } from "react";
import Layout from "../../Layout";
import InputTextComponent from "../../../../components/input/InputTextComponent";
import DropDownComponent from "../../../../components/input/DropDownComponent";
import MainButton from "../../../../components/buttons/MainButton";
import TabConatiner from "../profile/TabContainer";
import { useNavigate } from "react-router-dom";
const Contact = () => {
    const navigate = useNavigate()
    const [form, setForm] = useState({
        name: "",
        email: "",
        mobile: "",
        message: ""
    })
    const [error, setError] = useState("")
    const handleSubmit = () => {
        if (form.name === "" || form.mobile === "") {
            setError({
                ...error,
                name: form.name === "" ? "Please Enter Name" : error.name,
                mobile: form.mobile === "" ? "Please Enter Mobile" : error.mobile,
            })
            return false;
        }
        navigate("/")
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        setError({
            ...error,
            [name]: null
        })
        setForm({
            ...form,
            [name]: value
        })
    }
    return (
        <div>
            <Layout>
                <TabConatiner />
                <div className="banner p-5">

                </div>

                <div class="card contact-card w-50 m-auto mb-5 shadow border-0">
                    <div class="card-body p-4">
                        <h1 class="card-title">Contact Us</h1>
                        <p className="card-p mb-5">Enim tempor eget pharetra facilisis sed maecenas adipiscing. Eu leo<br /> molestie vel, ornare non id blandit netus.</p>
                        <div className="mb-3">
                            <label className="mb-2">Name < cod className="text-danger">*</cod></label>
                            <InputTextComponent placeholder="Name" extraClassName="" name = "name" onChange = {handleChange} value = {form.name} />
                            {error.name && <span className="error">{error.name}</span>}
                        </div>
                        <div className="mb-3">
                            <label className="mb-2">Email </label>
                            <InputTextComponent placeholder="Email" extraClassName="" name = "email" onChange = {handleChange} value = {form.email} />
                        </div>
                        <div className="mb-3">
                            <label className="mb-2">Phone number < cod className="text-danger">*</cod></label>
                            <InputTextComponent placeholder="Phone number" extraClassName="" name= "mobile" onChange = {handleChange} value = {form.mobile}/>
                            {error.mobile && <span className="error">{error.mobile}</span>}<br />
                        </div>
                        <div className="mb-3">
                            <label className="mb-2">How did you find us? </label>
                            <InputTextComponent placeholder="Message" extraClassName="" name= "message" onChange = {handleChange} value = {form.message}/>
                            {/* <DropDownComponent disabled optionLabel="name" placeholder="How did you find us?" extraClassName="w-100" /> */}
                        </div>
                        <MainButton label="Send" extraClass="mb-3" onClick={handleSubmit} />
                        <div class="row px-4 justify-content-around">
                            <div class="col d-flex align-items-center">
                                <i className="pi pi-phone" style={{ fontSize: '1.75rem', color: "black" }}></i>
                                <p className="col-p text-sm m-0 ms-2">PHONE <br /> <span className="text-main">03 5432 1234</span></p>
                            </div>
                            <div class="col d-flex align-items-center">
                                <i className="pi pi-calculator" style={{ fontSize: '1.75rem', color: "black" }}></i>
                                <p className="col-p text-sm m-0 ms-2">FAX <br /> <span className="text-main">03 5432 1234</span></p>
                            </div>
                            <div class="col d-flex align-items-center">
                                <i className="pi pi-briefcase" style={{ fontSize: '1.75rem', color: "black" }}></i>
                                <p className="col-p text-sm m-0 ms-2">EMAIL <br /> <span className="text-main">info@instajamaica.com</span></p>
                            </div>
                        </div>
                    </div>


                </div>
            </Layout>
        </div>
    )
}
export default Contact