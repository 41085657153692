import React from "react";
import { TabView, TabPanel } from "primereact/tabview";

export default function Appointments() {
  return (
    <div>
      <TabView className="appointments-view">
        <TabPanel header={<h6 className="m-0">Upcoming (1)</h6>}>
          <div className="row mb-3">
            <div className="col-md-6">
              <p className="fw-bold m-0">Ac Repair</p>
              <p className="m-0">Deep clean Ac Service (window)</p>
            </div>
            <div className="col-md-6 text-end">
            <p className="fw-bold text-main m-0">$25.00</p>
              <p className="text-gray-light m-0">12:50PM - 1:10 PM</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <p className="fw-bold m-0">Ac Repair</p>
              <p className="m-0">Deep clean Ac Service (window)</p>
            </div>
            <div className="col-md-6 text-end">
            <p className="fw-bold text-main m-0">$25.00</p>
              <p className="text-gray-light m-0">12:50PM - 1:10 PM</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <p className="fw-bold m-0">Ac Repair</p>
              <p className="m-0">Deep clean Ac Service (window)</p>
            </div>
            <div className="col-md-6 text-end">
            <p className="fw-bold text-main m-0">$25.00</p>
              <p className="text-gray-light m-0">12:50PM - 1:10 PM</p>
            </div>
          </div>
        </TabPanel>
        <TabPanel header={<h6 className="m-0">Past (0)</h6>}>
        <div className="row mb-3">
            <div className="col-md-6">
              <p className="fw-bold m-0">Ac Repair</p>
              <p className="m-0">Deep clean Ac Service (window)</p>
            </div>
            <div className="col-md-6 text-end">
            <p className="fw-bold text-main m-0">$25.00</p>
              <p className="text-gray-light m-0">Completed</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <p className="fw-bold m-0">Ac Repair</p>
              <p className="m-0">Deep clean Ac Service (window)</p>
            </div>
            <div className="col-md-6 text-end">
            <p className="fw-bold text-main m-0">$25.00</p>
              <p className="text-gray-light m-0">Completed</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <p className="fw-bold m-0">Ac Repair</p>
              <p className="m-0">Deep clean Ac Service (window)</p>
            </div>
            <div className="col-md-6 text-end">
            <p className="fw-bold text-main m-0">$25.00</p>
              <p className="text-gray-light m-0">Completed</p>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}
