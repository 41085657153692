import React from "react";
import Layout from "../../layout";
import InputTextComponent from "../../components/input/InputTextComponent";
import { Link } from "react-router-dom";
import ClientData from "./ClientsData";

export default function Clients() {
  const { ClientName } = ClientData();
  return (
    <div className="">
      <Layout>
        <div className="p-5">
          <div className="row align-items-center mb-3">
            <div className="col-6">
              <h4 className="text-main fw-bold m-0">Clients</h4>
            </div>
            <div className="col-md-4">
              <span className="p-input-icon-left w-100 login">
                <i className="pi pi-search" />
                <InputTextComponent placeholder="Search clients" />
              </span>
            </div>
          </div>
          <div className="p-3 bg-gray">
            <div className="row">
              {ClientName.map((items, index) => {
                return (
                  <div className="col-md-4 mb-3">
                    <Link
                      to="/provider/client/client-detail"
                      className="text-decoration-none text-dark"
                    >
                      <div className="clients-card bg-light-main d-flex justify-content-between align-items-center p-3 rounded">
                        <h3 className="card-text mb-0">{items.name}</h3>
                        <i
                          className="pi pi-angle-right"
                          style={{ fontSize: "1rem" }}
                        ></i>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
