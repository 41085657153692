import React, { useState, useEffect } from "react";
import Logo from "../../../assets/icons/Logo.png";
import { Avatar } from "primereact/avatar";
// import { Button } from "react-bootstrap";
import { Button } from "primereact/button";
import { Calendar } from 'primereact/calendar';
import Login from "../Login/Login";
import MainButton from "../../../components/buttons/MainButton";
import LoginIcon from "../../../assets/icons/login.png";
import InputTextComponent from "../../../components/input/InputTextComponent";
import CustomModal from "../../../components/Modal/CustomModal";
import SendIcon from "../../../assets/icons/snedicon.png";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { searchBusinessAction, recentSearchAction, getBussinessByIdAction, deleteSearchAction, likeunlikeProtofiloAction, getBussinessAction } from "../../../redux/clientAction/allCategoriesAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUserProfileAction } from "../../../redux/clientAction/clientSignupAction";
import { Dialog } from "primereact/dialog";
import HomeContent from "../HomeContent";
import Geocode from 'react-geocode';
import MapComponent from "../../../pages/profile-setup/mapComponent";
export default function Header() {
  const { categories } = HomeContent();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const [currentLatitude, setCurrentLatitude] = useState(null);
  const [currentLogitude, setCurrentLogitude] = useState(null);
  const [loginVisible, setLoginVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [categoryName, setCategoryName] = useState("")
  const [serviceName, setServiceName] = useState("")
  const [addressName, setAddressName] = useState("")
  const [visibleMap, setVisibleMap] = useState(false)
  const [visible, setVisible] = useState(false)
  const [date, setDate] = useState(null);
  const [calendarVisible, setCalendarVisible] = useState(false)
  const [form, setForm] = useState()
  let token = localStorage.getItem("token")
  const API_Key = 'AIzaSyAaVnz03Xmd1cZGKPcVcKFHg0rAX4o_BAs';
  const [address, setAddress] = useState({
    address: {
      formatted_address: '',
      latitude: '',
      longitude: '',
    },
  });
  const successPosition = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setAddress({
      ...address,
      address: { ...address, latitude, longitude },
    });
    Geocode.setApiKey(API_Key);
    Geocode.setLanguage('en');
    Geocode.setRegion('es');
    Geocode.enableDebug();
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        const formatted_address = response.results[6].formatted_address;
        setAddress({
          ...address,
          address: { ...address, formatted_address },
        });
      },
      (error) => {
      }
    );
  };
  const deniedPosition = (error) => {
    alert(
      "You denied to location permission,\nAllow the permission from browser's settings or add you address manually."
    );
  };
  const getCurrentLoaction = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (pos) => successPosition(pos),
        (err) => deniedPosition(err)
      );
    } else {
      alert("Your Browser doesn't support location service !");
    }
  };
  useEffect(() => {
    getCurrentLoaction();
  }, []);
  useEffect(() => {
    if (token) {
      setLoginVisible(false)
    }
  }, [token])
  useEffect(() => {
    if (token) {
      dispatch(recentSearchAction())
    }
  }, [token])
  useEffect(() => {
    var field = address?.address?.formatted_address
    // var field = address?.address?.formatted_address?.split(' ')[0]
    setAddressName(field)
  }, [address])

  const name = useParams()
  useEffect(() => {
    if (name.category) {
      setServiceName(name.category)
    }
  }, [name.category])


  const handleSubmit = () => {
    if (token) {
      dispatch(searchBusinessAction({ search: categoryName }))
    }

  }
  useEffect(() => {
    handleSubmit()
  }, [categoryName, token])
  const data = useSelector((state) => state.recentSearch.recentSearchProfile)
  const search = useSelector((state) => state.recentSearchBussiness.recentSearchBussinessProfile)
  const handleSubmitById = (id) => {
    dispatch(getBussinessByIdAction(id), navigate(`/single-page/${id}`))
  }
  const handleDelete = (id) => {
    dispatch(deleteSearchAction(id)).then(() => {
      dispatch(recentSearchAction())
    })
  }
  useEffect(() => {
    if (searchVisible === false) {
      setCategoryName("")
    }
  }, [searchVisible])
  const handleLikeUnLike = (id) => {
    dispatch(likeunlikeProtofiloAction(id)).then(() => {
      dispatch(searchBusinessAction({ search: categoryName }))
      dispatch(getBussinessAction())
    })
  }
  useEffect(() => {
    if (token) {
      dispatch(getUserProfileAction())
    }

  }, [token])
  const userData = useSelector((state) => state.getUserData.getUserProfile)
  const handleSubmits = () => {
    setVisible(false)
    if (serviceName !== "") {
      dispatch(searchBusinessAction({ search: serviceName }, navigate(`/service/${serviceName}`)))
    } else {
      dispatch(searchBusinessAction({ search: serviceName }, navigate("/service")))
    }
  }
  return (
    <div>
      {/* header */}
      <nav className="navbars">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center p-4">
            <div className=" mx-1">
              <img src={Logo} alt="logo" className="img-fluid" onClick={() => navigate("/")} />
            </div>
            {location?.pathname?.includes("/single-page") || location?.pathname?.includes("/customer-profile") || location?.pathname?.includes("/about") || location?.pathname?.includes("/faq") || location?.pathname?.includes("/privacy") || location?.pathname?.includes("/service") || location?.pathname?.includes("/contact") ?
              <div className="d-flex bg-light-color curve overflow-hidden">
                <div className="p-inputgroup header-search border-end align-items-center px-2">
                  <i className="pi pi-search"></i>
                  <InputTextComponent
                    placeholder="What are you looking for?"
                    className="bg-white"
                    value={serviceName}
                    // onChange = {(e)=>handleChange(e)}
                    onClick={() => setVisible(true)}
                  />
                </div>
                <div className="p-inputgroup border-end header-search">
                  <InputTextComponent placeholder="Where?" className="bg-white " onClick={() => setVisibleMap(true)} />
                </div>
                {/* <div className="p-inputgroup header-search">
                  <InputTextComponent placeholder="When?" className="bg-white " onClick={() => setCalendarVisible(true)} />
                </div> */}
              </div>
              : ""}

            <div className="d-flex align-items-center">

              {!token ? "" :
                <>
                  {location?.pathname?.includes("/single-page") || location?.pathname?.includes("/customer-profile") || location?.pathname?.includes("/about") || location?.pathname?.includes("/faq") || location?.pathname?.includes("/privacy") || location?.pathname?.includes("/service") || location?.pathname?.includes("/contact") ? "" : <i
                    className="pi pi-search text-main cursor-pointer"
                    onClick={() => setSearchVisible(true)}
                  ></i>}

                  <div className="d-flex align-items-center ms-3">
                    <Avatar image={`https://instajamaica-api.appdeft.biz/${userData.image}`} size="large" shape="circle" />
                    <div className="ms-2 me-2">
                      <p className="fw-semibold text-main m-0">{userData?.firstName}</p>
                    </div>
                    <i className="pi pi-chevron-down text-main" style={{ "margin-right": "15px" }} onClick={() => navigate("/customer-profile")}></i>

                  </div>
                </>
              }
              {token ? ""
                :
                <Button
                  className="bg-transparent border-0 ms-2"
                  onClick={() => setLoginVisible(true)}
                >
                  <img src={LoginIcon} alt="" width={20} />
                  <small className="ms-2 text-main text-sm fw-semibold">
                    Log In/Sign Up
                  </small>
                </Button>
              }
              <MainButton label="List Your Business" className="" onClick={() => navigate("/login")} />
            </div>

          </div>
        </div>

      </nav>

      <CustomModal
        header={<img src={Logo} alt="logo" className="mx-auto d-block" />}
        visible={loginVisible}
        onHide={() => setLoginVisible(false)}
      >
        <Login />
      </CustomModal>
      <CustomModal
        visible={searchVisible}
        onHide={() => setSearchVisible(false)}
        className="searchbox"
      >
        <div className="bg-light-color d-flex align-items-center px-3">
          <i className="pi pi-arrow-left" onClick={() => setSearchVisible(false)}></i>
          <InputTextComponent
            placeholder="What are you looking for?"
            className="bg-transparent"
            extraClassName="w-100"
            name="search"
            onChange={e => setCategoryName(e.target.value)}
          />
        </div>
        <div className="p-3">
          <p className="fw-semibold">Popular services</p>
          <div className="d-flex flex-wrap">
            {categoryName ? search?.businesses?.length > 0 && search?.businesses?.map((item) => {
              return (
                <div className="border-1 surface-border curve overflow-hidden m-2 bg-white p-2" >
                  <span className="text-dark text-decoration-none row"  >
                    <div className="h-full col-md-4" onClick={() => handleSubmitById(item._id)}>
                      <img
                        src={`https://instajamaica-api.appdeft.biz//${item.image?.workPlaceURL}`}
                        alt=""
                        className="w-100 h-100 cover-img shadow-2"
                      />
                    </div>
                    <div className="col-md-8">
                      <h5 className="mb-1 fw-semibold" onClick={() => handleSubmitById(item._id)}>
                        {item.businessName}
                      </h5>
                      <p className="mt-0 mb-3 text-md" onClick={() => handleSubmitById(item._id)}>{item.address?.line1},{item.address?.city},{item.address?.zipCode}</p>

                      <div className="d-flex flex-wrap justify-content-between gap-1">
                        <p onClick={() => handleSubmitById(item._id)}>
                          {item?.rating} <i className="pi pi-star-fill text-golden"></i>{" "}
                          <small>/{item?.reviewCount} reviews</small>
                        </p>
                        <Button
                          onClick={() => handleLikeUnLike(item._id)}
                          icon={item.isLiked === 1 ? "pi pi-heart-fill" : "pi pi-heart"}
                          className={item.isLiked === 1 ? `bg-transparent border-0 text-dark liked` : `bg-transparent border-0 text-dark unliked`}
                        />
                      </div>
                    </div>
                  </span>
                </div>
              )
            }) :
              <>
                {data.length > 0 && data?.map((item) => {
                  return (
                    <>
                      <div className="search-close-icon">

                        <div className="custom-position">
                          <p className="shadow px-3 py-2 rounded ms-2 " onClick={() => setCategoryName(item.keyword)}>{item.keyword}
                          </p>
                          <div className="custom-position-child">
                            <i className="pi pi-times" style={{ fontSize: "0.7rem", cursor: "pointer" }} onClick={() => handleDelete(item._id)}></i>
                          </div>

                        </div>


                      </div>

                    </>
                  )
                })}
              </>
            }

          </div>

          {/* <div className="d-flex justify-content-center">
            <MainButton label="Search" className="px-5" onClick={()=>handleSubmit()} />
          </div> */}
        </div>
      </CustomModal>
      <Dialog visible={visible} modal={false} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
        <div className="bg-light-color d-flex align-items-center px-3">
          <i className="pi pi-arrow-left" onClick={() => setVisible(false)}></i>
          <InputTextComponent
            placeholder="What are you looking for?"
            className="bg-transparent"
            extraClassName="w-100"
            name="search"
            value={serviceName}
            onChange={e => setServiceName(e.target.value)}
          />
        </div>
        <div className="p-3">
          <p className="fw-semibold">Popular services</p>
          <div className="d-flex flex-wrap">
            {categories?.length > 0 && categories?.map((item) => {
              return (
                <div className="custom-position">
                  <p className="shadow px-3 py-2 rounded ms-2" onClick={() => setServiceName(item.categoryName)}>{item.categoryName}
                  </p>
                </div>
              )
            })}
          </div>
          <div className="mt-5 d-flex justify-content-center">
            <MainButton label="Search" className="px-5" onClick={() => handleSubmits()} />
          </div>
        </div>
      </Dialog>
      <div className="card flex justify-content-center">
        {visibleMap === true ?
          <div className="position-fixed banner-searchbox">
            <div className="closebtn" onClick={() => setVisibleMap(false)}>&times;</div>
            <MapComponent islatitude={currentLatitude} islongitude={currentLogitude} openSerach={true} setIsLadtitude={setCurrentLatitude} setIsLongitude={setCurrentLogitude} form={form} setForm={setForm} address={address} setAddress={setAddress} />
          </div> : null
        }
      </div>
      <Dialog visible={calendarVisible} modal={false} style={{ width: "50vw" }} onHide={() => setCalendarVisible(false)}>
        <div className="bg-light-color d-flex align-items-center px-3">
          <Calendar value={date} onChange={(e) => setDate(e.value)} />
        </div>

      </Dialog>
    </div>
  );
}
