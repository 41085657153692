import React, { useState, useEffect } from "react";
import InputTextComponent from "../../components/input/InputTextComponent";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import SendIcon from "../../assets/icons/snedicon.png"
import { useSelector, useDispatch } from "react-redux";
import HomeContent from "./HomeContent";
import MainButton from "../../components/buttons/MainButton";
import { searchBusinessAction } from "../../redux/clientAction/allCategoriesAction";
import MapComponent from "../../pages/profile-setup/mapComponent";
import { useNavigate } from "react-router-dom";
export default function Banner() {
  const { categories } = HomeContent();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [visibleMap, setVisibleMap] = useState(false)
  const [categoryName, setCategoryName] = useState("")
  const [currentLatitude, setCurrentLatitude] = useState(null);
  const [currentLogitude, setCurrentLogitude] = useState(null);
  const [serviceIndex, setServiceIndex] = useState(null)
  const [address, setAddress] = useState()
  const [form, setForm] = useState()
  const [currentPosition, setCurrentPosition] = useState(null)
  const handleSubmit = () => {
    if (categoryName !== "") {
      dispatch(searchBusinessAction({ search: categoryName }, navigate(`/service/${categoryName}`)))
    }
  }


  return (
    <div className="home-banner">
      <div className="container-fluid h-100">
      <div className="row justify-content-center h-100">
        <div className="col-lg-5 d-flex flex-column justify-content-center align-items-center h-100">
          <h1 className="display-3 text-white fw-bold">
            Get Quality Services <br />
            at your Doorstep!
          </h1>
          <p className="text-white text-lg">
            Discover and book beauty & wellness professionals near you
          </p>
          <div className="d-flex bg-light-color curve overflow-hidden w-100">
            <div className="p-inputgroup border-end w-50">
              <Button icon="pi pi-search" className="text-main bg-light-color border-0" />
              <InputTextComponent placeholder="Book your services..." className="bg-white" onClick={() => setVisible(true)} />
            </div>
            <div className="p-inputgroup justify-content-between w-50">
              <Button icon="pi pi-map-marker" className="text-main bg-light-color border-0" />
              <InputTextComponent placeholder="Where?" className="bg-white" onClick={() => setVisibleMap(true)} value={currentPosition} />
              <Button className="text-main bg-light-color border-0" >
                <img src={SendIcon} alt="" width={15} />
              </Button>
            </div>
          </div>
        </div>
      </div>
      </div>

      <div className="card flex justify-content-center book_service ">
        <Dialog visible={visible} modal={false} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
          <div className="bg-light-color d-flex align-items-center px-3">
            <i className="pi pi-arrow-left" onClick={() => setVisible(false)}></i>
            <InputTextComponent
              placeholder="What are you looking for?"
              className="bg-transparent"
              extraClassName="w-100"
              name="search"
              onChange={e => setCategoryName(e.target.value)}
            />
          </div>
          <div className="p-3">
            <p className="fw-semibold">Popular services</p>
            <div className="d-flex flex-wrap">
              {categories?.length > 0 && categories?.map((item, index) => {
                return (
                  <div className="custom-position">
                    <p className={serviceIndex === index ? "shadow px-3 py-2 rounded ms-2 customserviceClass" : "shadow px-3 py-2 rounded ms-2"} onClick={() => { setCategoryName(item.categoryName); setServiceIndex(index) }}>{item.categoryName}
                    </p>
                  </div>
                )
              })}
            </div>
            <div className="mt-5 d-flex justify-content-center">
              <MainButton label="Search" className="px-5" onClick={() => { handleSubmit() }} />
            </div>
          </div>
        </Dialog>
      </div>
      <div className="card flex justify-content-center">
        {/* {visibleMap === true ?
          <div className="position-fixed banner-searchbox">
            <div className="closebtn" onClick={() => setVisibleMap(false)}>&times;</div>
            <MapComponent islatitude={currentLatitude} islongitude={currentLogitude} openSerach={true} setIsLadtitude={setCurrentLatitude} setIsLongitude={setCurrentLogitude} form={form} setForm={setForm} address={address} setAddress={setAddress} />
          </div> : null
        } */}


      </div>
    </div>
  );
}
