import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Carousel } from "primereact/carousel";
import ServiceBannerContent from "./ServiceBannerContent";
import img1 from "../../../../assets/images/hairstyle.png";
import DrayerImg from "../../../../assets/images/drayer.png";
import PortfolioImg from "../../../../assets/images/prtfolio.png";
import InputTextComponent from "../../../../components/input/InputTextComponent";
import { Avatar } from "primereact/avatar";
import MainButton from "../../../../components/buttons/MainButton";
import SheildImg from "../../../../assets/icons/sheild.png";
import CustomerLogo from "../../../../assets/icons/customerlogo.png";
import LocationIcon from "../../../../assets/icons/loaction.png";
import phoneIcon from "../../../../assets/icons/phonegreen.png";
import { ProgressBar } from "primereact/progressbar";
import ClientImg from "../../../../assets/images/profileimg.png";
import { Paginator } from "primereact/paginator";
import ThumbImg from "../../../../assets/icons/thumb.png";
import StarIcon from "../../../../assets/icons/star.png";
import { Button } from "primereact/button";
import CustomModal from "../../../../components/Modal/CustomModal";
import { Calendar } from "primereact/calendar";
import CloseIcon from "../../../../assets/icons/close.png";
import AddIcon from "../../../../assets/icons/addicon.png";
import NotesIcon from "../../../../assets/icons/notesicon.png";
import HomeIcon from "../../../../assets/icons/home.png";
import EditIcon from "../../../../assets/icons/editgreen.png";
import { InputText } from "primereact/inputtext";
import InputTextAreaComponent from "../../../../components/input/InputTextAreaComponent";
import { RadioButton } from "primereact/radiobutton";
import InputNumberComponent from "../../../../components/input/InputNumberComponent";
import Logo from "../../../../assets/icons/Logo.png";
import ConfirmIcon from "../../../../assets/icons/confirm.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getBussinessReviewsByIdAction,
  getSlotsById,
  getBussinessByIdAction,
  addReportAction
} from "../../../../redux/clientAction/allCategoriesAction";
import MapComponent from "../../../../pages/profile-setup/mapComponent";
import { likeunlikeProtofiloAction } from "../../../../redux/clientAction/allCategoriesAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Rating } from "primereact/rating";
import DropDownComponent from "../../../../components/input/DropDownComponent";
import { putAddressAction } from "../../../../redux/clientAction/addressAction";
import { bookSlotsAction } from "../../../../redux/clientAction/allCategoriesAction";
import Loader from "../../../../components/loader"
export default function Artist() {
  const { day, dateMonth, payment, setPayment, service, timeData, reviewsData, reportsArray, slotsData, date, setslotProgress, slotProgress, addressForm, setAddressForm, handleAddress, error, setError, getAddress, viewAddress, bookSlot, setBookSlot, viewBookingData } = ServiceBannerContent();
  const navigate = useNavigate()
  const location = useLocation()
  const [bookingVisible, setBookingVisible] = useState(false);
  const [anotherServiceVisible, setAnotherServiceVisible] = useState(false);
  const [addnoteToggle, setAddNoteToggle] = useState(false);
  const [payNowVisible, setPayNowVisible] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [Portfolio, setPortfolio] = useState([]);
  const [form, setForm] = useState();
  const [islongitude, setIsLongitude] = useState("");
  const [islatitude, setIsLadtitude] = useState("");
  const [appointmentConfirmVisible, setAppointmentConfirmVisible] =
    useState(false);
  const [address, setAddress] = useState(false);
  const [addParticularAddress, setAddParticlularAddress] = useState(false);
  const [AddressData, setAddressData] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [services, setServices] = useState(service?.services);
  const [selectAddressId, setSelectAddressId] = useState();
  const [dataBooking, setBookingData] = useState({
    bookingDate: "",
    services: [],
  })
  const [report, setReport] = useState({
    reportTitle: "",
    reportComment: ""
  })
  const [secondTime, setSecondTime] = useState(null)
  const [firstTime, setFirstTime] = useState(null)
  const [prebookfees, setPreBookFees] = useState(0)
  const [showBookingData, setShowBookingData] = useState([])
  const [totalPrice, setTotalPrice] = useState(0)
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [addReports, setAddReports] = useState(false)
  const dispatch = useDispatch();
  useEffect(() => {
    let images = [];
    service.workplace?.map((item, index) => {
      if (index <= 2) {
        images.push(item);
      }
    });
    setPortfolio(images);
  }, [service]);
  useEffect(() => {
    setIsLadtitude((prev) => {
      return {
        ...prev,
        islatitude: service?.location?.coordinates[0],
      };
    });
    setIsLongitude((prev) => {
      return {
        ...prev,
        islongitude: service?.location?.coordinates[1],
      };
    });
  }, [service]);
  const { id } = useParams();
  useEffect(() => {
    dispatch(getBussinessByIdAction(id));
  }, [id]);
  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "991px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  useEffect(() => {
    dispatch(getBussinessReviewsByIdAction(id));
  }, [service]);

  const reviews = useSelector(
    (state) => state.clientgetBusinessReviewsById.getBusinessReviewsByIdProfile
  );
  const filterServices = service?.services?.filter((item) => {
    if (
      dataBooking.services?.findIndex((childitem) => {
        return childitem === item._id;
      }) === -1
    ) {
      return item;
    }
  });
  const handlePortfolio = () => {
    setPortfolio(service.workplace);
  };
  const handleSocial = (social) => {
    window.open(social, "_blank");
  };
  const handleSearch = (e) => {
    if (searchVal === "") {
      setServices(service?.services);
    } else {
      const filterBySearch = service?.services?.filter((item) => {
        if (item.serviceName.toLowerCase().includes(searchVal.toLowerCase())) {
          return item.serviceName;
        }
      });
      setServices(filterBySearch);
    }
  };
  useEffect(() => {
    handleSearch();
  }, [searchVal]);
  let dates = new Date(dataBooking.bookingDate);
  let years = dates.getFullYear();
  let months = dates.getMonth() + 1;
  let dts = dates.getDate();
  if (dts < 10) {
    dts = "0" + dts;
  }
  if (months < 10) {
    months = "0" + months;
  }
  let dobs = dts + "-" + months + "-" + years;
  const handleSlots = (serviceId, serviceName) => {
    let array = [];
    setBookingVisible(true);
    array.push(serviceId);
    setBookingData((prev) => {
      return {
        ...prev,
        services: [...dataBooking?.services, ...array],
      };
    });
    setShowBookingData([...showBookingData, serviceName]);
  };

  useEffect(() => {
    let payload = {
      bookingDate: dobs,
      services: dataBooking.services,
    };
    if (bookingVisible === true) {
      dispatch(getSlotsById(id, payload));
    }
  }, [dobs]);
  const handleEditAddress = () => {
    setAddParticlularAddress(true);
    setAddressData(false);
  };
  const handleAddReport = (id) => {
    dispatch(addReportAction(id, report))
    setAddReports(false)
  }
  const handleUpdateAddress = () => {
    if (addressForm.line1 === "" || addressForm.city === "") {
      setError({
        ...error,
        line1: addressForm.line1 === "" ? "Please Enter Address" : error.line1,
        city: addressForm.city === "" ? "Please Enter City" : error.city,
      });
      setAddress(true);
      setAddressData(false);
      return false;
    }
    if (Object.keys(viewAddress).length === 0) {
      dispatch(putAddressAction(selectAddressId, addressForm));
    } else {
      dispatch(putAddressAction(viewAddress._id, addressForm));
    }
  };
  const handleAddEditAddress = () => {
    if (addressForm.line1 === "" || addressForm.city === "") {
      setError({
        ...error,
        line1: addressForm.line1 === "" ? "Please Enter Address" : error.line1,
        city: addressForm.city === "" ? "Please Enter City" : error.city,
      });
      setAddParticlularAddress(true);
      return false;
    }
    if (Object.keys(viewAddress).length === 0) {
      handleAddress();
    } else {
      handleUpdateAddress();
    }
  };
  const handleLikeUnLike = (id) => {
    dispatch(likeunlikeProtofiloAction(id)).then(() => {
      dispatch(getBussinessByIdAction(id))
    })
  }
  const handleBookSlots = () => {
    if (
      bookSlot.bookingDate === "NaN-NaN-NaN" ||
      bookSlot.services?.length === 0 ||
      bookSlot.startTime === undefined ||
      bookSlot.addressId === "" ||
      bookSlot.addressId === undefined
    ) {
      setError({
        ...error,
        bookingDate: bookSlot.bookingDate === "NaN-NaN-NaN" ? "Please Select Date" : error.bookingDate,
        services: bookSlot.services?.length === 0 ? "Please Choose Service" : error.services,
        startTime: bookSlot.startTime === undefined ? "Please Select Time" : error.startTime,
        addressId: bookSlot.addressId === undefined ? "Please Enter Address" : error.addressId
      })
      setAppointmentConfirmVisible(false)
      return false;
    }
    dispatch(bookSlotsAction(id, bookSlot), setAppointmentConfirmVisible(true))
  }
  const handleDeletServices = (id) => {
    let deletservice = showBookingData.filter((item) => { return item._id !== id })
    if (deletservice) {
      setShowBookingData(deletservice)
    }
    let removeDataBookingServices = dataBooking.services?.filter((item) => { return item !== id })
    if (removeDataBookingServices) {
      setBookingData({
        ...dataBooking,
        services: removeDataBookingServices
      })
    }
  }
  useEffect(() => {
    let dates = new Date(dataBooking.bookingDate);
    let years = dates.getFullYear();
    let months = dates.getMonth() + 1;
    let dts = dates.getDate();
    if (dts < 10) {
      dts = "0" + dts;
    }
    if (months < 10) {
      months = "0" + months;
    }
    let dobs = dts + "-" + months + "-" + years;
    setError({
      ...error,
      addressId: null,
    });
    setBookSlot({
      ...bookSlot,
      bookingDate: dobs,
      startTime: slotProgress?.startTime,
      services: dataBooking?.services,
      addressId: viewAddress?._id || selectAddressId,
      payment: "web",
      successUrl: config + "single-page" + "/" + id + "?" + "success=true",
      failedUrl: config + "single-page" + "/" + id,
    });
  }, [
    dataBooking,
    slotProgress,
    viewAddress,
    selectAddressId,
    showBookingData,
  ]);
  useEffect(() => {
    let calculatePrice = 0;
    showBookingData?.length > 0 &&
      showBookingData?.map((item) => {
        calculatePrice += item.price;
      });
    setTotalPrice(calculatePrice);
  }, [showBookingData]);
  useEffect(() => {
    let totalPrebooking = (totalPrice * 1) / 3;
    setPreBookFees(totalPrebooking);
  }, [viewBookingData]);
  const handleCancelBooking = () => {
    setBookingVisible(false);
    setShowBookingData("");
    setBookingData({
      bookingDate: "",
      services: [],
    });
  };
  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };
  useEffect(() => {
    if (appointmentConfirmVisible === true) {
      setBookingVisible(false)
    }
  }, [appointmentConfirmVisible])
  useEffect(() => {
    if (viewBookingData?.length !== 0) {
      const startTime = viewBookingData.startTime
      const result = startTime?.slice(0, 2);
      const lastResult = startTime?.slice(3, 5)
      const d = result * 60;
      const l = lastResult === "00" ? d + 0 : d + 30
      const g = l + viewBookingData?.services[0]?.durationTime;
      const s = g / 60;
      const starting = s.toString().split(".")
      const ending = starting[1] ? starting[1] * 60 : "00"
      const endvalue = ending / 10
      setFirstTime(starting[0])
      setSecondTime(endvalue)
    }
    // setSecondTime(end)
  }, [viewBookingData])
  useEffect(() => {
    if (appointmentConfirmVisible === false) {
      setBookingData({
        bookingDate: "",
        services: [],
      })
      setShowBookingData([])
    }
  }, [appointmentConfirmVisible])
  const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const d = new Date();
  let name = month[d.getMonth()];
  let year = d.getFullYear();
  let config = "https://instajamaicaweb.appdeft.biz/#/"
  const handlePay = () => {
    window?.open(viewBookingData?.checkout)
    setAppointmentConfirmVisible(false)
  }
  useEffect(() => {
    if (window.location.toString().includes("success=true")) {
      setConfirmationVisible(true)
    }
  }, [id])

  const ServiceTemplate = (BannerContent) => {
    return (
      <div className="">
        <div className="mb-4 recommend-img curve overflow-hidden position-relative">
          <img
            src={`https://instajamaica-api.appdeft.biz/${BannerContent.workPlaceURL}`}
            alt=""
            className="w-100 h-100 cover-img shadow-2"
          />
          <div className="position-absolute top-0 w-100 p-3">
            <div className="d-flex justify-content-between align-items-start">
              <div className="bg-white p-2 d-flex align-items-center rounded">
                <img src={ThumbImg} alt="" width={20} />
                <small className="ms-2">{BannerContent.data}</small>
              </div>
              <div className="review-detail bg-white p-2 rounded">
                <div className=" d-flex align-items-center">
                  <h4 className="fw-semibold me-2">{service.rating}</h4>
                  <img src={StarIcon} alt="" width={20} />
                </div>
                <p className="text-gray m-0">{service.reviewCount} reviews</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  };
  return (
    <div>
      <Carousel
        value={service?.workplace?.length > 0 ? service?.workplace : []}
        showNavigators="false"
        numScroll={1}
        breakpoint="2"
        numVisible={1}
        className="inspiredservice-carousel service-banner overflow-hidden position-relative mt-4"
        responsiveOptions={responsiveOptions}
        itemTemplate={ServiceTemplate}
      />
      <div className="d-flex justify-content-between mb-4 border-top-bottom border-top-0">
        <div>
          <h4>{service.businessName}</h4>
          <p className="mt-0 mb-3 text-sm">
            {service.address?.line1},{service.address?.line2},
            {service.address?.city},{service.address?.zipCode}
          </p>
        </div>
        <div>
          <Button
            size="large"
            onClick={() => handleLikeUnLike(service._id)}
            icon={service.isLiked === 1 ? "pi pi-heart-fill" : "pi pi-heart"}
            className={service.isLiked === 1 ? `bg-transparent border-0 text-dark liked` : `bg-transparent border-0 text-dark unliked`}
          />
        </div>
      </div>
      <Row>
        <Col sm={7}>
          <div className="bg-white shadow rounded mb-3">
            <div className="p-3">
              <h4 className="fw-semibold">Services</h4>
              <div className="p-inputgroup bg-light-color rounded border-end align-items-center px-2 w-100">
                <i className="pi pi-search"></i>
                <InputTextComponent
                  placeholder="Search for service"
                  className="bg-white "
                  extraClassName="w-100"
                  onChange={(e) => setSearchVal(e.target.value)}
                />
              </div>
            </div>
            <div className="border-top-bottom">
              <p className="fw-semibold p-3 mb-0">Popular Services</p>
            </div>
            {searchVal
              ? services?.length > 0 &&
              services.map((item, index) => {
                return (
                  <div
                    className="d-flex p-3 justify-content-between border-bottom"
                    key={index}
                  >
                    <div className="d-flex align-items-center">
                      <Avatar
                        className="cover-img"
                        image={img1}
                        size="xlarge"
                      />
                      <div className="ms-2">
                        <p className="m-0">{item.serviceName}</p>
                        <small className="text-gray">
                          {item.durationTime}min
                        </small>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <p className="text-sm m-0 fw-semibold">
                        ${item.price}.00
                      </p>
                      <MainButton
                        label="Book"
                        className="ms-2"
                        onClick={() => handleSlots(item._id, item)}
                      />
                    </div>
                  </div>
                );
              })
              : service?.services?.length > 0 &&
              service?.services?.map((item, index) => {
                return (
                  <div
                    className="d-flex p-3 justify-content-between border-bottom"
                    key={index}
                  >
                    <div className="d-flex align-items-center">
                      <Avatar
                        className="cover-img rounded overflow-hidden"
                        image={img1}
                        size="xlarge"
                      />
                      <div className="ms-2">
                        <p className="m-0">{item.serviceName}</p>
                        <small className="text-gray">
                          {item.durationTime}min
                        </small>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <p className="text-sm m-0 fw-semibold">
                        ${item.price}.00
                      </p>
                      <MainButton
                        label="Book"
                        className="ms-2"
                        onClick={() => handleSlots(item._id, item)}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="shadow rounded">
            <h4 className="fw-semibold pb-0 p-3">Safety Rules</h4>
            <Row className="p-3 border-top-bottom m-0">
              {service?.safetyRules?.map((items, key) => {
                return (
                  <Col md={6}>
                    <p>
                      <img src={SheildImg} alt="" className="" width={20} />
                      <small className="ms-2">{items.safetyRuleTitle}</small>
                    </p>
                  </Col>
                );
              })}
            </Row>
            <p className="text-gray p-3  text-sm">
              Clients required to wear mask before entering the{" "}
              {service?.businessName}
            </p>
          </div>
          <div className="shadow rounded mb-3">
            <div className="border-top-bottom border-top-0">
              <h4 className="fw-semibold pb-0 p-3">Portfolio</h4>
            </div>
            <Row className="p-3 ">
              {Portfolio?.length > 0 &&
                Portfolio?.map((item) => {
                  return (
                    <Col md={4} className="mb-3">
                      <img
                        src={`https://instajamaica-api.appdeft.biz/${item?.workPlaceURL}`}
                        alt=""
                        className="cover-img w-100 h-100 curve"
                      />
                    </Col>
                  );
                })}
            </Row>
            <div className="p-3">
              <MainButton
                onClick={() => handlePortfolio()}
                className="border-main bg-white text-main fw-semibold"
                label="See all works"
              />
            </div>
          </div>
          <div className="shadow rounded mb-3">
            <div className="border-top-bottom border-top-0">
              <h4 className="fw-semibold pb-0 p-3">Amenities</h4>
            </div>
            <Row className="p-3">
              {service?.venueAmenities?.map((items, key) => {
                return (
                  <Col md={6}>
                    <p>
                      <img
                        src={`https://instajamaica-api.appdeft.biz/${items.amenityImage}`}
                        alt=""
                        className=""
                        width={20}
                      />
                      <small className="ms-2">{items.amenityTitle}</small>
                    </p>
                  </Col>
                );
              })}
            </Row>
          </div>
          <div className="shadow rounded mb-3">
            <div className="border-top-bottom border-top-0">
              <h4 className="fw-semibold pb-0 p-3">Reviews</h4>
            </div>
            <div className="p-3">
              <small className="text-gray">
                Reviews are no joke! Booksy values authentic reviews and only
                verifies them if we know the reviewer has visited this business.
              </small>
              {reviews?.ratingCountByGroup?.length > 0 &&
                reviews?.ratingCountByGroup?.map((item, index) => {
                  return (
                    <>
                      <Row className="align-items-center mt-3">
                        <Col sm={2} className="text-gray">
                          5 star
                        </Col>
                        <Col sm={5}>
                          <ProgressBar
                            value={item._id === 5 ? item.count : 0}
                            className="review-bar"
                            style={{ height: "10px" }}
                            color="#FFB400"
                          ></ProgressBar>
                        </Col>
                        <Col sm={1} className="text-gray-light">
                          100%
                        </Col>
                      </Row>

                      <Row className="align-items-center mt-3">
                        <Col sm={2} className="text-gray">
                          4 star
                        </Col>
                        <Col sm={5}>
                          <ProgressBar
                            value={item._id === 4 ? item.count : 0}
                            className="review-bar"
                            style={{ height: "10px" }}
                            color="#FFB400"
                          ></ProgressBar>
                        </Col>
                        <Col sm={1} className="text-gray-light">
                          1%
                        </Col>
                      </Row>
                      <Row className="align-items-center mt-3">
                        <Col sm={2} className="text-gray">
                          3 star
                        </Col>
                        <Col sm={5}>
                          <ProgressBar
                            value={item._id === 3 ? item.count : 0}
                            className="review-bar"
                            style={{ height: "10px" }}
                            color="#FFB400"
                          ></ProgressBar>
                        </Col>
                        <Col sm={1} className="text-gray-light">
                          1%
                        </Col>
                      </Row>
                      <Row className="align-items-center mt-3">
                        <Col sm={2} className="text-gray">
                          2 star
                        </Col>
                        <Col sm={5}>
                          <ProgressBar
                            value={item._id === 2 ? item.count : 0}
                            className="review-bar"
                            style={{ height: "10px" }}
                            color="#FFB400"
                          ></ProgressBar>
                        </Col>
                        <Col sm={1} className="text-gray-light">
                          1%
                        </Col>
                      </Row>
                      <Row className="align-items-center mt-3">
                        <Col sm={2} className="text-gray">
                          1 star
                        </Col>
                        <Col sm={5}>
                          <ProgressBar
                            value={item._id === 1 ? item.count : 0}
                            className="review-bar"
                            style={{ height: "10px" }}
                            color="#FFB400"
                          ></ProgressBar>
                        </Col>
                        <Col sm={1} className="text-gray-light">
                          0%
                        </Col>
                      </Row>
                    </>
                  );
                })}
            </div>
            <hr />
            {reviewsData?.length > 0 &&
              reviewsData?.map((item, index) => {
                return (
                  <div className="review-box p-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <div className="d-flex align-items-center">
                        <Avatar
                          image={`https://instajamaica-api.appdeft.biz/${item.user?.image}`}
                          size="xlarge"
                          shape="circle"
                        />
                        <p className="fw-semibold ms-2 mb-0">
                          {item.user?.firstName} {item.user?.lastName}
                        </p>
                      </div>
                      <div>
                        <Rating value={item.rating} cancel={false} readOnly />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-2">{item.review}</p>
                        <small className="text-gray">{item.month} ago</small>
                      </div>
                      <div>
                        <span>
                          {item.images?.length > 0 &&
                            item?.images?.map((images, index) => {
                              return (
                                <div className="image">
                                  <img
                                    src={`https://instajamaica-api.appdeft.biz/${images}`}
                                    className="review-image"
                                  />
                                </div>
                              );
                            })}
                        </span>

                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={reviewsData.length}
            onPageChange={onPageChange}
          />
        </Col>
        <Col sm={5}>

          <div className="shadow rounded mb-3 p-3 map-show">
            <MapComponent
              islatitude={islatitude}
              form={form}
              setForm={setForm}
              islongitude={islongitude}
              originValue={true}
              setIsLadtitude={setIsLadtitude}
              setIsLongitude={setIsLongitude}
            />

            <div className="d-flex align-items-center justify-content-between p-3">
              <div className="d-flex align-items-center">
                <img src={CustomerLogo} alt="" width={40} />
                <div className="">
                  <p className="m-0">{service?.businessName}</p>
                  <small className="text-gray">
                    {service?.address?.line1},{service?.address?.line2},
                    {service?.address?.city},{service?.address?.zipCode}
                  </small>
                </div>
              </div>
              {/* <img src={LocationIcon} alt="" width={20} /> */}
            </div>
          </div>
          <div className="shadow rounded">
            <div className="border-top-bottom border-top-0">
              <h4 className="fw-semibold pb-0 p-3 text-lg">About</h4>
            </div>
            <div className="border-top-bottom border-top-0">
              <p className="text-gray text-sm p-3 m-0">{service?.about}</p>
            </div>
            <div className="border-top-bottom border-top-0">
              <h4 className="fw-semibold m-0 p-3 text-lg">
                Contact & Working Hours
              </h4>
            </div>
            <div className="d-flex align-items-center justify-content-between p-3 border-top-bottom border-top-0">
              <div className="d-flex align-items-center ">
                <img src={phoneIcon} alt="" width={20} />
                <div className="ms-2">
                  <small className="text-gray">
                    ({service?.countryCode}) {service?.mobile}
                  </small>
                </div>
              </div>
              <MainButton label="Call" />
            </div>
            <ul className="p-3 border-top-bottom border-top-0">
              {timeData?.length > 0 && timeData?.map((items, key) => {
                return (
                  <li className="d-flex justify-content-between p-2">
                    <small>
                      {items.weekDay === "0"
                        ? "Sunday"
                        : items.weekDay === "1"
                          ? "Monday"
                          : items.weekDay === "2"
                            ? "Tuesday"
                            : items.weekDay === "3"
                              ? "Wednesday"
                              : items.weekDay === "4"
                                ? "Thursday"
                                : items.weekDay === "5"
                                  ? "Friday"
                                  : items.weekDay === "6"
                                    ? "Saturday"
                                    : ""}
                    </small>
                    <small className={items.color}>
                      {items?.isClosed === true ? "Closed" : `${items.startTime === "10,:,00,,, AM" ? "10:00 AM" : items?.startTime} - ${items.endTime}`}

                    </small>
                  </li>
                );
              })}
            </ul>
            <div className="border-top-bottom border-top-0">
              <h4 className="fw-semibold pb-0 p-3 text-lg">
                Social Media & Share
              </h4>
              <div className="d-flex justify-content-around p-3">
                <div
                  className="text-center"
                  onClick={() => {
                    handleSocial("https://www.instagram.com/accounts/login/");
                  }}
                >
                  <i
                    className="pi pi-instagram bg-gray-color fs-5 p-2 text-white rounded-circle"
                    style={{ background: "#A4A4A4" }}
                  ></i>
                  <p className="text-xs text-gray">Instagram</p>
                </div>
                <div
                  className="text-center"
                  onClick={() => {
                    handleSocial("https://www.facebook.com/login/");
                  }}
                >
                  <i
                    className="pi pi-facebook bg-gray-color fs-5 p-2 text-white rounded-circle"
                    style={{ background: "#A4A4A4" }}
                  ></i>
                  <p className="text-xs text-gray">Facebook</p>
                </div>

                <div
                  className="text-center"
                  onClick={() => {
                    handleSocial(service?.website);
                  }}
                >
                  <i
                    className="pi pi-globe bg-gray-color fs-5 p-2 text-white rounded-circle"
                    style={{ background: "#A4A4A4" }}
                  ></i>
                  <p className="text-xs text-gray">Website</p>
                </div>
                <div
                  className="text-center"
                  onClick={() => {
                    handleSocial(service?.instagram);
                  }}
                >
                  <i
                    className="pi pi-upload bg-gray-color fs-5 p-2 text-white rounded-circle"
                    style={{ background: "#A4A4A4" }}
                  ></i>
                  <p className="text-xs text-gray">Share</p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center p-3">
              <p className="fw-semibold text-lg m-0">Reports</p>
              <i className="pi pi-chevron-right fs-6" onClick={() => setAddReports(true)}></i>
            </div>
          </div>
        </Col>
      </Row>
      <CustomModal
        header={
          <div className="text-center">
            <h5 className="text-black">Report Image or content</h5>
          </div>
        }
        visible={addReports}
        onHide={() => setAddReports()}
      >
        <ul className="list-unstyled">
          {reportsArray?.length > 0 && reportsArray?.map((item, index) => {
            return (
              <li className="border-bottom p-3"><div className="flex align-items-center">
                <RadioButton onChange={(e) => {
                  setError({
                    ...error,
                    reportComment: null
                  })
                  setReport({
                    ...report,
                    reportComment: item.reportComment,
                    reportTitle: item?.reportTitle
                  })
                }}
                  checked={report === item}
                />
                <label className="ms-2 text-gray">{item?.reportComment}</label>
              </div>
              </li>
            )
          })}
        </ul>
        <Row>
          <Col>
            <MainButton
              className="bg-gray-light text-black fw-semibold"
              label="Cancel"
            />
          </Col>
          <Col>
            <MainButton
              label="Report"
              onClick={() => handleAddReport(service._id)}
            />
          </Col>
        </Row>
      </CustomModal>
      <CustomModal
        header={
          <div className="text-center">
            <h5 className="fw-bold text-dark">{name} {year}</h5>
          </div>
        }
        visible={bookingVisible}
        onHide={() => handleCancelBooking()}
      >
        <div>
          <p>Select Date and Time</p>
          <div className="row m-0 rounded mb-3">
            <Calendar
              showIcon
              iconPos="left"
              className="border-end col-md-6 p-0"
              value={date}
              inputClassName="ggg"
              dateFormat="dd-mm-yy"
              onChange={(e) => {
                setError({ ...error, bookingDate: null });
                setBookingData({ ...dataBooking, bookingDate: e.value });
              }}
            />

            <DropDownComponent
              extraClassName="col-md-6 p-0"
              placeholder="10:30"
              value={slotProgress}
              disabled={dataBooking.bookingDate === "" ? true : false}
              onChange={(e) => {
                setError({ ...error, startTime: null });
                setslotProgress(e.value);
              }}
              options={slotsData.availabeSlots}
              optionLabel="startTime"
            />
            <div className="col-md-6">{error.bookingDate && (
              <span className="error">{error.bookingDate}</span>
            )}</div>
            <div className="col-md-6">{error.startTime && <span className="error">{error.startTime}</span>}</div>
          </div>


          <div className="bg-light-green p-3 curve mb-3">
            {showBookingData?.length > 0 &&
              showBookingData?.map((item, index) => {
                return (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="d-flex align-items-center ">
                      <Avatar
                        image={PortfolioImg}
                        size="xlarge"
                        className="rounded overflow-hidden"
                      />
                      <div className="ms-2">
                        <p className="text-sm fw-semibold m-0">
                          {item?.serviceName}
                        </p>
                        <small className="text-main text-xs">
                          {slotProgress?.startTime} -{slotProgress?.endTime}
                        </small>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <h5 className="fw-semibold m-0">${item?.price}</h5>
                      <img
                        src={CloseIcon}
                        alt=""
                        className="cursor-pointer bg-main p-2 rounded-circle ms-2"
                        width={25}
                        onClick={() => handleDeletServices(item?._id)}
                      />
                    </div>
                  </div>
                );
              })}

            <hr />
            <div className="d-flex align-items-center">
              <img src={CustomerLogo} alt="" width={40} />
              <div className="">
                <p className="m-0">{service?.businessName}</p>
              </div>
            </div>
          </div>
          <div
            className="d-flex align-items-center mb-3 cursor-pointer"
            onClick={() => setAnotherServiceVisible(true)}
          >
            <img
              src={AddIcon}
              alt=""
              className="cursor-pointer ms-2"
              width={20}
            />
            <small className="text-main ms-2">Add another service</small>
          </div>
          <div className="d-flex align-items-center mb-3 cursor-pointer">
            <img
              src={NotesIcon}
              alt=""
              className="cursor-pointer ms-2"
              width={20}
            />
            <small
              className="text-gray ms-2"
              onClick={() => setAddNoteToggle(true)}
            >
              Leave a note(optional)
            </small>
          </div>
          {addnoteToggle && (
            <div className="shadow p-3 mb-3 curve">
              <InputTextAreaComponent
                placeholder="Add Note"
                value={bookSlot.notes}
                className="mb-3"
                onChange={(e) =>
                  setBookSlot({
                    ...bookSlot,
                    notes: e.target.value,
                  })
                }
              />
              <Row>
                <Col>
                  <MainButton
                    label="Cancel"
                    className="bg-gray-color"
                    onClick={() => {
                      setAddNoteToggle(false);
                      setBookSlot({ bookSlot: "" });
                    }}
                  />
                </Col>
                <Col>
                  <MainButton
                    label="OK"
                    onClick={() => setAddNoteToggle(false)}
                  />
                </Col>
              </Row>
            </div>
          )}
          <p>{bookSlot.notes === "" ? "" : bookSlot.notes}</p>
          <div className="d-flex align-items-center mb-3">
            <img
              src={AddIcon}
              alt=""
              className="cursor-pointer ms-2"
              width={20}
            />
            <small className="text-main ms-2" onClick={() => setAddress(true)}>
              Add Address
            </small>
          </div>
          {/* {error.addressId && <span className="error">{error.addressId}</span>} */}
          {address === true ? (
            <div className="shadow p-3 mb-3 curve">
              <p className="text-sm text-black fw-semibold">Saved address</p>
              <div className="d-flex align-items-center mb-3">
                <img
                  src={AddIcon}
                  alt=""
                  className="cursor-pointer ms-2"
                  width={20}
                />
                <small
                  className="text-main ms-2"
                  onClick={() => {
                    setAddParticlularAddress(true);
                    setAddress(false);
                  }}
                >
                  Add New Address
                </small>
              </div>
              <hr />
              {getAddress.length > 0 &&
                getAddress.map((item, index) => {
                  return (
                    <div className="d-flex mb-3">
                      <RadioButton
                        value={item}
                        onChange={(e) => {
                          setError({
                            ...error,
                            line1: null,
                            city: null,
                          });
                          setAddressForm({
                            ...addressForm,
                            line1: e.value.line1,
                            line2: e.value.line2,
                            city: e.value.city,
                            id: e.value._id,
                          });
                          setSelectAddressId(e.value._id);
                        }}
                        checked={addressForm.id === item._id}
                      />
                      <div className="ms-2">
                        <p className="text-sm fw-semibold m-0">
                          {item.name === "" ? "Home" : item.name}
                        </p>
                        <small className="text-gray">
                          {item.line1},{item.line2 === "" ? "" : item.line2}{" "}
                          {item.city},{item.zipCode === "" ? "" : item.zipcode}
                        </small>
                      </div>
                    </div>
                  );
                })}
              {error.line1 && <span className="error">{error.line1}</span>}
              <MainButton
                label="Done"
                onClick={() => {
                  setAddress(false);
                  setAddressData(true);
                  handleUpdateAddress();
                }}
              />
            </div>
          ) : (
            false
          )}
          {addParticularAddress === true ? (
            <div className="shadow p-3 mb-3 curve">
              <p className="text-sm text-black fw-semibold">Add Address</p>
              <hr />
              <InputText
                placeholder="Street & Number"
                extraClassName="mb-2"
                value={addressForm.line1}
                onChange={(e) => {
                  setError({
                    ...error,
                    line1: null,
                  });
                  setAddressForm({
                    ...addressForm,
                    line1: e.target.value,
                  });
                }}
              />
              {error.line1 && <span className="error">{error.line1}</span>}
              <InputText
                placeholder="Apartment or Suite Number(Optional)"
                extraClassName="mb-2"
                value={addressForm.line2}
                onChange={(e) => {
                  setAddressForm({
                    ...addressForm,
                    line2: e.target.value,
                  });
                }}
              />

              <InputText
                placeholder="City"
                value={addressForm.city}
                extraClassName="mb-2"
                onChange={(e) => {
                  setError({
                    ...error,
                    city: null,
                  });
                  setAddressForm({
                    ...addressForm,
                    city: e.target.value,
                  });
                }}
              />
              {error.city && <span className="error">{error.city}</span>}
              <p className="text-sm text-black fw-semibold">Save as</p>
              <div className="d-flex mb-3">
                <MainButton
                  label="Home"
                  className={
                    addressForm.name === "Home"
                      ? ""
                      : addressForm.name === "other"
                        ? "bg-white border-main text-main ms-2"
                        : ""
                  }
                  onClick={() => {
                    setAddressForm({
                      ...addressForm,
                      name: "Home",
                    });
                  }}
                />
                <MainButton
                  label="Other"
                  className={
                    addressForm.name === "other"
                      ? ""
                      : "bg-white border-main text-main ms-2"
                  }
                  onClick={() => {
                    setAddressForm({
                      ...addressForm,
                      name: "other",
                    });
                  }}
                />
              </div>
              <Row>
                <Col>
                  <MainButton
                    label="Cancel"
                    className="bg-gray-color"
                    onClick={() => setAddParticlularAddress(false)}
                  />
                </Col>
                <Col>
                  <MainButton
                    label="Continune"
                    onClick={() => {
                      setAddressData(true);
                      setAddParticlularAddress(false);
                      handleAddEditAddress();
                    }}
                  />
                </Col>
              </Row>
            </div>
          ) : (
            false
          )}
          {AddressData === true ? (
            <>
              <p className="text-sm text-black fw-semibold">Add Address</p>
              <div className="shadow p-3 curve d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex">
                  <img
                    src={HomeIcon}
                    alt=""
                    className="cursor-pointer"
                    width={20}
                  />
                  <p className="text-sm m-0 ms-2">
                    {addressForm.line1 === "" ? "" : addressForm.line1} ,{" "}
                    {addressForm.line2 === "" ? "" : addressForm.line2} ,
                    {addressForm.city === "" ? "" : addressForm.city}
                  </p>
                </div>
                <img
                  src={EditIcon}
                  alt=""
                  className="cursor-pointer"
                  width={20}
                  onClick={() => handleEditAddress()}
                />
              </div>
            </>
          ) : (
            false
          )}
          {error.addressId && <span className="error">{error.addressId}</span>}
          <Row>
            <Col>
              <h2 className="fw-bold text-black">${totalPrice}.00</h2>
            </Col>
            <Col>
              <MainButton
                label="book"
                onClick={() => { handleBookSlots() }}
              />
            </Col>
          </Row>
        </div>
      </CustomModal>
      <CustomModal
        header={
          <>
            <div className="d-flex align-items-center ">
              <MainButton
                icon="pi pi-arrow-left"
                className="text-gray bg-white"
              />
              <h5 className="fw-bold text-dark ms-3 mb-0">
                Add Another Service
              </h5>
            </div>
            <span className="p-input-icon-left w-100 login">
              <i className="pi pi-search" />
              <InputText
                placeholder="Search"
                onChange={(e) => setSearchVal(e.target.value)}
              />
            </span>
          </>
        }
        visible={anotherServiceVisible}
        onHide={() => setAnotherServiceVisible(false)}
      >
        {searchVal
          ? services?.length > 0 &&
          services.map((item, index) => {
            return (
              <div
                className="d-flex px-3 justify-content-between border-bottom"
                key={index}
              >
                <div className="d-flex align-items-center">
                  <Avatar className="cover-img" image={img1} size="xlarge" />
                  <div className="ms-2">
                    <p className="m-0">{item.serviceName}</p>
                    <small className="text-gray">
                      {item.durationTime}min
                    </small>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <p className="text-sm m-0 fw-semibold">${item.price}.00</p>
                  <MainButton
                    label="Book"
                    className="ms-2"
                    onClick={() => handleSlots(item._id, item)}
                  />
                </div>
              </div>
            );
          })
          : filterServices?.length > 0 &&
          filterServices?.map((item, index) => {
            return (
              <div className="d-flex justify-content-between align-items-center border-bottom py-3 mb-3">
                <div className="d-flex align-items-center ">
                  <Avatar
                    image={PortfolioImg}
                    size="xlarge"
                    className="rounded overflow-hidden"
                  />
                  <div className="ms-2">
                    <p className="text-sm fw-semibold m-0">
                      {item?.serviceName}
                    </p>
                    <small className="text-gray text-xs">
                      {item?.durationTime}min
                    </small>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <h5 className="fw-semibold m-0">${item?.price}</h5>
                  <MainButton
                    label="Add"
                    className="ms-2"
                    onClick={() => handleSlots(item._id, item)}
                  />
                </div>
              </div>
            );
          })}

        <Row>
          <Col>
            <MainButton
              label="Cancel"
              className="bg-gray-color"
              onClick={() => setAnotherServiceVisible(false)}
            />
          </Col>
          <Col>
            <MainButton
              label="Continune"
              onClick={() => setAnotherServiceVisible(false)}
            />
          </Col>
        </Row>
      </CustomModal>
      {
        appointmentConfirmVisible === true && viewBookingData.length === 0 ?
          <Loader />
          : <CustomModal
            header={
              <>
                <div className="d-flex align-items-center ">
                  <MainButton
                    icon="pi pi-arrow-left"
                    className="text-gray bg-white"
                    onClick={() => setAppointmentConfirmVisible(false)}
                  />
                  <h5 className="fw-bold text-dark ms-3 mb-0">
                    Appointment Confirmation
                  </h5>
                </div>
                {/* <span className="p-input-icon-left w-100 login">
           <i className="pi pi-search" />
           <InputText placeholder="Search" />
         </span> */}
              </>
            }
            visible={appointmentConfirmVisible}
            onHide={() => setAppointmentConfirmVisible(false)}
          >
            <>
              <p className="text-lg text-center fw-semibold">
                {day},{dateMonth}, {viewBookingData.startTime}
              </p>
              <hr />
              <div className="bg-light-green p-3 curve mb-3">
                {viewBookingData?.services?.length > 0 && viewBookingData?.services?.map((item, index) => {
                  return (
                    <div className="d-flex justify-content-between align-items-start">
                      <div className="d-flex align-items-center ">
                        <Avatar
                          image={PortfolioImg}
                          size="xlarge"
                          className="rounded overflow-hidden"
                        />
                        <div className="ms-2">
                          <p className="text-sm fw-semibold m-0">
                            {item.serviceName}
                          </p>
                          <small className="text-main text-xs">{viewBookingData.startTime}-{firstTime}:{secondTime}</small>
                        </div>
                      </div>
                      <h5 className="fw-semibold m-0">${item.price}</h5>
                    </div>
                  )
                })}

              </div>
              <div className="text-center">
                <p className="fw-semibold m-0">This appoinment requires a pre-booking fees.</p>
                <small className="text-gray">
                  You need to pay 30% of the total booking amount .
                </small>
              </div>
              <hr />
              <div className="d-flex justify-content-between align-items-center mb-2">
                <p className="m-0 fw-semibold">Pre-booking fees</p>
                <p className="m-0 fw-semibold text-main">${prebookfees?.toFixed(2)}</p>
              </div>
              <MainButton label="Pay Now" onClick={() => handlePay()} />
            </>
          </CustomModal>
      }
      <CustomModal
        header={
          <>
            <div className="d-flex align-items-center ">
              <MainButton
                icon="pi pi-arrow-left"
                className="text-gray bg-white"
                onClick={() => { navigate(`/single-page/${id}`); setConfirmationVisible(false) }}
              />
            </div>
          </>
        }
        visible={confirmationVisible}
        onHide={() => { navigate(`/single-page/${id}`); setConfirmationVisible(false) }}
      >
        <div className="text-center mb-3">
          <img src={Logo} alt="" />
          <h4 className="text-dark fw-semibold text-center mb-3">
            Thank You for Choosing Us!
          </h4>
          <img src={ConfirmIcon} alt="" width={70} className="mb-3" />
          <p className="text-gray">
            You will receive an automatic email confirmation of your booking and
            an order number.
          </p>
        </div>

        <MainButton label="Ok, got it" className="mb-3" onClick={() => { navigate(`/single-page/${id}`); setConfirmationVisible(false) }} />
      </CustomModal>
    </div >
  );
}
