import React, { useState } from "react";
import LayoutSidebar from "../../layout/LayoutSidebar";
import { Card } from "react-bootstrap";
import { ProgressBar } from "primereact/progressbar";
import About from "./About";
import BussinessCategory from "./BussinessCategory";
import WhereDoWork from "./WhereDoWork";
import Address from "./Address";
import BussinessHours from "./BussinessHours";
import MapComponent from "./mapComponent";

export default function ProfileSetup() {
  const [selectedStep, setSelectedStep] = useState("1")
  const [islongitude,setIsLongitude] = useState('');
  const[islatitude,setIsLadtitude] =  useState('')
  const [form, setForm] = useState({
    businessName: "",
    category: "",
    countryCode: "",
    mobile: "",
    workType: "",
    location: {
      coordinates: []
    },
    address: {
      line1: "",
      line2: "",
      city: "",
      zipCode: ""
    }
  })

  const [error, setError] = useState("")
  const[address,setAddress]=  useState()
  return (
    <div>
      <LayoutSidebar>
        <div className=" background-all">
          <div className="row p-0" style={{ height: "100vh" }}>
            <div className="col-md-6 m-0 img-signup h-100 position-relative p-0">
              <div className=" h-100 d-flex align-items-center justify-content-center">
                <h1 className="text-white position-relative fw-bold">
                  Setup Your Business <br />
                  With InstaJamaica
                </h1>
              </div>
            </div>
            <div className="col-md-6 m-0">
              <div className="d-flex align-items-center justify-content-center h-100">
                <Card className="border-0" style={{ width: "30rem" }}>
                  <ProgressBar value={20} style={{ height: "6px" }}></ProgressBar>
                  <Card.Body className="p-5">
                    {selectedStep === "1" && <About error={error} setError={setError} form={form} setForm={setForm} selectedStep={selectedStep} setSelectedStep={setSelectedStep} />}
                    {selectedStep === "2" && <BussinessCategory form={form} setForm={setForm} setSelectedStep={setSelectedStep} />}
                    {selectedStep === "3" && <WhereDoWork form={form} setForm={setForm} setSelectedStep={setSelectedStep} />}
                    {selectedStep === "4" && <Address setAddress={setAddress} address={address} form={form} setForm={setForm} setSelectedStep={setSelectedStep} islongitude ={islongitude} setIsLongitude ={setIsLongitude} islatitude = {islatitude} setIsLadtitude ={setIsLadtitude} />}
                    {selectedStep === "5" && <MapComponent setSelectedStep ={setSelectedStep} setAddress={setAddress} address={address} searchBox={false} form = {form} setForm = {setForm} setError ={setError} error = {error} islongitude ={islongitude} setIsLongitude ={setIsLongitude} islatitude = {islatitude} setIsLadtitude ={setIsLadtitude}/>}
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </LayoutSidebar>
    </div>
  );
}
