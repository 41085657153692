import React from "react";
import Layout from "../../layout";
import CameraIcon from "../../assets/icons/camera.png";
import CodeReview from "../../assets/vector/Codereview.png";
import MainButton from "../../components/buttons/MainButton";
import LicenceImg from "../../assets/images/licence.png";
import { Link } from "react-router-dom";

export default function Verified() {
  return (
    <div>
      <Layout>
        <div className="p-5">
          <div className="d-flex mb-3">
            <h4 className="text-main fw-bold mb-0 ms-3">Verification</h4>
          </div>

          <div className="row text-center align-items-end mb-3">
            <div className="col-md-8">
              <div className="bg-white rounded p-5 text-center">
                <img src={CodeReview} width={211} className="mb-3" />
                <p className="text-lg fw-semibold">Documents Submitted!</p>
                <p>
                  We're diligently verifying your content to ensure accuracy.
                  Stay
                  <br />
                  tuned for updates! Thank you for your cooperation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
