import React from "react";
import Img from "../../assets/images/img.png";
// import HomeContent from "./HomeContent";

export default function Chooseus() {
  // const { ChooseUsData } = HomeContent();
  return (
    <div>
      <div className="container position-relative py-4">
        {/* {ChooseUsData?.map((items, key) => {
          return (
            <div className={`row justify-content-between row-reverse py-5`}>
              <div className="col-md-5">
                <div>
                  <h2 className="fw-bold">{items.heading}</h2>
                  <p className="text-gray">
                    {items.para}
                    <br /><br />
                    {items.para}1
                    <br /><br />
                    {items.para2}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="curve img-box overflow-hidden">
                  <img src={items.img} alt="" className="w-100 h-100 cover-img" />
                </div>
              </div>
            </div>
          );
        })} */}
      </div>
    </div>
  );
}
