import React, { useState } from "react";
import Layout from "../../layout";
import ArrowRight from "../../assets/icons/arrow-right.png";
import { InputSwitch } from "primereact/inputswitch";
import DropDownComponent from "../../components/input/DropDownComponent";
import MainButton from "../../components/buttons/MainButton";

export default function BussinessHour() {
  const [checked, setChecked] = useState(false);
  return (
    <div>
      <Layout>
        <div className="p-5">
          <div className="d-flex align-items-center mb-3">
            <img src={ArrowRight} width={25} alt="" />
            <h4 className="text-main fw-bold mb-0 ms-3">Business Hours</h4>
          </div>
          <div className="bg-white rounded p-5">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-5">
                  <div className="row align-items-center mb-3">
                    <div className="col-md-3 d-flex align-items-center">
                      <InputSwitch
                        checked={checked}
                        onChange={(e) => setChecked(e.value)}
                      />
                      <p className="fw-semibold ms-2 m-0">Sunday</p>
                    </div>
                    <div className="col-md-3">
                      <DropDownComponent />
                    </div>
                    <div className="col-md-3">
                      <DropDownComponent />
                    </div>
                    <div className="col-md-3">
                      <MainButton
                        label="Add Break"
                        className="p-2 h-100 mb-0"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center mb-3">
                    <div className="col-md-3 d-flex align-items-center">
                      <InputSwitch
                        checked={checked}
                        onChange={(e) => setChecked(e.value)}
                      />
                      <p className="fw-semibold ms-2 m-0">Monday</p>
                    </div>
                    <div className="col-md-3">
                      <DropDownComponent />
                    </div>
                    <div className="col-md-3">
                      <DropDownComponent />
                    </div>
                    <div className="col-md-3">
                      <MainButton
                        label="Add Break"
                        className="p-2 h-100 mb-0"
                      />
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-md-3 d-flex align-items-center justify-content-center">
                      <p className="fw-semibold ms-2 m-0">Break</p>
                    </div>
                    <div className="col-md-3">
                      <DropDownComponent />
                    </div>
                    <div className="col-md-3">
                      <DropDownComponent />
                    </div>
                    <div className="col-md-3">
                      <MainButton
                        label="Delete"
                        className="p-2 h-100 mb-0 bg-red"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center mb-3">
                    <div className="col-md-3 d-flex align-items-center">
                      <InputSwitch
                        checked={checked}
                        onChange={(e) => setChecked(e.value)}
                      />
                      <p className="fw-semibold ms-2 m-0">Tuesday</p>
                    </div>
                    <div className="col-md-3">
                      <DropDownComponent />
                    </div>
                    <div className="col-md-3">
                      <DropDownComponent />
                    </div>
                    <div className="col-md-3">
                      <MainButton
                        label="Add Break"
                        className="p-2 h-100 mb-0"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center mb-3">
                    <div className="col-md-3 d-flex align-items-center">
                      <InputSwitch
                        checked={checked}
                        onChange={(e) => setChecked(e.value)}
                      />
                      <p className="fw-semibold ms-2 m-0">Wednesday</p>
                    </div>
                    <div className="col-md-3">
                      <DropDownComponent />
                    </div>
                    <div className="col-md-3">
                      <DropDownComponent />
                    </div>
                    <div className="col-md-3">
                      <MainButton
                        label="Add Break"
                        className="p-2 h-100 mb-0"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center mb-3">
                    <div className="col-md-3 d-flex align-items-center">
                      <InputSwitch
                        checked={checked}
                        onChange={(e) => setChecked(e.value)}
                      />
                      <p className="fw-semibold ms-2 m-0">Thursday</p>
                    </div>
                    <div className="col-md-3">
                      <DropDownComponent />
                    </div>
                    <div className="col-md-3">
                      <DropDownComponent />
                    </div>
                    <div className="col-md-3">
                      <MainButton
                        label="Add Break"
                        className="p-2 h-100 mb-0"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center mb-3">
                    <div className="col-md-3 d-flex align-items-center">
                      <InputSwitch
                        checked={checked}
                        onChange={(e) => setChecked(e.value)}
                      />
                      <p className="fw-semibold ms-2 m-0">Friday</p>
                    </div>
                    <div className="col-md-3">
                      <DropDownComponent />
                    </div>
                    <div className="col-md-3">
                      <DropDownComponent />
                    </div>
                    <div className="col-md-3">
                      <MainButton
                        label="Add Break"
                        className="p-2 h-100 mb-0"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center mb-3">
                    <div className="col-md-3 d-flex align-items-center">
                      <InputSwitch
                        checked={checked}
                        onChange={(e) => setChecked(e.value)}
                      />
                      <p className="fw-semibold ms-2 m-0">Saturday</p>
                    </div>
                    <div className="col-md-3">
                      <DropDownComponent />
                    </div>
                    <div className="col-md-3">
                      <DropDownComponent />
                    </div>
                    <div className="col-md-3">
                      <MainButton
                        label="Add Break"
                        className="p-2 h-100 mb-0"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <MainButton label="Save" className="px-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
