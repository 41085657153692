const ServiceData = () => {
    const WalletTableHeading = [
      {
        field: "service",
        header: "Services",
      },
      {
        field: "duration",
        header: "Duration",
      },
      {
        field: "price",
        header: "Price",
      },
      {
        field: "action",
        header: "",
      },
    ];
  
    const WalletDetail = [
      {
        service: "26-August-2022",
        duration: "John Bride",
        price: "$ 45.00 USD",
      },
    ];
  
    return { WalletTableHeading, WalletDetail };
  };
  export default ServiceData;
  