export default class ClientConstants {
    static CLIENT_BASE_URL = "https://instajamaica-api.appdeft.biz/api/user"
    static END_POINT = {
        CLIENTSIGNUP: "/signup",
        REQUESTOTP: "/request-otp",
        CLIENTLOGIN: "/login",
        ClientForgetPassword: "/forget-password",
        ClientcChangeForgetPassword: "/change-forget-password",
        allCategories: "/categories",
        GETBUSSIBNESS: "/get-business",
        GETBUSSINESSBYID: "/get-business",
        GETBUSINESSREVIEWSBYID: "/get-business-reviews",
        GETSLOTS: "/get-slots",
        BOOKSLOTS:"/book-slots",
        ADDRESS:"/address",
        GETADDRESS:"/address",
        PUTADDRESS:"/address",
        CREATECARDTOKEN:"/get-card-token",
        GETUSERPROFILE:"/my-profile",
        UPDATEUSERPROFILE:"/update",
        RECENTSEARCH:"/search",
        DELETESEARCH:"/search",
        LIKEUNLIKE:"/like-unlike-business",
        GETMYBOOKING:"/bookings",
        GETBOOKING:"/booking",
        CANCELBOOKING:"/booking",
        ADDREVIEW:"/add-review",
        ADDREPORTS:"/add-business-reports",
        DELETEADDRESS:"/address"
    }
}