import React,{useState,useEffect} from "react";
import Layout from "../../layout";
import { Card } from "react-bootstrap";
import MainButton from "../../components/buttons/MainButton";
import { Link } from "react-router-dom";
import OtpInput from 'react-otp-input';
import ArrowRight from "../../assets/icons/arrow-right.png"
import LayoutSidebar from "../../layout/LayoutSidebar";
export default function OtpVerification({ form, setForm, selectedState, setSelectedState,handleSubmit }) {
  const [counter, setCounter] = useState(60);
  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000)
    }
  }, [counter]);
  const resendOtp = () => {
    setCounter(60);
    handleSubmit()
  }
  return (
    <>
    <div>
      <LayoutSidebar>
        <div className=" background-all d-flex justify-content-center align-items-center">
        
          <Card className="logincards border-0 p-3">
            <Card.Body>
            <img src={ArrowRight} alt = "" width={25} onClick={()=>setSelectedState("1")}/>
              <h3 className="heading text-center fw-bold">OTP Verifications</h3>
              <p className="text-center text-gray">
                Code has been send to registered email: <br />{" "}
                <span>{form?.email}</span>
              </p>
              <div className="row verify-input justify-content-center mb-3">
                <OtpInput
                  value={form?.otp}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                  onChange={(e) => {
                   setForm({
                    ...form,
                    otp:e
                   })
                  }}
                />
              </div>
              <p className="text-main text-center fw-semibold">
              {counter === 0 ? <span onClick={resendOtp}>Resend code </span> : <><span>Resend code </span><span className="text-dark">in</span> {counter}
          <span className="text-dark">s</span></>}
              </p>
              <Link to="">
                <MainButton label="Continue" onClick={()=>setSelectedState("3")}/>
              </Link>
            </Card.Body>
          </Card>
        </div>
      </LayoutSidebar>
      </div>
    </>
  );
}
