import React from "react";
import { Button } from "primereact/button";

export default function MainButton({
  type,
  onClick,
  label,
  loading,
  className,
  icon,
  iconPos,
  extraClass,
  ...props
}) {
  return (
    <div className={extraClass}>
      <Button
        type={type}
        label={label}
        icon={icon}
        iconPos={iconPos}
        onClick={onClick}
        loading={loading}
        className={`ripple w-100 border-0 text-white border-none bg-main font-semibold rounded ${className}`}
        {...props}
      />
    </div>
  );
}
