import React from "react";
import Layout from "../../Layout";
import TabConatiner from "../profile/TabContainer";
const PrivacyData = () => {
    return (
        <div>
            <Layout>
                <TabConatiner/>
                <div className="banner p-5">
                    <h1 className="display-3 d-flex justify-content-center align-items-center h-100 fw-bold text-main">
                        Privacy Policy
                    </h1>
                </div>
                <div className="p-5">
                    <p>
                        This privacy policy (this "Policy") explains how personal information is collected, used, and disclosed by InstaJamaica, 515 North State Street, Suite 460, Chicago, IL 60654 and all its designated agents, employees and subsidiaries ("InstaJamaica" or "we"). This Policy applies to consumer users (individually referred to as "you") of InstaJamaica websites, applications, and other online services (collectively, our "Sites").
                    </p>
                    <p className="mb-5">
                        Other third parties, such as small and medium businesses ("SMBS") at which you make reservations through our Sites, issuers of Merchant Gift Cards you purchase through our Sites, and social networks that you use in connection with our Sites, may also collect, use, and share information about you. This Policy does not cover such third parties or their services. For information about third-party privacy practices, please consult with them directly.
                    </p>

                    <h3 className="privacy-heading">Information We Collect</h3>
                    <p className="text-gray">We collect information about you in various ways when you use our Sites. We use this information to, among other things, provide the functionality and improve the quality of our Sites and personalize your experience. For example, we may collect your name, date of birth, your photo, email address, postal address, phone number (including your mobile phone number), billing information, survey responses, demographics, current and prior appointments details, favorite SMBs, special SMB requests, passwords, contact information of people you add to, or notify of, your appointments through our Sites, names and email addresses of recipients of InstaJamaica Gift Cards (as this term is defined in the InstaJamaica Terms of Use) and Merchant Gift Cards, and other information you provide on our Sites. If you use our mobile application, either to book an appointment or to pay, we may also collect your mobile device ID, your precise location data, and the SMB search locations you select. When you use our Friends and Family feature to book appointments for your friends and family, we will access and collect your contact book details, which may include all contacts in your phone, their names, addresses, phone numbers, and emails. Similarly, if another user has added you to their account using the Friends and Family feature, we will collect the data on you provided by that user and process in accordance with this privacy policy, which also provides you rights as the data subject of that imported contact date. For certain services on our Sites, credit or debit card account information may be required, as further described in the section called "Payment Card Information" below. We may also obtain information from other sources, such as third-party websites, applications, and services (each, a "Third Party Platform"), through which you connect with our Sites and combine that with information we collect on our Sites. </p>
                    <p className="text-gray">You can check or edit your personal data that you have provided to us at any time by going to the "Profile" "Account Details" tab in the InstaJamaica application. </p>
                    <p className="text-gray">When you visit our Sites, some information is automatically collected. For example, when you visit our Sites, we may automatically collect your location, computer operating system, Internet Protocol (IP) address, access times, browser type and language, and the website you visited before our Sites. We also collect information about your usage and activity on our Sites using certain technologies, such as : </p>
                    <p className="text-gray">1. Cookies. We may automatically collect information using "cookies" and similar technology. Cookies are small data files that may have unique identifiers and reside, among other places, on your computer or mobile device, in emails we send to you, and on our web pages. Among other things, cookies help us improve our Sites and your experience. We use cookies to see which areas and features of our Sites are popular and to count visits to our Sites. We may access information contained in cookies placed on your device by a Third-Party Platform as permitted by the terms of your agreement and privacy settings with such Third-Party Platform. We may share this information with the Third-Party Platform for its use as permitted by the terms of your agreement and privacy settings with such Third-Party Platform. </p>
                    <p className="text-gray">2. Web Beacons. We may collect information using web beacons. Web beacons are electronic images that may be used on our Sites or in our emails. We use web beacons to deliver cookies, count visits, understand usage and campaign effectiveness, and tell whether you open an email and act upon it. </p>
                    <p className="text-gray mb-5">3. Do Not Track Signals. We currently do not employ technology that recognizes "do-not-track" signals from your browser. We may engage third parties, such as marketing or analytics partners, who may collect information about your online activities over time and across different websites when you use our Sites.</p>
                    <h3 className="privacy-heading">How We Use Information We Collect</h3>
                    <p className="text-gray mb-5">We use personal information collected through our Sites for the purposes described in this Policy or disclosed to you on our Sites or otherwise in connection with our services. For example, we may use your information to : </p>
                    <p className="text-gray mb-5">1. Make and change your appointments or those of your friends and family members made through our Sites; </p>
                    <p className="text-gray mb-5">2. Offer you, or provide you with, products and services, such as Booksy Gift Cards and Merchant Gift Cards and the option to pay your bill at a SMB through our Sites; </p>
                    <p className="text-gray mb-5">3. Email Booksy Gift Cards and Merchant Gift Cards to designated recipients; </p>
                    <p className="text-gray mb-5">4. Operate and improve our Sites, products, and services; </p>
                    <p className="text-gray mb-5">5. Understand you and your preferences to enhance, personalize, and customize your experience and enjoyment using our Sites, products, and services, such as understanding your appointment history to make recommendations about other SMBS you may like; </p>
                    <p className="text-gray mb-5">6. Process and deliver contest entries and rewards; </p>
                    <p className="text-gray mb-5">7. Display relevant advertising; </p>
                    <p className="text-gray mb-5">8. Respond to your comments and questions and provide customer service; </p>
                    <p className="text-gray mb-5">9. Send you information relating to our products and services, including reservation confirmations, receipts, technical notices, updates, security alerts, and support and administrative messages; </p>
                    <p className="text-gray mb-5">10. Communicate with you about contests, offers, promotions, rewards, upcoming events, and other news about products and services offered by Booksy, our subsidiaries, and affiliates; select SMBs; and our other selected partners; </p>
                    <p className="text-gray mb-5">11. Link or combine with other personal information we get from third parties to help understand your needs and provide you with better service;</p>
                    <p className="text-gray mb-5">12. Authenticate your credit or debit card account information; </p>
                    <p className="text-gray mb-5">13. Protect, investigate, and deter against fraudulent, unauthorized, or illegal activity. By providing your mobile phone number, you expressly consent to receive direct dial calls, autodialed and prerecorded message calls, and text messages from us relating to our product and services at that number.</p>
                    <p className="text-gray mb-5">InstaJamaica may store and process personal information in the United States and other countries. We will process your data : </p>
                    <p className="text-gray mb-5">1. for the conclusion and performance of the contract - until the contract is terminated or expires, and then until the end of the limitation period for claims; </p>
                    <p className="text-gray mb-5">2. for marketing of products or services (except direct marketing) - until the termination or expiry of the contract or until you object to the processing of your data for this purpose; </p>
                    <p className="text-gray mb-5">3. for direct marketing of InstaJamaica products or services - until the termination or expiry of the contract or until you object to the processing of your data for this purpose; </p>
                    <p className="text-gray mb-5">4. for profiling/grouping purposes - until the termination or expiry of the contract or until you object to processing for that purpose; </p>
                    <p className="text-gray mb-5">5. for research into preferences in terms of demand for services - until the termination or expiry of the contract or until you object to processing for that purpose;</p>
                    <p className="text-gray mb-5">6. for the purpose of establishing, investigating or defending against claims - until the end of the limitation period for claims; </p>
                    <p className="text-gray mb-5">7. to comply with legal obligations- until the expiry of legal data storage obligations; </p>
                    <p className="text-gray mb-5">8. in order to produce compilations, analyses, statistics for internal needs - for the duration of the agreement and then until the end of the limitation period for claims arising from the agreement or until an objection is raised to data processing for this purpose; </p>
                    <p className="text-gray mb-5">9. for the purpose of implementing or developing new solutions/features of InstaJamaica systems/applications - until the termination or expiration of the contract or until you object to data processing for this purpose.</p>
                    <h3 className="privacy-heading" >Notice to California Residents</h3>
                    <p className="text-gray">A California resident who has provided personal information to a business with whom he/she has established a business relationship for personal, family, or household purposes (a "California Customer") may request information about whether the business has disclosed personal information to any third parties for the third parties' direct marketing purposes, subject to certain exceptions. In general, again, subject to certain exceptions, if the business has made such a disclosure of personal information, upon receipt of a request by a California Customer, the business is required to provide a list of all third parties to whom it disclosed personal information in the preceding calendar year, as well as a list of the categories of personal information that were disclosed. One of the exceptions allows InstaJamaica to provide California Customers a cost-free means to opt out of these disclosures instead of providing such list. Any customer may request to opt out of these disclosures by contacting InstaJamaica as described above. California Customers may also request further information about our compliance with this law by contacting us at our address above.</p>
                    <h3 className="privacy-heading">Changes to This Policy</h3>
                    <p className="text-gray">InstaJamaica may update or revise this Policy from time to time. You agree that you will review this Policy periodically. If we make any changes to this Policy, we will change the "Last Updated" date above. You are free to decide whether or not to accept a modified version of this Policy, but accepting this Policy, as modified, is required for you to continue using our Sites. If you do not agree to the terms of this Policy or any modified version of this Policy, your sole recourse is to terminate your use of our Sites.</p>
                </div>
            </Layout>
        </div>
    )
}
export default PrivacyData