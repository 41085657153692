import React, { useState } from "react";
import Logo from "../../assets/svg/Logoinstajamica.svg";
import MainButton from "../../components/buttons/MainButton";
import { Link } from "react-router-dom";

function WelcomeIntro({setSelectedStep}) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    {
      heading: "Welcome to InstaJamica",
      paragraph:
        "We're excited to have you join our community of service providers. Let's work together to provide exceptional service to our customers.",
    },
    {
      heading: "Build your business",
      paragraph:
        "Take your services to the next level with our app. Join as a provider and enjoy the benefits  of seamless booking and payment processing.",
    },
    {
      heading: "Broaden your reach",
      paragraph:
        "Don't limit your business to your local area. Expand your reach and find new customers with our app.",
    },
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
    );
  };

  return (
    <>
      <div className="mb-3">
        <div>
          <img src={Logo} alt="logo" className="mt-2" />
        </div>
        <div className="d-block  mt-3">
          <h4 className="text-main fw-bold">{slides[currentIndex].heading}</h4>
          <p className="para-signup">{slides[currentIndex].paragraph}</p>
        </div>
        <div className="d-block  mt-3">
          <i
            className="pi pi-angle-left"
            style={{
              color: "#42B16E",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            onClick={prevSlide}
          ></i>
          <i
            className="pi pi-angle-right"
            style={{
              color: "#42B16E",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            onClick={nextSlide}
          ></i>
        </div>
      </div>
      <Link to="">
        <MainButton label="Get Started" onClick={()=>setSelectedStep("2")}/>
      </Link>
    </>
  );
}

export default WelcomeIntro;
