import { types } from "../types/types";
const intitalState = {
    clientGetMyBookingProfile: {},
    clientGetBooking: {},
    cancelBookingProfile: [],
    addReviewProfile: [],
    termServiceProfile: {},
    privacyPolicyProfile:[],
};

export const getMyBookingReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.GET_MY_BOOKING:
            return {
                ...state,
                clientGetMyBookingProfile: action.payload,
            };
        default:
            return { ...state };
    }
}
export const getBookingReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.GET_BOOKING:
            return {
                ...state,
                clientGetBooking: action.payload,
            };
        default:
            return { ...state };
    }
}
export const cancelBookingReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CANCEL_BOOKING:
            return {
                ...state,
                cancelBookingProfile: action.payload,
            };
        default:
            return { ...state };
    }
}

export const addReviewReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ADD_REVIEW:
            return {
                ...state,
                addReviewProfile: action.payload,
            };
        default:
            return { ...state };
    }
}

export const termServiceReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.TERM_SERVICE:
            return {
                ...state,
                termServiceProfile: action.payload,
            };
        default:
            return { ...state }
    }
}

export const privacyPolicyReducer = (state=intitalState,action)=>{
    switch(action.type){
        case types.PRIVACY_POLICY:
            return{
                ...state,
                privacyPolicyProfile:action.payload,
            };
            default:
                return {...state}
    }
}