import React from 'react';
import { Link } from 'react-router-dom';
import Appstore from "../../../assets/icons/Appstore.png";
import Playstore from "../../../assets/icons/playstore.png";

export default function Footer() {
  return (
    <div>
         {/* footer */}
      <div className="bg-main p-5">
        <div className="container">
          <div className="row justify-content-between align-items-center mb-4">
            <div className="col-md-8">
              <ul className="d-flex flex-wrap justify-content-between list-unstyled">
                <li>
                  <Link to="" className="text-white text-decoration-none">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="text-white text-decoration-none">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/faq" className="text-white text-decoration-none">
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link to="/privacy" className="text-white text-decoration-none">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="text-white text-decoration-none">
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link to="" className="text-white text-decoration-none">
                    Careers
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="text-white text-decoration-none">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <div className="d-flex justify-content-end">
                <img src={Appstore} alt="" width={130} className="ms-2" />
                <img src={Playstore} alt="" width={130} className="ms-2" />
              </div>
            </div>
          </div>
          <hr className="border-white border-2" />
          <div className="d-flex justify-content-between align-items-center py-3">
            <p className="text-white m-0">
              Instajamaica@ 2023 all right reserved{" "}
            </p>
            <div>
              <i class="pi pi-twitter text-white ms-3"></i>
              <i class="pi pi-instagram text-white ms-3"></i>
              <i class="pi pi-youtube text-white ms-3"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
