import { types } from "../types/types";

const intitalState = {
    getbusinessHoursProfile: [],
    createBusinessHoursProfile:{},
    workplaceProfile:[],
    getServicesProfile:[],
    cretaeServicesProfile:{},
    deleteServicesProfile:{},
};
export const getBusinessHoursReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.GET_BUSSINESS_HOURS:
            return {
                ...state,
                getbusinessHoursProfile: action.payload,
            };
        default:
            return { ...state };
    }
}
export const createBusinessHoursReducer =(state =intitalState,action)=>{
    switch(action.type){
        case types.CREATE_BUSINESS_HOURS:
            return {
                ...state,
                createBusinessHoursProfile: action.payload,
            };
            default:
            return { ...state };
    }
}
export const createWorkplaceReducer = (state=intitalState,action)=>{
    switch(action.type){
        case types.WORKPLACE:
            return{
                ...state,
                workplaceProfile:action.payload,
            };
            default:
                return {...state};
    }
}
export const getServicesReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.GET_SERVICES:
            return {
                ...state,
                getServicesProfile: action.payload,
            };
        default:
            return { ...state };
    }
}
export const createServiceReducer =(state=intitalState,action)=>{
    switch(action.type){
        case types.CREATE_SERVICE:
            return{
                ...state,
                cretaeServicesProfile:action.payload,
            };
            default:
                return {...state};
    }
}
export const deleteServiceReducer = (state=intitalState,action)=>{
    switch(action.type){
        case types.DELETE_SERVICES:
            return{
                ...state,
                deleteServicesProfile:action.payload,
            };
            default:
                return {...state};
    }
}