import React from "react";
import Banner from "./Banner";
import Recommended from "./Recommended";
import LocationService from "./LocationService";
import Inspired from "./Inspired";
import BookAppointment from "./BookAppointment";
import Chooseus from "./Chooseus";
import Specialist from "./Specialist";
import Recommend from "./Recommend";
import Layout from "./Layout";
import BookingDetails from "./BookingDetails";

export default function Home() {
  return (
    <div>
      <Layout>
        {/* banner */}
        <div className="bg-white-light">
          <Banner />
          <Recommended />
          <LocationService />
          <Inspired />
          <BookAppointment />
          <BookingDetails/>
          <Chooseus />
          <Specialist />
          <Recommend />
        </div>
      </Layout>
    </div>
  );
}
