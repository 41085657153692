import React, { useEffect, useState } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import MainButton from "../../../../components/buttons/MainButton";
import CustomModal from "../../../../components/Modal/CustomModal";
import { Avatar } from "primereact/avatar";
import PortfolioImg from "../../../../assets/images/prtfolio.png";
import CloseIcon from "../../../../assets/icons/close.png";
import { useSelector, useDispatch } from "react-redux";
import { getMyBooking,getBookingAction,cancelBooking } from "../../../../redux/clientAction/ProfileAction";
import { getBussinessByIdAction } from "../../../../redux/clientAction/allCategoriesAction";
import { useNavigate } from "react-router-dom";
import MapComponent from "../../../../pages/profile-setup/mapComponent";
export default function MyBooking() {
  const [showBookingDetail, setShowBookingDetail] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [date, setDate] = useState()
  const[completed,setCompleted] = useState()
  const[bookingPriceData,setBookingPriceData] =  useState(0)
  const[form,setForm] =  useState()
  const[islatitude,setIsLadtitude] =  useState()
  const[islongitude,setIsLogintude] = useState()
  const dispatch = useDispatch()
  const navigate =  useNavigate()
  const data = useSelector((state) => state.getMyBooking.clientGetMyBookingProfile)
  const getBooking =  useSelector((state)=>state.getBooking.clientGetBooking)
  const token =  localStorage.getItem("token")
  useEffect(() => {
    if(token){
      dispatch(getMyBooking())
    }
   
  }, [token])
  const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  useEffect(() => {
    let dateMonth = []
    let day 
    let Confirmeddata =[]
    
    if (data) {

      data?.length > 0 && data?.map((item) => {
      if( item.status === "CONFIRMED" || item.status === "UNCONFIRMED"  || item.status === "INPROGRESS" || item.status === "IN_PROGRESS" || item.status ==="NO_SHOW" ){
         const d = new Date(item.bookingDate);
        day = weekday[d.getDay()];
        let date = new Date(item.bookingDate)
        let dt = date.getDate();
        if (dt < 10) {
          dt = '0' + dt;
        }
      let obj = {
        dt:dt,
        business:item.business,
        services:item.services,
        startTime:item.startTime,
        status:item.status,
        days:day,
        id:item._id
      }
       dateMonth.push(obj)
      }else{
        const d = new Date(item.bookingDate);
        day = weekday[d.getDay()];
        let date = new Date(item.bookingDate)
        let dt = date.getDate();
        if (dt < 10) {
          dt = '0' + dt;
        }
        let obj = {
          dt:dt,
          business:item.business,
          services:item.services,
          startTime:item.startTime,
          status:item.status,
          days:day,
        }
        Confirmeddata.push(obj)
      }
      })
      setDate(dateMonth)
      setCompleted(Confirmeddata)
    }
    
  }, [data])
  const handleSubmit = (id) => {
    dispatch(getBussinessByIdAction(id), navigate(`/single-page/${id}`))
  }
  const handleBooking = (id)=>{
    dispatch(getBookingAction(id),setShowBookingDetail(true))
  }
  const booking = useSelector((state)=>state.getBooking.clientGetBooking)
 useEffect(()=>{
  let bookingprice = 0 ; 
  booking?.services?.length > 0 && booking?.services?.map((item,index)=>{
    bookingprice +=item.price
  })
  setBookingPriceData(bookingprice)
 },[booking])
 const handleCancelBooking = (id)=>{
  if(token){
    dispatch(cancelBooking(id),setShowCancelPopup(false)).then(()=>{
      dispatch(getMyBooking())
    })
  }

 }
  return (
    <div className="px-4">
      <p className="text-lg bg-main p-3 text-white curve mb-3">My Bookings</p>
      <Row>
        <Col sm={12}>
            {date?.length > 0 && date?.map((item)=>{
              return(
                <>
                {item.status === "CONFIRMED"  || item.status === "UNCONFIRMED"  || item.status === "INPROGRESS" || item.status === "IN_PROGRESS" || item.status ==="NO_SHOW" ?
                 <div
                 className="shadow p-3 mb-3 d-flex justify-content-between curve"
                 onClick={() => handleBooking(item.id)}
               >
                 <div >
                   <div className="d-flex">
                     <p className="bg-yellow text-golden py-1 px-3 curve text-xs">
                       {item.status}
                     </p>
                   </div>
                   {item?.services?.length > 0 && item?.services?.map((name, index) => {
                        return (
                          <>
                            <p className="text-lg text-dark fw-semibold">{name.serviceName}</p>
                          </>
                        )
                      })}
                   <p>{item?.business?.businessName}</p>
                 </div>
                 <div className="text-end">
                   <p className="text-gray text-sm">{item.days}</p>
                      <p className="text-lg text-dark fw-semibold">{item.dt}</p>
                  
                 
                   <p className="text-gray text-sm">{item?.startTime}</p>
                 </div>
               </div>:""
              }
                </>
              )
            })}
          <h4 className="text-main fw-semibold mb-3">Completed Appointments</h4>
          {completed?.length > 0 && completed.map((item, index) => {
            return (
              <>
                {item.status === "COMPLETED" ? <div className="shadow p-3 mb-3 curve">
                  <div className="d-flex justify-content-between ">
                    <div>
                      <div className="d-flex">
                        <p className="bg-new-gray text-gray py-1 px-3 curve text-xs">
                          {item?.status}
                        </p>
                      </div>
                      {item?.services?.length > 0 && item?.services?.map((name, index) => {
                        return (
                          <>
                            <p className="text-lg text-dark fw-semibold">{name.serviceName}</p>
                          </>
                        )
                      })}

                      <p>{item?.business?.businessName}</p>
                    </div>
                    <div className="text-end">
                      <p className="text-gray text-sm">{item.days}</p>
                      <p className="text-lg text-dark fw-semibold">{item.dt}</p>
                      <p className="text-gray text-sm">{item?.startTime}</p>
                    </div>
                  </div>
                  <MainButton label="Book Again"  onClick={()=>handleSubmit(item?.business?._id)}/>
                </div> : ""}
              </>

            )
          })}

        </Col>
      </Row>
      <CustomModal
        header={
          <div className="d-flex justify-content-center">
            <h5 className="fw-semibold">{booking?.startTime} Today</h5>
          </div>
        }
        visible={showBookingDetail}
        onHide={() => setShowBookingDetail(false)}
      >
        {booking?.services?.length > 0 && booking?.services?.map((item,index)=>{
          return(
            <>
            <MapComponent business = {getBooking} originValue={true}  inputbox= {true}form = {form} setForm={setForm} setIsLadtitude={setIsLadtitude} setIsLongitude={setIsLogintude} islatitude={booking?.business?.location?.coordinates[0]} islongitude ={booking?.business?.location?.coordinates[1]}/>
            <div className="d-flex bg-light-green p-3 rounded justify-content-between align-items-start mb-3">
            <div className="d-flex align-items-center ">
              <Avatar
                image={PortfolioImg}
                size="xlarge"
                className="rounded overflow-hidden"
              />
              <div className="ms-2">
                <p className="text-sm fw-semibold m-0">
                 {item.serviceName}
                </p>
                <small className="text-main text-xs">{item.durationTime} min</small>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <h5 className="fw-semibold m-0 text-main">${item.price}.00</h5>
            </div>
          </div>
          </>
          )
        })}
       
        <div className="d-flex justify-content-between px-3">
          <p className="text-gray text-lg fw-semibold">Total</p>
          <h5 className="text-main">${bookingPriceData}.00</h5>
        </div>
        <div className="px-3">
          <small className="fw-semibold text-dark">Customer note</small>
          <p className="text-gray mt-2">
            {booking.notes}
          </p>
        </div>
        <div className="d-flex justify-content-between px-3">
          <small className="text-dark fw-semibold">Your OTP</small>
          <h5 className="text-gray text-sm fw-semibold">{booking?.otp}</h5>
        </div>
        <div className="px-3">
          <small>Please give your OTP to the provider when asked.</small>
        </div>
        <div className="row justify-content-between p-3">
          <div className="col-6">
            <h4>${bookingPriceData}.00</h4>
          </div>
          <div className="col-6">
            <MainButton label="Cancel" onClick={() => setShowCancelPopup(true)} />
          </div>
        </div>
      </CustomModal>
      <CustomModal visible={showCancelPopup}
        onHide={() => setShowCancelPopup(false)}>
        <h5 className="fw-semibold text-center mb-3">Are you sure you want to cancel</h5>
        <Row>
          <Col>
            <MainButton label="Yes" className="bg-gray-color" onClick={() => {handleCancelBooking(booking._id);setShowBookingDetail(false)}} />
          </Col>
          <Col>
            <MainButton label="No" onClick={() => setShowCancelPopup(false)} />
          </Col>
        </Row>
      </CustomModal>
    </div>
  );
}
