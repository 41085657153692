import axios from "axios";
import ClientConstants from "./ClientConstants";
import PagesConstants from "./PagesConstants";
import { isAuthenticated } from "./authService";
const successStatus = [200, 201, 204];
export const include = (arr, con) => arr && arr.includes(con)
export const clientApi = (method, urlEndPoint, data = null) =>

    new Promise((myResolve) => {
        let headers = {
            "Content-Type": "application/json",
        };
        if (isAuthenticated()) {
            headers = {
                ...headers,
                Authorization: `Bearer ${isAuthenticated()}`,
            };
        }
        axios({
            method,
            url: ClientConstants.CLIENT_BASE_URL + urlEndPoint,
            data,
            headers,
        })
            .then((response) => {
                myResolve({
                    message: response.data.message,
                    data: response.data.data,
                    success: response.data.success,
                    statusCode: include(successStatus, response.status),
                });
            })
            .catch((err) => {
                if (err?.response?.data?.message) {
                    myResolve({
                        message: err?.response?.data?.message,
                        statusCode: false,
                    });
                }
                myResolve({
                    message: err?.response?.data,
                    statusCode: false,
                });
            });
    });

export const formaDataapi = (method, urlEndPoint, data = null) =>
new Promise((myResolve) => {
    let headers = {
        "Content-Type": "multipart/form-data",
    };
    if (isAuthenticated()) {
        headers = {
            ...headers,
            Authorization: `Bearer ${isAuthenticated()}`,
        };
    }
    axios({
        method,
        url: ClientConstants.CLIENT_BASE_URL + urlEndPoint,
        data,
        headers,
    })
        .then((response) => {
            myResolve({
                message: response.data.message,
                data: response.data.data,
                success: response.data.success,
                statusCode: include(successStatus, response.status),
            });
        })
        .catch((err) => {
            if (err?.response?.data?.message) {
                myResolve({
                    message: err?.response?.data?.message,
                    statusCode: false,
                });
            }
            myResolve({
                message: err?.response?.data,
                statusCode: false,
            });
        });
});

export const deleteapi = (method, urlEndPoint, data = null) =>

    new Promise((myResolve) => {
        let headers = {
            "Content-Type": "application/json",
        };
        if (isAuthenticated()) {
            headers = {
                ...headers,
                Authorization: `Bearer ${isAuthenticated()}`,
            };
        }
        axios({
            method,
            url: ClientConstants.CLIENT_BASE_URL + urlEndPoint,
            headers,
        })
            .then((response) => {
                myResolve({
                    message: response.data.message,
                    data: response.data.data,
                    success: response.data.success,
                    statusCode: include(successStatus, response.status),
                });
            })
            .catch((err) => {
                if (err?.response?.data?.message) {
                    myResolve({
                        message: err?.response?.data?.message,
                        statusCode: false,
                    });
                }
                myResolve({
                    message: err?.response?.data,
                    statusCode: false,
                });
            });
    });


export const clientBussiness = (method, urlEndPoint, data = null) =>

    new Promise((myResolve) => {
        let headers = {
            "Content-Type": "application/json",
        };
        if (isAuthenticated()) {
            headers = {
                ...headers,
                Authorization: `Bearer ${isAuthenticated()}`,
            };
        }
        axios({
            method,
            url: ClientConstants.CLIENT_BASE_URL + urlEndPoint,
            // data,
            headers,
        })
            .then((response) => {
                myResolve({
                    message: response.data.message,
                    data: response.data.data,
                    success: response.data.success,
                    statusCode: include(successStatus, response.status),
                });
            })
            .catch((err) => {
                if (err?.response?.data?.message) {
                    myResolve({
                        message: err?.response?.data?.message,
                        statusCode: false,
                    });
                }
                myResolve({
                    message: err?.response?.data,
                    statusCode: false,
                });
            });
    });

export const pagesApi = (method, urlEndPoint, data = null) =>

    new Promise((myResolve) => {
        let headers = {
            "Content-Type": "application/json",
        };
        if (isAuthenticated()) {
            headers = {
                ...headers,
                Authorization: `Bearer ${isAuthenticated()}`,
            };
        }
        axios({
            method,
            url: PagesConstants.CLIENT_BASE_URL + urlEndPoint,
            data,
            headers,
        })
            .then((response) => {
                myResolve({
                    message: response,
                    data: response,
                    success: response,
                    statusCode: include(successStatus, response),
                });
            })
            .catch((err) => {
                if (err?.response?.data?.message) {
                    myResolve({
                        message: err?.response?.data?.message,
                        statusCode: false,
                    });
                }
                myResolve({
                    message: err?.response?.data,
                    statusCode: false,
                });
            });
    });