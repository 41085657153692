import React, { useState } from "react";
import Layout from "../../layout";
import { Card } from "react-bootstrap";
import Logo from "../../assets/icons/Logo.png";
import { Link } from "react-router-dom";
import FacebookIcon from "../../assets/icons/facebook.png";
import GoogleIcon from "../../assets/icons/google.png";
import AppleIcon from "../../assets/icons/Apple.png";
import MainButton from "../../components/buttons/MainButton";
import { showToast } from "../../redux/action/toastAction";
import { loginAction } from "../../redux/action/loginAction";
import { Password } from "primereact/password";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import LayoutSidebar from "../../layout/LayoutSidebar";
export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!form.email || !form.password) {
      return;
    }
    dispatch(loginAction(form, navigate))
  };

  return (
    <div className="">
      <LayoutSidebar>
        <div className=" background-all d-flex justify-content-center align-items-center">
          <Card className="logincards border-0">
            <div className="p-4 login">
              <img src={Logo} alt="logo" className="d-block mx-auto mt-5" />

              <div className=" mt-4 ">
                <h4 className="heading text-center fw-semibold">
                  Login into InstaJamica
                </h4>
              </div>
              <div className="mt-3">
                <span className="p-input-icon-left w-100">
                  <i className="pi pi-envelope" />
                  <InputText
                    value={form?.email}
                    id="email"
                    placeholder="Email"
                    type="email"
                    onChange={(e) => {
                      setField("email", e.target.value);
                    }}
                  />
                </span>
              </div>
              <div className="mt-3">
                <span className="p-input-icon-left" style={{ width: "100%" }}>
                  <i className="pi pi-lock" style={{ zIndex: "1" }} />
                  <Password
                    value={form?.password}
                    placeholder="Password"
                    onChange={(e) => {
                      setField("password", e.target.value);
                    }}
                    toggleMask
                    feedback={false}
                  />
                </span>
              </div>
              <div className="text-end mt-4 ">
                <Link
                  to="/provider/forget-password"
                  className="text-decoration-none text-end"
                >
                  &nbsp;
                  <span className=" h6 text-end text-main">
                    Forgot Password?
                  </span>
                </Link>
              </div>
              <div className="my-3 d-block ">
                <Link to="">
                  <MainButton label="Login" className="border-0" onClick={handleSubmit} />
                </Link>
              </div>
              <div>
                <p className="sub-heading position-relative text-center">
                  or continue with
                </p>
              </div>
              <div className="d-flex justify-content-center my-1">
                <div className="logos border cursor-pointer">
                  <img src={FacebookIcon} alt="" />
                </div>
                <div className="logos border cursor-pointer ms-3">
                  <img src={GoogleIcon} alt="" />
                </div>
                <div className="logos border cursor-pointer ms-3">
                  <img src={AppleIcon} alt="" />
                </div>
              </div>
              <div className="d-flex justify-content-center my-3">
                <p className="sub-heading-2">Don’t have an account?</p>&nbsp;
                <Link
                  to="/provider/signup"
                  className="link text-main text-decoration-none"
                >
                  <span className="sign-text">Sign up</span>
                </Link>
              </div>
            </div>
          </Card>
        </div>
      </LayoutSidebar>
    </div>
  );
}
