import React from "react";
import Layout from "../../Layout";
import AboutData from "./AboutData";
import TabConatiner from "../profile/TabContainer";
export default function AboutInsta() {
  const { AboutDetail } = AboutData();
  return (
    <div>
      <Layout>
        <TabConatiner/>
        <div className="banner p-5">
          <h1 className="display-3 d-flex justify-content-center align-items-center h-100 fw-bold text-main">
            About Us
          </h1>
        </div>
        <div className="container position-relative py-4">
          {AboutDetail?.map((items, key) => {
            return (
              <div
                className={`row justify-content-between align-items-center row-reverse py-5`}
              >
                <div className="col-md-5">
                  <div>
                    <h2 className="fw-bold">{items.heading}</h2>
                    <p className="text-gray">{items.description}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="curve img-box overflow-hidden">
                    <img
                      src={items.img}
                      alt=""
                      className="w-100 h-100 cover-img"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="bg-light-color py-5">
          <div className="container">
            <h2 className="fw-bold text-center mb-4">
              Schedule Your Appointment in Just 10 Seconds
            </h2>
            <iframe className="m-auto d-block curve" width="60%" height="315" src="https://www.youtube.com/embed/jHr9eyCi4MA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
      </Layout>
    </div>
  );
}
