import LookAroundImg from "../../../../assets/images/look.png";
import AppointmentImg from "../../../../assets/images/img.png";
import NotifyImg from "../../../../assets/images/img1.png";

const AboutData = () => {
  const AboutDetail = [
    {
      img: LookAroundImg,
      heading: "Look Around",
      description:
        "InstaJamaica makes it easy to find appointments with local beauty, wellness, and health professionals. Find your favorite spot or discover new businesses through our marketplace. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    },
    {
      img: AppointmentImg,
      heading: "Appointments, Anytime",
      description: `Book services instantly through the InstaJamaica App and avoid
        the back-and-forth phone calls during business hours. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`,
    },
    {
      img: NotifyImg,
      heading: "Get Notified",
      description: `Automated reminders ensure you never forget upcoming
        appointments. Use the app to change and manage your appointment. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`,
    },
  ];

  return { AboutDetail };
};
export default AboutData;
