import React from "react";
import { Dropdown } from "primereact/dropdown";

export default function DropDownComponent({
  label,
  name,
  labelClassName,
  errorMessage,
  extraClassName,
  required,
  inputClass,
  ...props
}) {
  return (
    <div className={extraClassName}>
      {label ? (
        <label
          htmlFor={name}
          className={`text-sm text-gray form-label ${labelClassName}`}
        >
          {label} {required && <span className="p-error">*</span>}
        </label>
      ) : null}
      <Dropdown
        id={name}
        name={name}
        className={`w-100 rounded ${inputClass} `}
        {...props}
      />
    </div>
  );
}
