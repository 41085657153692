import Portfolio from "../../assets/images/iamge7.png";
const ProfileData = () => {
  const PortfolioData = [
    {
      itemImageSrc:
        "https://primefaces.org/cdn/primereact/images/galleria/galleria1.jpg",
      thumbnailImageSrc:
        "https://primefaces.org/cdn/primereact/images/galleria/galleria1s.jpg",
    },
    {
      itemImageSrc: Portfolio,
      thumbnailImageSrc: Portfolio,
    },
    {
      itemImageSrc:
        "https://primefaces.org/cdn/primereact/images/galleria/galleria1.jpg",
      thumbnailImageSrc:
        "https://primefaces.org/cdn/primereact/images/galleria/galleria1s.jpg",
    },
  ];

  
  return { PortfolioData };
};
export default ProfileData;
