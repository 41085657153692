import React from "react";
import Layout from "../../layout";
import { Link } from "react-router-dom";
import MainButton from "../../components/buttons/MainButton";
import ProfileImg from "../../assets/images/profileimg.png";
import ImageProtfolio from "../../assets/images/profile.png";
import ImageProtfolio1 from "../../assets/images/iamge7.png";
import ImageProtfolio2 from "../../assets/images/image1.png";
import ImageProtfolio3 from "../../assets/images/image3.png";
import { Avatar } from "primereact/avatar";
import StarIcon from "../../assets/icons/star.png";

export default function Profile() {
  return (
    <div>
      <Layout>
        <div className="p-5">
          <div className="d-flex mb-3">
            <h4 className="text-main fw-bold mb-0 ms-3">Profile</h4>
          </div>

          <div className="row text-center align-items-end mb-3">
            <div className="col-md-10">
              <div className="profile-header rounded d-flex align-items-end"></div>
              <div className="d-flex align-items-center avatar-detail mb-3">
                <div className="avatarimg rounded-circle">
                  <img src={ProfileImg} className="w-100 cover-img" />
                </div>
                <h5 className="fw-semibold ms-3 mt-4">Ac Repair</h5>
              </div>
            </div>
            <div className="col-md-10">
              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="bg-white rounded p-3">
                    <div className="d-flex justify-content-between mb-4">
                      <h5 className="text-main fw-semibold m-0">Portfolio</h5>
                      <Link
                        to="/portfolio"
                        className="text-gray fw-semibold text-decoration-none"
                      >
                        View all
                      </Link>
                    </div>
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <img
                          src={ImageProtfolio}
                          className="w-100 cover-img rounded"
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src={ImageProtfolio1}
                          className="w-100 cover-img rounded"
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src={ImageProtfolio2}
                          className="w-100 cover-img rounded"
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src={ImageProtfolio3}
                          className="w-100 cover-img rounded"
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src={ImageProtfolio}
                          className="w-100 cover-img rounded"
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src={ImageProtfolio1}
                          className="w-100 cover-img rounded"
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src={ImageProtfolio2}
                          className="w-100 cover-img rounded"
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src={ImageProtfolio3}
                          className="w-100 cover-img rounded"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="bg-white rounded p-3">
                    <div className="d-flex justify-content-between mb-4">
                      <h5 className="text-main fw-semibold m-0">
                        Workplace photos
                      </h5>
                      <Link
                        to=""
                        className="text-gray fw-semibold text-decoration-none"
                      >
                        View all
                      </Link>
                    </div>
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <img
                          src={ImageProtfolio}
                          className="w-100 cover-img rounded"
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src={ImageProtfolio1}
                          className="w-100 cover-img rounded"
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src={ImageProtfolio2}
                          className="w-100 cover-img rounded"
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src={ImageProtfolio3}
                          className="w-100 cover-img rounded"
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src={ImageProtfolio}
                          className="w-100 cover-img rounded"
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src={ImageProtfolio1}
                          className="w-100 cover-img rounded"
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src={ImageProtfolio2}
                          className="w-100 cover-img rounded"
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src={ImageProtfolio3}
                          className="w-100 cover-img rounded"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-10">
              <div className="bg-white p-3 rounded">
                <div className="d-flex justify-content-between mb-4">
                  <h5 className="text-main fw-semibold m-0">Recent Reviews</h5>
                  <Link
                    to="/reviews"
                    className="text-gray fw-semibold text-decoration-none"
                  >
                    View all
                  </Link>
                </div>
                <div className="row">
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-4">
                          <div className="d-flex align-items-center justify-content-between mb-2">
                            <div className="d-flex align-items-center">
                              <Avatar
                                image={ProfileImg}
                                size="xlarge"
                                shape="circle"
                              />
                              <p className="fw-bold mb-0 ms-2">John</p>
                            </div>
                            <div className="rating d-flex align-items-center rounded-pill px-3 text-main p-1">
                              <img src={StarIcon} width={15} />
                              <p className="ms-2 mb-0">5</p>
                            </div>
                          </div>
                          <div className="text-start">
                            <h6 className="text-sm mb-2">
                              The people who work here are very friendly and
                              professional, I really like it! 👍👍
                            </h6>
                            <small className="text-gray">1 months ago</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <div className="d-flex align-items-center justify-content-between mb-2">
                            <div className="d-flex align-items-center">
                              <Avatar
                                image={ProfileImg}
                                size="xlarge"
                                shape="circle"
                              />
                              <p className="fw-bold mb-0 ms-2">John</p>
                            </div>
                            <div className="rating d-flex align-items-center rounded-pill px-3 text-main p-1">
                              <img src={StarIcon} width={15} />
                              <p className="ms-2 mb-0">5</p>
                            </div>
                          </div>
                          <div className="text-start">
                            <h6 className="text-sm mb-2">
                              The people who work here are very friendly and
                              professional, I really like it! 👍👍
                            </h6>
                            <small className="text-gray">1 months ago</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <div className="d-flex align-items-center justify-content-between mb-2">
                            <div className="d-flex align-items-center">
                              <Avatar
                                image={ProfileImg}
                                size="xlarge"
                                shape="circle"
                              />
                              <p className="fw-bold mb-0 ms-2">John</p>
                            </div>
                            <div className="rating d-flex align-items-center rounded-pill px-3 text-main p-1">
                              <img src={StarIcon} width={15} />
                              <p className="ms-2 mb-0">5</p>
                            </div>
                          </div>
                          <div className="text-start">
                            <h6 className="text-sm mb-2">
                              The people who work here are very friendly and
                              professional, I really like it! 👍👍
                            </h6>
                            <small className="text-gray">1 months ago</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <div className="d-flex align-items-center justify-content-between mb-2">
                            <div className="d-flex align-items-center">
                              <Avatar
                                image={ProfileImg}
                                size="xlarge"
                                shape="circle"
                              />
                              <p className="fw-bold mb-0 ms-2">John</p>
                            </div>
                            <div className="rating d-flex align-items-center rounded-pill px-3 text-main p-1">
                              <img src={StarIcon} width={15} />
                              <p className="ms-2 mb-0">5</p>
                            </div>
                          </div>
                          <div className="text-start">
                            <h6 className="text-sm mb-2">
                              The people who work here are very friendly and
                              professional, I really like it! 👍👍
                            </h6>
                            <small className="text-gray">1 months ago</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
