import React from "react";
import { Card } from "primereact/card";
export default function MemberCard({
  title,
  price,
  children,
  icon,
  description,
  cardclass,
  headingclass,
  priceclass,
}) {
  return (
    <div className={`membership-card ${cardclass}`}>
      <div className="p-3 text-center">
        <h2 className={`text-lg text-main fw-bold ${headingclass}`}>{title}</h2>
        <h1 className={`text-main fw-bold ${priceclass}`}>{price}</h1>
        {children}
      </div>
    </div>
  );
}
