import React, { useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { Link } from "react-router-dom";
import MainButton from "../../components/buttons/MainButton";
import ChevronrightIcon from "../../assets/icons/chevronright.png";
import { Dialog } from "primereact/dialog";
import DropDownComponent from "../../components/input/DropDownComponent";
import DeleteIcon from "../../assets/icons/deleteicon.png";
import { ProgressBar } from "primereact/progressbar";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getBusinessHours } from "../../redux/action/businessHoursAction";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { createBusinessHours } from "../../redux/action/businessHoursAction";
import { useNavigate } from "react-router-dom";
import LayoutSidebar from "../../layout/LayoutSidebar";
import * as moment from 'moment';

export default function BussinessHours() {
  const data = useSelector((state) => state?.getBusinessHours?.getbusinessHoursProfile)
  const navigate =  useNavigate()
  const [checked, setChecked] = useState(true);
  const [toggleValue, setToogleValue] = useState({
    0: true, 1: true, 2: true, 3: true, 4: true, 5: true, 6: true
  })
  const[date,setDate] = useState();
  const[endTimeSolts,setEndTimeSolts] = useState([]);
  const[startBreakTime,setStartBreakTime] =useState([])
  const [hoursvisible, setHoursVisible] = useState(false);
  const [EndTime,setEndTime] =  useState([]) 
  const [weakName, setWeakName] = useState("")
  const generateTimeSlots = async (hh, mm, ss) => {
    const startTime = new Date();
    startTime.setHours(0, 0, 0); 

    const endTime = new Date();
    endTime.setHours(23, 30, 0);

    const genTimeSlots = [];
    while (startTime <= endTime) {
      const hours = startTime.getHours().toString().padStart(2, '0');
      const minutes = startTime.getMinutes().toString().padStart(2, '0');
      let timeSlot = `${hours}:${minutes}`;
      timeSlot = await tConvert(timeSlot)
      genTimeSlots.push(timeSlot);
      startTime.setMinutes(startTime.getMinutes() + 30);
    }
    setEndTimeSolts(() => {
      return [
        ...genTimeSlots,
        "11:59 PM"
      ]
    })
    // setEndTime(()=>{
    //   return [
    //     ...breakTimeSlots,
    //     "11.59 PM"
    //   ]
    // })
    setDate(genTimeSlots);
    setStartBreakTime(genTimeSlots)
  };
  useEffect(() => {
    generateTimeSlots();
  }, []);
  useEffect(() => {
    data.timings?.map((item) => {
      setWeekData((prev) => {
        return {
          ...prev,
          [item.weekDay]: {
            weekDay: item.weekDay,
            isClosed: item.isClosed,
            startTime: item.startTime,
            breakStartTime: item.breakStartTime,
            breakEndTime: item.breakEndTime,
            endTime: item.endTime
          }
        }
      })
    })
  }, [data])

  const [weekData, setWeekData] = useState({
    0: {
      weekDay: "",
      isClosed: "",
      startTime: "",
      breakStartTime: "",
      breakEndTime: "",
      endTime: ""
    },
    1: {
      weekDay: "",
      isClosed: "",
      startTime: "",
      breakStartTime: "",
      breakEndTime: "",
      endTime: ""
    },
    2: {
      weekDay: "",
      isClosed: "",
      startTime: "",
      breakStartTime: "",
      breakEndTime: "",
      endTime: ""
    },
    3: {
      weekDay: "",
      isClosed: "",
      startTime: "",
      breakStartTime: "",
      breakEndTime: "",
      endTime: ""
    },
    4: {
      weekDay: "",
      isClosed: "",
      startTime: "",
      breakStartTime: "",
      breakEndTime: "",
      endTime: ""
    },
    5: {
      weekDay: "",
      isClosed: "",
      startTime: "",
      breakStartTime: "",
      breakEndTime: "",
      endTime: ""
    },
    6: {
      weekDay: "",
      isClosed: "",
      startTime: "",
      breakStartTime: "",
      breakEndTime: "",
      endTime: ""
    },
  })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getBusinessHours())
  }, [])

  const handleToogleChange = (e, index) => {
    const { name, value } = e.target
    setToogleValue((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
    setChecked(e.value)
    setWeekData((prev) => {
      return {
        ...prev,
        [name]: {
          ...weekData[name],
          isClosed: value,
        }
      }
    })
  }
  const handleDialogOpen = (item) => {
    setWeakName(item)
    setHoursVisible(true)
  }
  const handleChange = async (e) => {
    const { name, value } = e.target;
    let endTime = "";
    if(name === "startTime"){
      endTime = await gernateEndTime(value, 30);
    } else if(name === "endTime"){
      endTime = value
    }
    setWeekData((prev) => {
      return {
        ...prev,
        [weakName]: {
          ...weekData[weakName],
          [name]: value,
          weekDay: weakName,
          endTime: endTime ? endTime : weekData[weakName].endTime,
        }
      }
    })
  }

  const gernateEndTime =  async (time,minutestoadd)=>{
    time = moment(time, "h:mm A").add(minutestoadd, 'minutes').format("h:mm A");
    if(time === "12:00 AM") {
      time = "11:59 PM";
      setEndTimeSolts(["11:59 PM"]);
    } else {
      const dateClone = [...date];
      let endTime = dateClone.slice(date.indexOf(time));
      endTime = [...endTime, "11:59 PM"]
      setEndTimeSolts(endTime);

    }
    return time;
  }
  const tConvert = async (time) => {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }

  const handleSubmit = () => {
    let data = []
    Object.values(weekData).map((item) => {
      data.push(item)
    })

    let payload = {
      timings: data
    }
    dispatch(createBusinessHours(payload,()=>navigate("/provider/add-workplace")));
  }
  return (
    <div>
      <LayoutSidebar>
        <div className=" background-all">
          <div className="row p-0" style={{ height: "100vh" }}>
            <div className="col-md-6 m-0 img-signup h-100 position-relative p-0">
              <div className=" h-100 d-flex align-items-center justify-content-center">
                <h1 className="text-white position-relative fw-bold">
                  Setup Your Business <br />
                  With InstaJamaica
                </h1>
              </div>
            </div>
            <div className="col-md-6 m-0">
              <div className="d-flex align-items-center justify-content-center h-100">
                <Card className="border-0" style={{ width: "30rem" }}>
                  <ProgressBar value={20} style={{ height: "6px" }}></ProgressBar>
                  <Card.Body className="p-5">
                    <div>
                      <div className="d-block  mt-2 ">
                        <i className="pi pi-arrow-left text-main fs-3 mb-2 cursor-pointer" onClick={()=>navigate("/provider/profile-setup")}></i>
                        <h4 className="text-main fw-bold mb-0">Your Business Hours</h4>
                        <small className="text-xs mb-3">When can clients book with you?</small>
                        <div className="mb-3">
                          <div className="row border-bottom py-3 align-items-center">
                          </div>
                          {data?.timings?.length > 0 && data?.timings.map((item, index) => {
                            return (
                              <div
                                className="row border-bottom py-3 align-items-center cursor-pointer"

                              >
                                <div className="col-3">
                                  <InputSwitch name={`${index}`}
                                    checked={weekData[item.weekDay].isClosed} onChange={(e) => handleToogleChange(e, index)}
                                  />
                                </div>

                                <div className="col-3">
                                  <p className="text-sm fw-bold mb-0">{item.weekDay === "0" ? "Sunday" : item.weekDay === "1" ? "Monday" : item.weekDay === "2" ? "Tuesday" : item.weekDay === "3" ? "Wednesday" : item.weekDay === "4" ? "Thursday" : item.weekDay === "5" ? "Friday" : item.weekDay === "6" ? "Saturday" : null}</p>
                                </div>
                                {weekData[item.weekDay].isClosed === true ? <div className="col-6 d-flex justify-content-between">
                                  <div className="">
                                    <p className=" mb-0 text-sm">{weekData[item.weekDay].startTime} - {weekData[item.weekDay].endTime}</p>
                                    <p className=" mb-0 text-xs">Break{weekData[item.weekDay].breakStartTime} - {weekData[item.weekDay].breakEndTime}</p>
                                  </div>
                                  <div className="" onClick={() => handleDialogOpen(item.weekDay)}>
                                    <img alt = ""src={ChevronrightIcon} width={6} />
                                  </div>
                                </div>
                                  : <div className="col-5">
                                    <p className=" mb-0 text-sm">Closed</p>
                                  </div>
                                }
                              </div>
                            )
                          })}

                        </div>
                        <Link to="">
                          <MainButton label="Continue" onClick={handleSubmit} />
                        </Link>
                      </div>
                      <Dialog
                        header={
                          <div className="d-flex align-items-center">
                            <i className="pi pi-arrow-left text-main fs-6 mb-2 cursor-pointer" onClick={()=>setHoursVisible(false)}></i>
                            <h4 className="text-main fw-bold mb-0 ms-3">{weakName === "0" ? "Sunday" : weakName === "1" ? "Monday" : weakName === "2" ? "Tuesday" : weakName === "3" ? "Wednesday" : weakName === "4" ? "Thursday" : weakName === "5" ? "Friday" : weakName === "6" ? "Saturday" : null}</h4>
                          </div>
                        }
                        visible={hoursvisible}
                        style={{ width: "50vw" }}
                        onHide={() => setHoursVisible(false)}
                      >
                        <p className="m-0 text-gray-light">
                          Set your business hours here. Head to your calendar if you need to
                          adjust hours for a single day.
                        </p>
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-3">
                            <p className="text-sm fw-bold m-0">Opening hours</p>
                          </div>
                          <div className="col-3">
                            <DropDownComponent
                              placeholder="10:00 AM"
                              value={weekData[weakName]?.startTime}
                              name="startTime"
                              onChange={handleChange}
                              options={date}
                            />
                          </div>
                          <div className="col-3">
                            <DropDownComponent
                              placeholder="07:00 PM"
                              value={weekData[weakName]?.endTime}
                              name="endTime"
                              onChange={handleChange}
                              options={endTimeSolts}
                            />
                          </div>
                          <div className="col-1"></div>
                        </div>
                        <span className="text-main cursor-pointer">Add Break</span>
                        <hr />
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-3">
                            <p className="text-sm fw-bold m-0">Break</p>
                          </div>
                          <div className="col-3">
                            <DropDownComponent
                              placeholder="10:00 AM"
                              value={weekData[weakName]?.breakStartTime}
                              name="breakStartTime"
                              onChange={handleChange}
                              options={startBreakTime}
                            />
                          </div>
                          <div className="col-3">
                            <DropDownComponent
                              placeholder="10:00 AM"
                              value={weekData[weakName]?.breakEndTime}
                              name="breakEndTime"
                              onChange={handleChange}
                              options={endTimeSolts}
                            />
                          </div>
                          {/* <div className="col-1">
                            <img src={DeleteIcon} width={25} alt = "" />
                          </div> */}
                        </div>
                        <div className="row align-items-center justify-content-between mb-4">
                          {/* <div className="col-3">
                            <p className="text-sm fw-bold m-0">Break</p>
                          </div> */}
                          {/* <div className="col-3">
                            <DropDownComponent
                              placeholder="10:00 AM"
                              value={weekData[weakName]?.breakStartTime}
                              name="breakStartTime"
                              onChange={handleChange}
                              options={date}
                            />
                          </div>
                          <div className="col-3">
                            <DropDownComponent
                              placeholder="10:00 AM"
                              value={weekData[weakName]?.breakEndTime}
                              name="breakEndTime"
                              onChange={handleChange}
                              options={date}
                            />
                          </div> */}
                          {/* <div className="col-1">
                            <img src={DeleteIcon} width={25}alt = "" />
                          </div> */}
                        </div>
                        <div className="d-flex justify-content-center">
                          <MainButton label="Cancel" className="bg-white cancelbtn text-gray fw-semibold" onClick={()=>setHoursVisible(false)} />
                          <MainButton label="Save" className="ms-2 fw-semibold"onClick={()=>setHoursVisible(false)}/>
                        </div>
                      </Dialog>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </LayoutSidebar>
    </div>

  );
}
