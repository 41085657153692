import React, { useState, useRef } from "react";
import InputTextComponent from "../../components/input/InputTextComponent";
import PasswordComponent from "../../components/input/PasswordComponent";
import MainButton from "../../components/buttons/MainButton";
import { Checkbox } from "primereact/checkbox";
import { Link } from "react-router-dom";
import { requestotpAction } from "../../redux/action/requestotpAction";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/action/toastAction";
import ArrowRight from "../../assets/icons/arrow-right.png"
function CreateAccount({ setSelectedStep, setErrors, setForm, form, errors }) {
  const dispatch = useDispatch()
  const [checked, setChecked] = useState(false);

  const emailRegex =
    new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
  const isValidEmail = emailRegex.test(form.email);

  const handleChange = (e) => {
    setForm({
      ...form,
      email: e.target.value
    });
  };

  const handleRequestOtp = (e) => {
    if (isValidEmail) {
      dispatch(requestotpAction(form, () => setSelectedStep("3")))
    }
    else {
      dispatch(showToast({ severity: "error", summary: "Please Enter Valid Email Address", content: "Please Enter Valid Email Address" }));
    }
  }
  const handleSubmitOtp = () => {
    if (form.firstName === "" || form.lastName === "" || form.email === "" || form.password === "" || form.confirmPassword === "" ) {
      dispatch(showToast({ severity: "error", summary: "Required all Fields", content: "Required all Fields" }));
    }
    else if (form.password !==form.confirmPassword){
      dispatch(showToast({ severity: "error", summary: "Password Can't match", content: "Password Can't match" }));
    }
    else if(checked === false){
      dispatch(showToast({ severity: "error", summary: "Please Accept Terms & Conditions", content: "Please Accept Terms & Conditions"}));
    }
    else {
      handleRequestOtp()
    }
  }
  return (
    <>
          <img src = {ArrowRight} alt ="" onClick={()=>setSelectedStep("1")}/>
      <div className="d-block  mt-2 ">
        {/* <i className="pi pi-arrow-left text-main fs-3 mb-2 cursor-pointer"></i> */}
        <h4 className="text-main fw-bold"> Create Your Account</h4>
        <div className="mt-2">
          <InputTextComponent
            extraClassName="mb-3"
            placeholder="First name"
            name="firstname"
            value={form?.firstName}
            onChange={(e) => {
              setErrors({
                ...errors,
                firstName: null,
              });
              setForm({ ...form, firstName: e.target.value });
            }}

          />
          {errors.firstName && <span className="error">{errors.message}</span>}
          <InputTextComponent
            extraClassName="mb-3"
            placeholder="Last name"
            name="lastname"
            value={form?.lastName}
            onChange={(e) => {
              setErrors({
                ...errors,
                lastName: null
              });
              setForm({ ...form, lastName: e.target.value })
            }}
          />
          {errors.lastName && <span className="error">{errors.lastName}</span>}
          <InputTextComponent
            extraClassName="mb-3"
            placeholder="Email"
            name="email"
            value={form?.email}
            onChange={(e) => { handleChange(e) }}
          />
          {errors.email && <span className="error">{errors.email}</span>}
          <PasswordComponent extraClassName="mb-3"
            placeholder="Password"
            name="password"
            value={form?.password}
            onChange={(e) => {
              setErrors({
                ...errors,
                password: null
              });
              setForm({ ...form, password: e.target.value })
            }}
          />
          {errors.password && <span className="error">{errors.password}</span>}
          <PasswordComponent
            extraClassName="mb-3"
            placeholder="Confirm Password"
            name="confirmPassword"
            value={form?.confirmPassword}
            onChange={(e) => {
              setErrors({
                ...errors,
                confirmPassword: null
              });
              setForm({
                ...form,
                confirmPassword: e.target.value
              })
            }}
          />
          {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}
        </div>
        <div className="d-flex align-items-baseline mb-2">
          <div className="mt-3 m-2">
            <Checkbox
              onChange={(e) => setChecked(e.checked)}
              checked={checked}
            ></Checkbox>
          </div>
          <div>
            <span className="conditions ">By signing up I agree to the </span>
            <strong className="st-conditions">Terms & Conditions</strong>
            <div className="mt-1  ">
              <span className="conditions">and to the</span>
              <strong className="st-conditions"> Privacy Policy</strong>
            </div>
          </div>
        </div>
        <Link to="">
          <MainButton label="Sign up" onClick={handleSubmitOtp} />
        </Link>
      </div>
    </>
  );
}

export default CreateAccount;
