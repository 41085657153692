import React from "react";
import { Chip } from "primereact/chip";
import { Avatar } from 'primereact/avatar';

export default function Payments() {
  return (
    <div>
      <div className="row mb-3 align-items-baseline">
        <div className="col-md-6 d-flex align-items-center">
          <Avatar icon="pi pi-user" size="xlarge" shape="circle" />
          <div className="ms-2">
            <Chip label="Paid" className="bg-main text-white px-3" />
            <p className="m-0">Deep clean Ac Service (window)</p>
          </div>
        </div>
        <div className="col-md-6 text-end">
          <p className="fw-bold text-main m-0">$25.00</p>
        </div>
      </div>
    </div>
  );
}
