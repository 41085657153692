import { Avatar } from "primereact/avatar";
import React, { useState, useEffect } from "react";
import DrayerImg from "../../../../assets/images/drayer.png";
import CustomModal from "../../../../components/Modal/CustomModal";
import { Rating } from "primereact/rating";
import InputTextAreaComponent from "../../../../components/input/InputTextAreaComponent";
import MainButton from "../../../../components/buttons/MainButton";
import { useSelector } from "react-redux";
import { addReviewAction } from "../../../../redux/clientAction/ProfileAction";
import { useDispatch } from "react-redux";
import { getBussinessByIdAction } from "../../../../redux/clientAction/allCategoriesAction";
export default function BookingHistory() {
  const [showReview, setShowReview] = useState(false);
  const [bookingData, setBookingData] = useState()
  const dispatch = useDispatch()
  const booking = useSelector((state) => state.getMyBooking.clientGetMyBookingProfile)
  const business =  useSelector((state)=>state.clientgetBussinessById.getBussinessByIdProfile)
  const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const [data, setData] = useState({
    rating: "",
    review: "",
    images: []
  })
  const[error,setError] =  useState({})
  useEffect(() => {
    let Confirmeddata = []
    let day
    if (booking) {
      booking?.length > 0 && booking?.map((item) => {
        const d = new Date(item.bookingDate);
        day = weekday[d.getDay()];
        let date = new Date(item.bookingDate)
        let dt = date.getDate();
        if (dt < 10) {
          dt = '0' + dt;
        }
        let obj = {
          dt: dt,
          business: item.business,
          services: item.services,
          startTime: item.startTime,
          status: item.status,
          days: day,
          id: item._id
        }
        Confirmeddata.push(obj)
      })
      setBookingData(Confirmeddata)
    }
  }, [booking])
  const handleGetBusiness = (id)=>{
    dispatch(getBussinessByIdAction(id),setShowReview(true))
  }
 const handleReview = (id)=>{
  if(data.review === ""){
    setError({
      ...error,
      review:data.review === ""?"Please Enter Review":error.review
    })
    return false;
  }
  dispatch(addReviewAction(data,id),setShowReview(false)).then(()=>{
    setData({
      review:"",
      rating:"",
      images:[]
    })
  })
 }
  return (
    <div className="px-4">
      <p className="text-lg bg-main p-3 text-white curve mb-3">
        Booking History
      </p>
      {bookingData?.length > 0 && bookingData?.map((item, index) => {
        return (
          <div className="shadow p-3 mb-3  curve" key={index}>
            <div className="d-flex justify-content-between mb-2">
              <div className="d-flex align-items-center">
                <Avatar
                  image={`https://instajamaica-api.appdeft.biz/${item.business.image}`}
                  size="xlarge"
                  className="cover-img rounded overflow-hidden"
                />
                <div className="ms-2">
                  <p className="text-lg text-dark fw-semibold m-0">{item.business?.businessName}</p>
                  {item?.services?.length > 0 && item?.services?.map((service, index) => {
                    return (
                      <p className="m-0" key={index}>{service?.serviceName}</p>
                    )
                  })}

                  <p className="text-gray text-sm m-0">{item?.startTime}</p>
                </div>
              </div>
              <div className="text-end">
                <p className="text-gray text-sm m-0">{item.days}</p>
                <p className="text-lg text-dark fw-semibold m-0">{item.dt}</p>

                <div className="d-flex">
                  <p className="bg-light-green text-main py-1 px-3 curve text-xs m-0">
                    {item?.status}
                  </p>
                </div>
              </div>
            </div>
            <small
              className="text-main cursor-pointer"
              onClick={() => handleGetBusiness(item.business._id)}
            >
              Write a review
            </small>
          </div>
        )
      })}

      <CustomModal visible={showReview} onHide={() => setShowReview(false)}>
        <div className="text-center ">
          <h4 className="text-dark fw-semibold">Write your Review</h4>
          <div className="d-flex justify-content-center align-items-center">
            <Avatar
              image={`https://instajamaica-api.appdeft.biz/${business.image}`}
              size="xlarge"
              className="rounded overflow-hidden"
            />
            <div className="ms-2">
              <small className="fw-semibold">{business?.businessName}</small>
              <Rating value={data.rating} onChange={(e) => setData({
                ...data,
                rating: e.value
              })} />
            </div>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between mb-2 ">
          <p className="m-0 fw-semibold">Write a Review</p>
          <div className="d-flex align-items-center position-relative cursor-pointer">
            <i className="pi pi-camera text-main"></i>
            <p className="ms-2 text-sm m-0 text-main">Add Photo</p>
            <input type="file" className="position-absolute w-100 h-100 opacity-0 cursor-pointer"  onChange={(e)=>setData({
              ...data,
              images:e.target.files[0]
            })} />
          </div>
        </div>
        <InputTextAreaComponent
          placeholder="Share details of your experience."
          className="mb-3"
          value={data.review}
          onChange={(e) => {
            setError({
              ...error,
              review: null,
            });
            setData({ ...data, review: e.target.value });
          }}
        />
        {error.review && <span className="error">{error.review}</span>}
        <MainButton label="Submit" onClick={()=>handleReview(business._id)} />
      </CustomModal>
    </div>
  );
}
