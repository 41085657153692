import React, { useState, useRef, useEffect } from "react";
import Layout from "../../layout";
import { Galleria } from "primereact/galleria";
import ProfilData from "./ProfileData";
import ProfileData from "./ProfileData";
import ArrowRight from "../../assets/icons/arrow-right.png";

export default function Portfolio() {
  const [images, setImages] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const galleria = useRef(null);
  const { PortfolioData } = ProfilData();

  useEffect(() => {
    setImages(PortfolioData);
  }, []);

  const itemTemplate = (item) => {
    return (
      <img
        src={PortfolioData[0].itemImageSrc}
        alt={item.alt}
        style={{ width: "100%", display: "block" }}
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={PortfolioData[0].thumbnailImageSrc}
        alt={item.alt}
        style={{ display: "block" }}
      />
    );
  };

  return (
    <div>
      <Layout>
        <div className="p-5">
          <div className="d-flex align-items-center mb-3">
            <img src={ArrowRight} width={25} />
            <h4 className="text-main fw-bold mb-0 ms-3">Portfolio</h4>
          </div>
          <Galleria
            ref={galleria}
            value={images}
            numVisible={7}
            style={{ maxWidth: "850px" }}
            activeIndex={activeIndex}
            onItemChange={(e) => setActiveIndex(e.index)}
            circular
            fullScreen
            showItemNavigators
            showThumbnails={false}
            item={itemTemplate}
            thumbnail={thumbnailTemplate}
          />
          <div className="row">
            {images &&
              images.map((image, index) => {
                let imgEl = (
                  <img
                    src={image.thumbnailImageSrc}
                    alt={image.alt}
                    className="w-100 h-100 cover-img rounded"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setActiveIndex(index);
                      galleria.current.show();
                    }}
                  />
                );
                return (
                  <div className="col-md-2 h-100" key={index}>
                    <div className="rounded h-100 img-card">{imgEl}</div>
                  </div>
                );
              })}
          </div>
        </div>
      </Layout>
    </div>
  );
}
