import React from "react";
import Layout from "../../layout";
import CameraIcon from "../../assets/icons/camera.png";
import undrawnFixing from "../../assets/vector/undraw_fixing.png";
import MainButton from "../../components/buttons/MainButton";
import LicenceImg from "../../assets/images/licence.png";
import { Link } from "react-router-dom";

export default function Frontdocument() {
  return (
    <div>
      <Layout>
        <div className="p-5">
          <div className="d-flex mb-3">
            <h4 className="text-main fw-bold mb-0 ms-3">Verification</h4>
          </div>

          <div className="row text-center align-items-end mb-3">
            <div className="col-md-8">
              <div className="bg-white rounded p-5">
                <h5 className="text-main text-start font-medium">Front</h5>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <div className="img-upload d-flex flex-column justify-content-center align-items-center rounded position-relative cursor-pointer">
                      {/* <img src={CameraIcon} width={53} className="mb-2" />
                    <h5 className="text-gray-light">Add Media</h5> */}
                      <img src={LicenceImg} className="w-100 cover-img h-100" />
                      <input
                        type="file"
                        className="position-absolute w-100 h-100 file-upload cursor-pointer"
                      />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="text-start">
                      <img src={undrawnFixing} width={211} />
                      <p className="text-main text-lg fw-semibold">
                        Hey Duzmaan!
                      </p>
                      <p>
                        <small className="text-lg">
                          Please provide the following Id Verification.
                        </small>
                        <br />
                        <small>
                          Front image of any govt. approved ID(this can be a
                          passport, driver’s license or national identity card.)
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <Link to="/verification/backdocument">
                    <MainButton label="Next" className="px-5" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
