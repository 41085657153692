import React from "react";
import Layout from "../../layout";
import { Card } from "react-bootstrap";
import MainButton from "../../components/buttons/MainButton";
import PasswordComponent from "../../components/input/PasswordComponent";
import { changeForgetPassword } from "../../redux/action/forgetPasswordAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowRight from "../../assets/icons/arrow-right.png"
import LayoutSidebar from "../../layout/LayoutSidebar";
export default function CreateNewPassword({ form, setForm, selectedSate,setSelectedState }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleSubmit = () => {
    const payload= {
      email:form.email,
      otpcode:form.otp,
      password:form.password
    }
    dispatch(changeForgetPassword(payload, () => navigate("/login")))
  }
  return (
    <div>
      <LayoutSidebar>
        <div className=" background-all d-flex justify-content-center align-items-center">
          <Card className="logincards border-0">
            <div className="p-4 login">
            <img alt = "" src={ArrowRight} width={25} onClick={()=>setSelectedState("2")}/>
              <h4 className="heading text-center fw-semibold">Create New Password</h4>
              <div className="mt-3">
                <span className="p-input-icon-left" style={{ width: "100%" }}>
                  <i className="pi pi-lock" style={{ zIndex: "1" }} />
                  <PasswordComponent placeholder="Password" value={form?.password}
                    onChange={(e) => {
                      setForm({ ...form, password: e.target.value })
                    }} />
                </span>
              </div>
              <div className="mt-3 mb-5">
                <span className="p-input-icon-left" style={{ width: "100%" }}>
                  <i className="pi pi-lock" style={{ zIndex: "1" }} />
                  <PasswordComponent placeholder="Confirm Password" value={form.newPassword}
                    onChange={(e) => {
                      setForm({ ...form, newPassword: e.target.value })
                    }} />
                </span>
              </div>
              <MainButton label="Save Password" onClick={handleSubmit} />
            </div>
          </Card>
        </div>
      </LayoutSidebar>
    </div>
  );
}
