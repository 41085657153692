import ClientConstants from "../../services/ClientConstants";
import { clientApi } from "../../services/clientApi";
import { authenticate,isAuthenticated } from "../../services/authService";
import { types } from "../types/types";
import { showToast } from "../action/toastAction";
import { hideLoaderAction,showLoaderAction } from "../action/loaderAction";
export const clientLoginAction = (data,navigate)=>async(dispatch)=>{
    dispatch(showLoaderAction());
    const res = await clientApi("post", ClientConstants.END_POINT.CLIENTLOGIN, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CLIENT_LOGIN_DETAIL,
                payload: res.data,
            });

            authenticate(res.data.token, () => {
                if (isAuthenticated()) {
                    navigate("/");
                    dispatch(showToast({ severity: "success", summary: res.message }));
                }
            });
            dispatch(hideLoaderAction())
        }
    } else {
        navigate("/");
        dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch(hideLoaderAction())
    }
}