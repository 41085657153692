import React from "react";

import { DataView } from "primereact/dataview";
import ProfileData from "./ProfileData";
import RedHeartImg from "../../../../assets/icons/redheart.png";
import CustomerLogo from "../../../../assets/icons/customerlogo.png";

export default function Favourites() {
  const { favouriteData } = ProfileData();
  const itemTemplate = (favouriteData) => {
    return (
      <div className="col-md-6 border-0 ">
        <div className=" border-1 surface-border shadow-sm curve overflow-hidden m-20 bg-white p-2">
          <div className="mb-4 header-img position-relative">
            <img
              src={`https://instajamaica-api.appdeft.biz/${favouriteData?.image?.workPlaceURL}`}
              alt=""
              className="w-100 cover-img shadow-2"
            />
            <p className="position-absolute top-0 end-0 bg-white-light text-center p-2 m-2 rounded fw-semibold">
              {favouriteData?.rating} <i className="pi pi-star-fill text-golden"></i>
              <br />
              <small className="fw-normal">{favouriteData?.reviewCount} reviews</small>
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <img src={CustomerLogo} alt="" width={35} />
              <div className="ms-2">
                <h5 className="mb-1 fw-semibold">
                  {favouriteData.businessName}
                </h5>
                <p className="mt-0 text-xs m-0">${favouriteData.address?.line1} {favouriteData.address?.line2} {favouriteData.address?.city} {favouriteData.address?.zipCode}</p>
              </div>
            </div>
            <div className="">
              <img src={RedHeartImg} alt="" width={30} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="px-4">
      <p className="text-lg bg-main p-3 text-white curve mb-3 ml-2 mr-2">Favourites</p>
      <DataView
        value={favouriteData}
        itemTemplate={itemTemplate}
        className="favourite-card"
      />
    </div>
  );
}
