import React from 'react'
import { Card } from 'react-bootstrap'
import PasswordComponent from '../../../components/input/PasswordComponent'
import MainButton from '../../../components/buttons/MainButton'
import { ClientChangeForgetPassword } from '../../../redux/clientAction/forgetPasswordAction';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
export default function NewPassword({ form, setForm, errors, setErrors, setLoginVisible, setForgetVisible, setSendOtpVisible }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleModel = ()=>{
    setForgetVisible(false)
    setSendOtpVisible(false)
    setLoginVisible(true)
  }
  const handleChangeSubmit = () => {
    if (form.password === "" || form.confirmPassword === "" || form.password !== form.confirmPassword) {
      setErrors({
        ...errors,
        password: form.password === "" ? "Please Enter Password" : form.password !== form.confirmPassword ? "Enetr Doesn't Match" : errors.password,
        confirmPassword: form.confirmPassword === "" ? "Please Enter Confirm Password" : errors.confirmPassword
      })
      return false;
    }
    const payload = {
      email: form.email,
      otpcode: form.otp,
      password: form.password
    }
    dispatch(ClientChangeForgetPassword(payload, handleModel))
  }
  return (
    <div>
      <Card className="border-0">
        <div className="p-4 login">
          <h4 className="heading text-center fw-bold">Create New Password</h4>
          <div className="mt-3">
            <span className="p-input-icon-left" style={{ width: "100%" }}>
              <i className="pi pi-lock" style={{ zIndex: "1" }} />
              <PasswordComponent placeholder="Password" value={form.password}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    password: null
                  })
                  setForm({
                    ...form,
                    password: e.target.value
                  })
                }
                } />
              {errors.password && <span className='error'>{errors.password}</span>}
            </span>
          </div>
          <div className="mt-3 mb-5">
            <span className="p-input-icon-left" style={{ width: "100%" }}>
              <i className="pi pi-lock" style={{ zIndex: "1" }} />
              <PasswordComponent placeholder="Confirm Password" value={form.confirmPassword}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    confirmPassword: null
                  })
                  setForm({
                    ...form,
                    confirmPassword: e.target.value
                  })
                }
                } />
              {errors.confirmPassword && <span className='error'>{errors.confirmPassword}</span>}
            </span>
          </div>
          <MainButton label="Save Password" className="fw-semibold" onClick={handleChangeSubmit} />
        </div>
      </Card>
    </div>
  )
}
