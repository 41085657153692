import React from "react";
import { Link } from "react-router-dom";

export default function AboutInstajamica() {
  return (
    <div className="px-4">
      <p className="text-lg bg-main p-3 text-white curve mb-3">
        About InstaJamica
      </p>
      <div className="">
        <Link to="" className="text-decoration-none d-flex justify-content-between p-3 border-bottom">
            <p className="text-dark m-0">Like Us on Facebook</p>
            <i className="pi pi-chevron-right text-dark"></i>
        </Link>
        <Link to="" className="text-decoration-none d-flex justify-content-between p-3 border-bottom">
            <p className="text-dark m-0">Follow Us on Instagram</p>
            <i className="pi pi-chevron-right text-dark"></i>
        </Link>
        <Link to="" className="text-decoration-none d-flex justify-content-between p-3 border-bottom">
            <p className="text-dark m-0">Rate InstaJamica in Google Play</p>
            <i className="pi pi-chevron-right text-dark"></i>
        </Link>
        <Link to="" className="text-decoration-none d-flex justify-content-between p-3">
            <p className="text-dark m-0">Rate InstaJamaica in App Store</p>
            <i className="pi pi-chevron-right text-dark"></i>
        </Link>
      </div>
    </div>
  );
}
